import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { subscribe } from "../Services/Subscribe";

export const subscribeUser = createAsyncThunk("subscriber/subscribeUser",
    async (payload) => {
        console.log("payload Slice", payload)
        return await subscribe(payload);
    }
)

const SubscriberSlice = createSlice({
    name: "subscriber",
    reducers: {},
})


export default SubscriberSlice.reducer;