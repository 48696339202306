import { useState, useEffect } from "react";

function useMoveAndRotate(isMouseInside) {
  const [transform, setTransform] = useState({ x: 0, y: 0 });

  const moveAndRotateElement = (event) => {
    const x = event.clientX;
    const y = event.clientY;

    const middleX = window.innerWidth / 2;
    const middleY = window.innerHeight / 2;

    const offsetX = ((x - middleX) / middleX) * 20;
    const offsetY = ((y - middleY) / middleY) * 30;

    setTransform({ x: offsetX, y: offsetY });
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isMouseInside) {
        moveAndRotateElement(event);
      }
    };

    if (isMouseInside) {
      document.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isMouseInside]);

  const { x, y } = transform;

  const logoStyle = {
    transform: isMouseInside
      ? `translateX(${x}px) translateY(${y}px) rotateX(${y}deg) rotateY(${-x}deg)`
      : `translateX(0) translateY(0) rotateX(0deg) rotateY(0deg)`,
  };

  return logoStyle;
}

export default useMoveAndRotate;
