/* SUBSCRIPTION */
export const SUBSCRIPTION = "subscribe/createSubscribe";
export const UNSUBSCRIBE = "/unsubscribe";

/* CONTACT FROM */
export const CONTACT = "contactUs/createContactUs";

/* ADMIN AUTH */
export const LOGIN = "users/admin/loginAdmin";
export const REGISTER = "users/admin/registerAdmin";
export const CHANGEPASSWORD = "users/admin/changeAdminPassword";
export const PROFILEGET = "users/admin/adminProfile";
export const PROFILEUPDATE = "users/admin/editAdminProfile";

/* ADMIN CATEGORY */
export const GETMAINCATEGORY = "category/getAllMainCategory";
export const GETMAINSUBCATEGORY = "category/getCategory";
export const GETTIDBITCATEGORY = "tidbit/getTidbitsCategory";
export const ADDMAINANDSUBCATEGORY = "category/addCategory";
export const DELETECATEGORY = "category/deleteMainCategory";
export const DELETESUBCATEGORY = "category/deleteSubCategory";

/* ADMIN BLOG */
export const PUBLISHEDBLOG = "blogs/admin/publishBlog";
export const CRETENEW = "blogs/createBlog";
export const GETALLBLOGADMIN = "blogs/admin/getAllBlogForAdmin";
export const DELETEBLOG = "blogs/admin/deleteBlog";
export const SINGLEBLOGVIEW = "blogs/blogOverview";
export const EDITBLOG = "blogs/editBlog";

/* ADMIN TIDBIT */
export const GETALLTIDBIT = "tidbit/getTidbits";
export const CRETETIDBIT = "tidbit/createTidbit";
export const EDITTIDBIT = "tidbit/editTidbits";
export const GETSINGLETIDBIT = "tidbit/getSingleTidbitsInstance";
export const DELETETIDBIT = "tidbit/deleteTidbits";


/* ============= Clint  Side ========== */
export const ALLBLOG = "blogs/getInfohub"
export const BlogLike = "blogs/likeBlog"