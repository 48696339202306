import Cookies from 'js-cookie';
import React, { useEffect } from 'react'
import { createPortal } from 'react-dom';

const Modal = ({ children, isOpen, handleClose }) => {
    useEffect(() => {
        const cookiesPopupOpen = Cookies.get('cookieConsent');;
        if (isOpen || cookiesPopupOpen === undefined) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;
    return createPortal(
        <div>{children}</div>,
        document.getElementById("modal-root")
    );
};

export default Modal
