import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import customData from '../../static/SubServices.json'
import SEO from '../Common/SEO';
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from '../../Utility/HeadingTitle';
import LeftBorderHeading from '../../Utility/LeftBorderHeading';
import Development from '../../Utility/Development';
import OursProcessSection from '../../Utility/OursProcessSection';
import WebFrontendTechnologies from '../Others/WebFrontendTechnologies'
import WebBackendTechnologies from '../Others/WebBackendTechnologies'
import WebPlatformsTechnologies from '../Others/WebPlatformsTechnologies'
import AppFrontendTechnologies from "../Others/AppFrontendTechnologies";
import AppBackendTechnologies from "../Others/AppBackendTechnologies";
import AppPlatformsTechnologies from "../Others/AppPlatformsTechnologies";
import SecurityTechnologies from '../Others/SecurityTechnologies';
import QualityAssurance from '../Others/QualityAssurance';
import ConsultationFrom from '../Others/ConsultationFrom';
import DatabaseTechnologies from '../Others/DatabaseTechnologies'
import UiUxDesignTools from '../Others/UiUxDesignTools';
import CommonTechnologies from '../Others/CommonTechnologies';
import Modal from '../Common/Modal';
import ContactFrom from '../Popup/ContactFrom';

import TechnologiesBox from '../../Assets/svg/technologiesbox.svg'
import WebAppDevelopment from '../../Assets/Webp/web_app_development.webp'
import MobileAppDevelopmentBox from "../../Assets/Webp/mobileapp_development_box.webp";
import UiuxDevelopmentBox from '../../Assets/Webp/uiuxDevelopmentBox.webp'
import Security from '../../Assets/Webp/security.webp';
import QualityAssuranceTools from '../../Assets/Webp/quality_assurance.webp';
import ComprehensiveSoftwareDevelopment from '../../Assets/Webp/comprehensive_software_development.webp';


function Technologies() {
    const navigator = useNavigate();
    const data = customData.technologies;
    const isHDImages = false;
    const [consultFrom, setConsultFrom] = useState(false);
    const [webTechnologies, setWebTechnologies] = useState(1);
    const [appTechnologies, setAppTechnologies] = useState(1);
    const [uiUxTechnologies, setUiUxTechnologies] = useState(1);
    const [securityTechnologies, setSecurityTechnologies] = useState(1);
    const [qualityAssuranceTesting, setQualityAssuranceTesting] = useState(1);

    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className='wrapper'>
                {/* Hire Resources Box*/}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[819px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? imageUrl + data.headingBox.src : TechnologiesBox}
                            alt="Technologies We Develope"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                </div>
                {/* Web App Development */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        onClick={() => navigator("../services/custom-services/web-development")}
                        title_name={data.WebAppDevelopment.heading}
                        heading_class="order-1 cursor-pointer"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.WebAppDevelopment.src : WebAppDevelopment}
                        alt="Web Development"
                        title_class="hidden"
                        development_paragraph={data.WebAppDevelopment.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.WebAppDevelopment.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the Right Web Development Language" />
                    <div className="customs-services border-[1px] border-transparent rounded-2xl md:rounded-[30px] lg:rounded-[50px] h-full">
                        <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                            <div className="w-full md:w-auto flex md:flex-col justify-between border-b md:border-y-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <h3 className={`technology-sideTab ${webTechnologies === 1 ? "active" : ""}`} data-tab="Frontend" onClick={() => setWebTechnologies(1)}>Frontend</h3>
                                <h3 className={`technology-sideTab ${webTechnologies === 2 ? "active" : ""}`} data-tab="Frontend" onClick={() => setWebTechnologies(2)}>Backend</h3>
                                <h3 className={`technology-sideTab ${webTechnologies === 3 ? "active" : ""}`} data-tab="Frontend" onClick={() => setWebTechnologies(3)}>Platforms</h3>
                                <h3 className={`technology-sideTab ${webTechnologies === 4 ? "active" : ""}`} data-tab="Frontend" onClick={() => setWebTechnologies(4)}>Database</h3>
                            </div>
                            <div className="w-full flex flex-col p-4 md:p-[30px]">
                                <div className="">
                                    {webTechnologies === 1 && <WebFrontendTechnologies />}
                                    {webTechnologies === 2 && <WebBackendTechnologies />}
                                    {webTechnologies === 3 && <WebPlatformsTechnologies />}
                                    {webTechnologies === 4 && <DatabaseTechnologies />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Mobile App development */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        onClick={() => navigator("/services/custom-services/mobile-app-development")}
                        title_name={data.MobileAppDevelopment.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1 cursor-pointer"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.MobileAppDevelopment.src : MobileAppDevelopmentBox}
                        alt="Mobile App Development"
                        title_class="hidden"
                        development_paragraph={data.MobileAppDevelopment.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.MobileAppDevelopment.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the Right app Development Language" />
                    <div className="customs-services border-[1px] rounded-2xl md:rounded-[30px] lg:rounded-[50px] border-transparent">
                        <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                            <div className="w-full md:w-auto flex md:flex-col justify-between border-b md:border-b-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <h3 className={`technology-sideTab ${appTechnologies === 1 ? "active" : ""}`} data-tab="Frontend" onClick={() => setAppTechnologies(1)}>Frontend</h3>
                                <h3 className={`technology-sideTab ${appTechnologies === 2 ? "active" : ""}`} data-tab="Frontend" onClick={() => setAppTechnologies(2)}>Backend</h3>
                                <h3 className={`technology-sideTab ${appTechnologies === 3 ? "active" : ""}`} data-tab="Frontend" onClick={() => setAppTechnologies(3)}>Platforms</h3>
                                <h3 className={`technology-sideTab ${appTechnologies === 4 ? "active" : ""}`} data-tab="Frontend" onClick={() => setAppTechnologies(4)}>Database</h3>
                            </div>
                            <div className="w-full flex flex-col p-4 md:p-[30px]">
                                <div className="">
                                    {appTechnologies === 1 && <AppFrontendTechnologies />}
                                    {appTechnologies === 2 && <AppBackendTechnologies />}
                                    {appTechnologies === 3 && <AppPlatformsTechnologies />}
                                    {appTechnologies === 4 && <DatabaseTechnologies />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* UI/UX Design */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.UiUxDesign.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.UiUxDesign.src : UiuxDevelopmentBox}
                        alt="UI/UX Design"
                        title_class="hidden"
                        development_paragraph={data.UiUxDesign.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.UiUxDesign.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the right Design tools" />
                    <div className="customs-services border-[1px] border-transparent rounded-2xl md:rounded-[30px] lg:rounded-[50px]">
                        <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                            <div className="w-full md:w-auto md:min-w-[200.55px] lg:min-w-[230.43px] xl:min-w-[432.43px] flex md:flex-col justify-between border-b md:border-b-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] px-3 space-x-5 md:space-x-0 xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <div className={`technology-sideTab ${uiUxTechnologies === 1 ? "active" : ""}`} data-tab="UI/UXDesignTools" onClick={() => setUiUxTechnologies(1)}>UI/UX</div>
                            </div>
                            <div className="w-full flex flex-col p-4 md:p-[30px]">
                                <div className="">
                                    {uiUxTechnologies === 1 && <UiUxDesignTools />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Security */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.Security.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.Security.src : Security}
                        alt="Security"
                        title_class="hidden"
                        development_paragraph={data.Security.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.Security.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the Right Security Technology" />
                    <div className="customs-services border-[1px] border-transparent md:min-w-[200.55px] lg:min-w-[230.43px] rounded-2xl md:rounded-[30px] lg:rounded-[50px]">
                        <div className="flex flex-wrap md:flex-nowrap justify-start h-full">
                            <div className="w-full md:w-auto flex md:flex-col justify-between border-b md:border-b-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] px-3 space-x-5 md:space-x-0 xl:px-[60px] md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <div className={`technology-sideTab ${securityTechnologies === 1 ? "active" : ""}`} data-tab="UI/UXDesignTools" onClick={() => setSecurityTechnologies(1)}>Applications</div>
                            </div>
                            <div className="w-full flex flex-col p-4 md:p-[30px]">
                                <div className="">
                                    {securityTechnologies === 1 && <SecurityTechnologies />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Quality Assurance */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        onClick={() => navigator("/services/custom-services/software-testing")}
                        title_name={data.QualityAssurance.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1 cursor-pointer"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.QualityAssurance.src : QualityAssuranceTools}
                        alt="Quality Assurance"
                        title_class="hidden"
                        development_paragraph={data.QualityAssurance.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.QualityAssurance.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the Right Quality Assurance Testing Technologies" />
                    <div className="customs-services border-[1px] border-transparent md:min-w-[200.55px] lg:min-w-[230.43px] rounded-2xl md:rounded-[30px] lg:rounded-[50px]">
                        <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                            <div className="w-full md:w-auto flex md:flex-col justify-between border-b md:border-b-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] px-3 space-x-5 md:space-x-0 xl:px-[73PX] md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <div className={`technology-sideTab ${qualityAssuranceTesting === 1 ? "active" : ""}`} data-tab="UI/UXDesignTools" onClick={() => setQualityAssuranceTesting(1)}>QA Testing</div>
                            </div>
                            <div className="w-full flex flex-col p-4 md:p-[30px]">
                                <div className="">
                                    {qualityAssuranceTesting === 1 && <QualityAssurance />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* common Technology */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.ComprehensiveTool.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? imageUrl + data.ComprehensiveTool.src : ComprehensiveSoftwareDevelopment}
                        alt="Comprehensive Software Development and Operations Toolset"
                        title_class="hidden"
                        development_paragraph={data.ComprehensiveTool.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.ComprehensiveTool.buttonName}
                    />
                    <LeftBorderHeading heading_class="py-8 md:py-[50px]" heading_title="Choosing the Right Comprehensive and Operations Toolset" />
                    <div className="customs-services border-[1px] rounded-2xl md:rounded-[30px] lg:rounded-[50px] border-transparent">
                        <CommonTechnologies />
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* Consult Now*/}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_2={data.Contact.paragraph_2}
                    />
                </div>
                {/* consultFrom Popup */}
                <Modal isOpen={consultFrom}>
                    <ContactFrom handleClose={setConsultFrom} />
                </Modal>
            </main>
        </React.Fragment>
    )
}

export default Technologies