import React, { useEffect, useState } from 'react'
import data from "../../static/ProcessContent.json"
import SEO from '../Common/SEO';
import ProcessPopUpBox from '../Popup/ProcessPopUpBox';
import HeadingTitle from '../../Utility/HeadingTitle';
import ProcessBox from '../../Utility/ProcessBox';
import Modal from '../Common/Modal';

import Close_Icon from '../../Assets/svg/close_icon_normal.svg'
import It_Process from '../../Assets/svg/It_Process.svg'

import Line from "../../Assets/Webp/line.webp";

import Step1to2 from '../../Assets/svg/step_1_to_2.svg'
import Step2to3 from '../../Assets/svg/step_2_to_3.svg'
import Step3to4 from '../../Assets/svg/step_3_to_4.svg'
import Step4to5 from '../../Assets/svg/step_4_to_5.svg'
import Step5to6 from '../../Assets/svg/step_5_to_6.svg'
import Step6to7 from '../../Assets/svg/step_6_to_7.svg'
import Step7to8 from '../../Assets/svg/step_7_to_8.svg'
import Step8to9 from '../../Assets/svg/step_8_to_9.svg'
import Step9to10 from '../../Assets/svg/step_9_to_10.svg'
import Step10to11 from '../../Assets/svg/step_10_to_11.svg'
import Step11to12 from '../../Assets/svg/step_11_to_12.svg'
import Step12to13 from '../../Assets/svg/step_12_to_13.svg'
import Step6to2 from '../../Assets/svg/step_6_to_2.svg'
import Step7to6 from '../../Assets/svg/step_7_to_6.svg'
import Step10to8 from '../../Assets/svg/step_10_to_8.svg'
import Step11to8 from '../../Assets/svg/step_11_to_8.svg'
import Step13to3 from '../../Assets/svg/step_13_to_3.svg'

import Laptop_Step1to2 from '../../Assets/svg/laptop_step_1_2.svg'
import Laptop_Step2to3 from '../../Assets/svg/laptop_step_2_3.svg'
import Laptop_Step3to4 from '../../Assets/svg/laptop_step_3_4.svg'
import Laptop_Step4to5 from '../../Assets/svg/laptop_step_4_5.svg'
import Laptop_Step5to6 from '../../Assets/svg/laptop_step_5_6.svg'
import Laptop_Step6to7 from '../../Assets/svg/laptop_step_6_7.svg'
import Laptop_Step7to8 from '../../Assets/svg/laptop_step_7_8.svg'
import Laptop_Step8to9 from '../../Assets/svg/laptop_step_8_9.svg'
import Laptop_Step9to10 from '../../Assets/svg/laptop_step_9_10.svg'
import Laptop_Step10to11 from '../../Assets/svg/laptop_step_10_11.svg'
import Laptop_Step11to12 from '../../Assets/svg/laptop_step_11_12.svg'
import Laptop_Step12to13 from '../../Assets/svg/laptop_step_12_13.svg'
import Laptop_Step6to2 from '../../Assets/svg/laptop_step_6_2.svg'
import Laptop_Step7to6 from '../../Assets/svg/laptop_step_7_6.svg'
import Laptop_Step10to8 from '../../Assets/svg/laptop_step_10_8.svg'
import Laptop_Step11to8 from '../../Assets/svg/laptop_step_11_8.svg'
import Laptop_Step13to3 from '../../Assets/svg/laptop_step_13_3.svg'

import Step6to2Mobile from '../../Assets/svg/step_6_to_2_mobile.svg'
import Step7to6Mobile from '../../Assets/svg/step_7_to_6_mobile.svg'
import Step10to8Mobile from '../../Assets/svg/step_10_to_8_mobile.svg'
import Step11to8Mobile from '../../Assets/svg/step_11_to_8_mobile.svg'
import Step13to2Mobile from '../../Assets/svg/step_13_to_2_mobile.svg'


import ProcessesStepCircleLine from '../../Assets/Webp/processes_step_circle_line.webp'
import ProcessContentBox from '../Others/ProcessContentBox';

function Process() {
    /* const ContentRef = useRef(null); */
    const [processPopUp, setProcessPopUp] = useState(false);
    const [processContentPopUp, setProcessContentPopUp] = useState(false);
    const [processStep, setProcessStep] = useState(1)
    const [clickDisable, setClickDisable] = useState(false)

    const processStepNames = {
        1: "Get In Touch",
        2: "Ideation",
        3: "Quick kick-off",
        4: "Work Elicitation",
        5: "Contract transparency",
        6: "Planning & strategize Documentation",
        7: "Requirement Walkthrough",
        8: "Design And Prototyping",
        9: "Development",
        10: "Quality assurance",
        11: "Security check",
        12: "Deployment",
        13: "Maintenance and care",
    };

    const processStepContent = {
        1: "At Enthusia Softech, we're not just developers; we're your partners in success.",
        2: "At the ideation phase of software development, the focus is on brainstorming ideas for the project. This is where the initial idea is formed and refined to lay the foundation for the development process.",
        3: "This documentation sets the project's direction, aligns stakeholders, and minimizes misunderstandings, ensuring a smooth and efficient development process. It's crucial for project success.",
        4: "Work elicitation identifies and defines project requirements, ensuring that the development team knows what is expected and can deliver a successful end product. It's essential for project clarity and alignment.",
        5: "Contract transparency is vital to establish trust, prevent disputes, and ensure both parties have a clear understanding of project expectations and responsibilities. It fosters a cooperative and successful working relationship.",
        6: "Planning & Strategize Documentation is essential to outline project objectives and strategies. It ensures project alignment and offers detailed timelines, resource allocation, and risk mitigation, enhancing project management and successful development.",
        7: "Requirement walkthroughs are essential for teams to gain a comprehensive understanding of project requirements, fostering clarity, alignment, and reducing misinterpretations. It enhances collaboration and helps ensure that everyone is on the same page before proceeding with the project.",
        8: "Design and prototyping are essential before development to visualize the final product, validate concepts, and uncover potential issues early, ensuring a more efficient and successful development process. It helps reduce costly rework and ensures that the final product aligns with client expectations.",
        9: "Follow Coding Standards: Adhere to coding standards and guidelines set by your team or organization. Consistent code style makes it easier for multiple developers to work on the project and maintain the code.",
        10: "At Enthusia Softech, we prioritize the highest standards of quality throughout the Software Development Life Cycle (SDLC). Our robust Quality Assurance (QA) processes are seamlessly integrated into every phase, from ideation to deployment.",
        11: "At Enthusia Softech, we recognize the paramount importance of security in the digital landscape. Our dedicated Security Checks are seamlessly woven into every phase of the Software Development Life Cycle (SDLC), ensuring that your digital assets remain impervious to threats.",
        12: "At Enthusia Softech, the journey from code to deployment is a well-choreographed symphony. Our deployment practices are meticulously crafted to ensure a smooth transition from development to the live environment, bringing your vision to life with precision.",
        13: "At Enthusia Softech, our commitment to your project extends well beyond the deployment phase. Our Maintenance and Care practices are designed to nurture your software, ensuring its continued excellence, security, and relevance in the ever-evolving digital landscape.",
    };

    const handleStepClick = (step) => {
        /*  ContentRef.current.scrollIntoView({ behavior: 'smooth' }); */
        if (clickDisable) {
            setProcessStep(step);
            setProcessPopUp(true);
        } else {
            setProcessStep(step);
        }
    };

    useEffect(() => {
        const updateClickDisable = () => {
            if (window.innerWidth < 768) {
                setClickDisable(true);
            } else {
                setClickDisable(false);
            }
        };

        updateClickDisable();
        window.addEventListener('resize', updateClickDisable);
        return () => {
            window.removeEventListener('resize', updateClickDisable);
        };
    }, []);

    const scrollToSection = () => {
        // Replace 'targetSection' with the actual id of your target section
        const targetSection = document.getElementById('targetSection');

        if (targetSection) {
            targetSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className="wrapper">
                {/* IT Processes */}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[800px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        Sculpting Efficiencies in the Digital Realm: Our <span className="h2 font-bold">IT Processes</span> Unleashed.
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={It_Process}
                            alt="It Process"
                            className="w-full h-full object-contain"
                            loading='lazy'
                        />
                    </div>
                </div>
                <div className="pt-7 sm:pt-10 md:pt-16 lg:pt-[100px]">
                    <HeadingTitle
                        title_name="how we work"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                        src={Line}
                    />
                    {/* process chart */}
                    <div className="relative w-full md:overflow-x-auto md:mt-[50px]">
                        {/* =========   Process onclick Arrow Image  ============ */}
                        <div className="">
                            {/* Get In Touch Step*/}
                            {/* {processStep === 1 && (<img src={ProcessesStep1} alt="get_in_touch_Step" className="hidden 2xl:inline-block absolute left-0 top-12 object-cover" loading='lazy' />)} */}
                            {processStep === 1 && <svg className={`hidden 3xl:inline-block absolute top-12 object-cover ${processStep === 1 ? "" : ""}`} width="41" height="680" viewBox="0 0 41 680" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M41 1H31C14.4315 1 1 14.4315 1 31V705" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Ideation Step*/}
                            {/* {processStep === 2 && (<img src={ProcessesStep2} alt="Ideation_Step" className="hidden 2xl:inline-block absolute left-0 top-[185px] object-cover" loading='lazy' />)} */}
                            {processStep === 2 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[185px] object-cover ${processStep === 2 ? "" : ""}`} width="244" height="545" viewBox="0 0 244 545" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M244 1H31C14.4315 1 1 14.4315 1 31V566" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Quick kick-off */}
                            {/* {processStep === 3 && (<img src={ProcessesStep3} alt="work_elicitation_Step" className="hidden 2xl:inline-block absolute left-0 top-[50px] object-cover" loading='lazy' />)} */}
                            {processStep === 3 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[50px] object-cover ${processStep === 3 ? "" : ""}`} width="1390" height="680" viewBox="0 0 1401 685" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 705.5V686C1 669.431 14.4315 656 31 656H1370C1386.57 656 1400 642.569 1400 626V31C1400 14.4315 1386.57 1 1370 1H630C613.431 1 600 14.4315 600 31V120.5" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Work Elicitation Step*/}
                            {/* {processStep === 4 && (<img src={ProcessesStep4} alt="work_elicitation_Step" className="hidden 2xl:inline-block absolute left-0 top-[50px] object-cover" loading='lazy' />)} */}
                            {processStep === 4 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[50px] object-cover ${processStep === 4 ? "" : ""}`} width="1400" height="680" viewBox="0 0 1400 680" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 704V683.5V683.5C1 668.312 13.3122 656 28.5 656H1369C1385.57 656 1399 642.569 1399 626V31C1399 14.4315 1385.57 1 1369 1H968C951.431 1 938 14.4315 938 31V120.5" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Contract transparency Step*/}
                            {/* {processStep === 5 && (<img src={ProcessesStep5} alt="contract_transparency_Step" className="hidden 2xl:inline-block absolute left-0 top-[387px] object-cover" loading='lazy' />)} */}
                            {processStep === 5 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[387px] object-cover ${processStep === 5 ? "" : ""}`} width="1400" height="340" viewBox="0 0 1400 340" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 364V346C1 329.431 14.4315 316 31 316H1369C1385.57 316 1399 302.569 1399 286V127C1399 110.431 1385.57 97 1369 97H1102.5C1085.93 97 1072.5 83.5685 1072.5 67V0" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Planning & strategize Documentation Step*/}
                            {/* {processStep === 6 && (<img src={ProcessesStep6} alt="planning_strategize_documentation_Step" className="hidden 2xl:inline-block absolute left-0 top-[300px] object-cover" loading='lazy' />)} */}
                            {processStep === 6 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[300px] object-cover ${processStep === 6 ? "" : ""}`} width="698" height="430" viewBox="0 0 698 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 448V31C1 14.4315 14.4315 1 31 1H667C683.569 1 697 14.4315 697 31V54.1136" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Requirement Walkthrough Step*/}
                            {/* {processStep === 7 && (<img src={ProcessesStep7} alt="requirement_walkthrough_Step" className="hidden 2xl:inline-block absolute left-0 top-[300px] object-cover" loading='lazy' />)} */}
                            {processStep === 7 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[300px] object-cover ${processStep === 7 ? "" : ""}`} width="411" height="440" viewBox="0 0 411 440" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 440V31C1 14.4315 14.4315 1 31 1H380C396.569 1 410 14.4315 410 31V55.1136" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Design And Prototyping Step*/}
                            {/* {processStep === 8 && (<img src={ProcessesStep8} alt="design_and_prototyping_Step" className="hidden 2xl:inline-block absolute left-0 top-[300px] object-cover" loading='lazy' />)} */}
                            {processStep === 8 && <svg className={`hidden 3xl:inline-block absolute left-0 top-[300px] object-cover ${processStep === 8 ? "" : ""}`} width="124" height="430" viewBox="0 0 124 430" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 441.5V31C1 14.4315 14.4315 1 31 1H93C109.569 1 123 14.4315 123 31V54.1136" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Development Step*/}
                            {/* {processStep === 9 && (<img src={ProcessesStep9} alt="development_Step" className="hidden 2xl:inline-block absolute left-0 bottom-0 object-cover" loading='lazy' />)} */}
                            {processStep === 9 && <svg className={`hidden 3xl:inline-block absolute left-0 bottom-0 object-cover ${processStep === 9 ? "" : ""}`} width="64" height="150" viewBox="0 0 64 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M63 0V86C63 102.569 49.5685 116 33 116H31C14.4315 116 1 129.431 1 146V150" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Quality assurance Step*/}
                            {/*  {processStep === 10 && (<img src={ProcessesStep10} alt="quality_assurance_Step" className="hidden 2xl:inline-block absolute left-0 bottom-0 object-cover" loading='lazy' />)} */}
                            {processStep === 10 && <svg className={`hidden 3xl:inline-block absolute left-0 bottom-0 object-cover ${processStep === 10 ? "" : ""}`} width="337" height="147" viewBox="0 0 337 147" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M336 0V86C336 102.569 322.569 116 306 116H31C14.4315 116 1 129.431 1 146V146.5" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Security check Step*/}
                            {/* {processStep === 11 && (<img src={ProcessesStep11} alt="security_check_Step" className="hidden 2xl:inline-block absolute left-0 bottom-0 object-cover" loading='lazy' />)} */}
                            {processStep === 11 && <svg className={`hidden 3xl:inline-block absolute left-0 bottom-0 object-cover ${processStep === 11 ? "" : ""}`} width="610" height="147" viewBox="0 0 610 147" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M609 0V86C609 102.569 595.569 116 579 116H31C14.4315 116 1 129.431 1 146V147" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Deployment Step*/}
                            {/* {processStep === 12 && (<img src={ProcessesStep12} alt="deployment_Step" className="hidden 2xl:inline-block absolute left-0 bottom-0 object-cover" loading='lazy' />)} */}
                            {processStep === 12 && <svg className={`hidden 3xl:inline-block absolute left-0 bottom-0 object-cover ${processStep === 12 ? "" : ""}`} width="883" height="146" viewBox="0 0 883 146" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M882 0V86C882 102.569 868.569 116 852 116H30.5C14.2076 116 1 129.208 1 145.5V145.5" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Maintenance and care Step*/}
                            {/* {processStep === 13 && (<img src={ProcessesStep13} alt="maintenance_and_care_Step" className="hidden 2xl:inline-block absolute left-0 bottom-0 object-cover" loading='lazy' />)} */}
                            {processStep === 13 && <svg className={`hidden 3xl:inline-block absolute left-0 bottom-0 object-cover ${processStep === 13 ? "" : ""}`} width="1156" height="147" viewBox="0 0 1156 147" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1155 0V86C1155 102.569 1141.57 116 1125 116H31C14.4314 116 1 129.431 1 146V147" stroke="#134046" strokeWidth="2" />
                            </svg>}

                            {/* Laptop Onclick Arrow */}
                            {/* Get In Touch Step*/}
                            {/* {processStep === 1 && (<img src={Laptop_click_Step1} alt="get_in_touch_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[37px] object-cover" loading='lazy' />)} */}
                            {processStep === 1 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[37px] object-cover ${processStep === 1 ? "" : ""}`} width="47" height="731" viewBox="0 0 47 731" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47 1H11C5.47715 1 1 5.47715 1 11V731" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Ideation Step*/}
                            {/* {processStep === 2 && (<img src={Laptop_click_Step2} alt="Ideation_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[58px] object-cover" loading='lazy' />)} */}
                            {processStep === 2 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[58px] object-cover ${processStep === 2 ? "" : ""}`} width="285" height="707" viewBox="0 0 285 707" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M284 0V38C284 43.5228 279.523 48 274 48H11C5.47714 48 1 52.4772 1 58V671V707" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Quick kick-off */}
                            {/* {processStep === 3 && (<img src={Laptop_click_Step3} alt="work_elicitation_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[60px] object-cover" loading='lazy' />)} */}
                            {processStep === 3 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[60px] object-cover ${processStep === 3 ? "" : ""}`} width="473" height="706" viewBox="0 0 473 706" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M472 0V37C472 42.5228 467.523 47 462 47H11C5.47715 47 1 51.4772 1 57V670V706" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Work Elicitation Step*/}
                            {/* {processStep === 4 && (<img src={Laptop_click_Step4} alt="work_elicitation_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[213px] object-cover" loading='lazy' />)} */}
                            {processStep === 4 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[213px] object-cover ${processStep === 4 ? "" : ""}`} width="381" height="550" viewBox="0 0 381 550" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M380 0V35.9029C380 41.4257 375.523 45.9029 370 45.9029H11C5.47716 45.9029 1 50.38 1 55.9029V514V550" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Contract transparency Step*/}
                            {/* {processStep === 5 && (<img src={Laptop_click_Step5} alt="contract_transparency_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[155px] object-cover" loading='lazy' />)} */}
                            {processStep === 5 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[155px] object-cover ${processStep === 5 ? "" : ""}`} width="109" height="610" viewBox="0 0 109 610" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M108 20.9999V11.9028C108 6.37998 103.523 1.90283 98 1.90283H11C5.47715 1.90283 1 6.37998 1 11.9028V574V610" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Planning & strategize Documentation Step*/}
                            {/* {processStep === 6 && (<img src={Laptop_click_Step6} alt="planning_strategize_documentation_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[370px] object-cover" loading='lazy' />)} */}
                            {processStep === 6 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[370px] object-cover ${processStep === 6 ? "" : ""}`} width="109" height="395" viewBox="0 0 109 395" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 395V69.0442C1 63.5213 5.47715 59.0442 11 59.0442H98C103.523 59.0442 108 54.567 108 49.0442V0" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Requirement Walkthrough Step*/}
                            {/* {processStep === 7 && (<img src={Laptop_click_Step7} alt="requirement_walkthrough_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[370px] object-cover" loading='lazy' />)} */}
                            {processStep === 7 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[370px] object-cover ${processStep === 7 ? "" : ""}`} width="419" height="394" viewBox="0 0 419 394" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 394V49.0442C1 43.5213 5.47714 39.0442 11 39.0442H408C413.523 39.0442 418 34.567 418 29.0442V0" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Design And Prototyping Step*/}
                            {/* {processStep === 8 && (<img src={Laptop_click_Step8} alt="design_and_prototyping_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 top-[369px] object-cover" loading='lazy' />)} */}
                            {processStep === 8 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 top-[369px] object-cover ${processStep === 8 ? "" : ""}`} width="652" height="395" viewBox="0 0 652 395" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 395V49.0296C1 43.5067 5.47715 39.0296 11 39.0296H641C646.523 39.0296 651 34.5524 651 29.0296V0" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Development Step*/}
                            {/* {processStep === 9 && (<img src={Laptop_click_Step9} alt="development_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 bottom-1 object-cover" loading='lazy' />)} */}
                            {processStep === 9 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 bottom-1 object-cover ${processStep === 9 ? "" : ""}`} width="511" height="238" viewBox="0 0 511 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M510 0V48.9065C510 54.4293 505.523 58.9065 500 58.9065H11C5.47714 58.9065 1 63.3836 1 68.9065V202V238" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Quality assurance Step*/}
                            {/* {processStep === 10 && (<img src={Laptop_click_Step10} alt="quality_assurance_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 bottom-1 object-cover" loading='lazy' />)} */}
                            {processStep === 10 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 bottom-1 object-cover ${processStep === 10 ? "" : ""}`} width="298" height="238" viewBox="0 0 298 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M297 0V48.9065C297 54.4293 292.523 58.9065 287 58.9065H11C5.47716 58.9065 1 63.3836 1 68.9065V202V238" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Security check Step*/}
                            {/* {processStep === 11 && (<img src={Laptop_click_Step11} alt="security_check_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 bottom-1 object-cover" loading='lazy' />)} */}
                            {processStep === 11 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 bottom-1 object-cover ${processStep === 11 ? "" : ""}`} width="109" height="298" viewBox="0 0 109 298" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M108 60V11.9065C108 6.38365 103.523 1.90649 98 1.90649H11C5.47715 1.90649 1 6.38365 1 11.9065V262V298" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Deployment Step*/}
                            {/* {processStep === 12 && (<img src={Laptop_click_Step12} alt="deployment_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 bottom-1 object-cover" loading='lazy' />)} */}
                            {processStep === 12 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 bottom-1 object-cover ${processStep === 12 ? "" : ""}`} width="109" height="82" viewBox="0 0 109 82" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M108 0V36C108 41.5229 103.523 46 98 46H11C5.47715 46 1 50.4772 1 56V82" stroke="#134046" strokeWidth="2" />
                            </svg>}
                            {/* Maintenance and care Step*/}
                            {/* {processStep === 13 && (<img src={Laptop_click_Step13} alt="maintenance_and_care_Step" className="hidden md:inline-block 2xl:hidden absolute left-0 bottom-1 object-cover" loading='lazy' />)} */}
                            {processStep === 13 && <svg className={`hidden md:inline-block 3xl:hidden absolute left-0 bottom-1 object-cover ${processStep === 13 ? "" : ""}`} width="335" height="83" viewBox="0 0 335 83" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M334 0V37C334 42.5229 329.523 47 324 47H11C5.47715 47 1 51.4772 1 57V83" stroke="#134046" strokeWidth="2" />
                            </svg>}
                        </div>
                        {/* =========   Process Step By Step Arrow  ============ */}
                        <div className="">
                            {/* Step1to2 */}
                            <img src={Step1to2} alt="Step1to2" className={`hidden 3xl:inline-block 3xl:absolute left-[282px] top-12 ${processStep === 1 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step2to3 */}
                            <img src={Step2to3} alt="Step2to3" className={`hidden 3xl:inline-block 3xl:absolute left-[475px] top-[178px] ${processStep === 2 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step3to4 */}
                            <img src={Step3to4} alt="Step3to4" className={`hidden 3xl:inline-block 3xl:absolute left-[815px] top-[178px] ${processStep === 3 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step4to5 */}
                            <img src={Step4to5} alt="Step4to5" className={`hidden 3xl:inline-block 3xl:absolute left-[1155px] top-[185px] ${processStep === 4 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step5to6 */}
                            <img src={Step5to6} alt="Step5to6" className={`hidden 3xl:inline-block 3xl:absolute left-[1009px] top-[360px] ${processStep === 5 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step6to7 */}
                            <img src={Step6to7} alt="Step6to7" className={`hidden 3xl:inline-block 3xl:absolute left-[632px] top-[360px] ${processStep === 6 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step7to8 */}
                            <img src={Step7to8} alt="Step7to8" className={`hidden 3xl:inline-block 3xl:absolute left-[344px] top-[360px] ${processStep === 7 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step8to9 */}
                            <img src={Step8to9} alt="Step8to9" className={`hidden 3xl:inline-block 3xl:absolute left-[20px] top-[369px] ${processStep === 8 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step9to10 */}
                            <img src={Step9to10} alt="Step9to10" className={`hidden 3xl:inline-block 3xl:absolute left-[280px] bottom-[144px] ${processStep === 9 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step10to11 */}
                            <img src={Step10to11} alt="Step10to11" className={`hidden 3xl:inline-block 3xl:absolute left-[553px] bottom-[144px] ${processStep === 10 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step11to12 */}
                            <img src={Step11to12} alt="Step11to12" className={`hidden 3xl:inline-block 3xl:absolute left-[827px] bottom-[144px] ${processStep === 11 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step12to13 */}
                            <img src={Step12to13} alt="Step12to13" className={`hidden 3xl:inline-block 3xl:absolute left-[1100px] bottom-[144px] ${processStep === 12 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step6to2 */}
                            <img src={Step6to2} alt="Step6to2" className={`hidden 3xl:inline-block 3xl:absolute left-[372px] top-[238px] ${processStep === 6 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step7to6 */}
                            <img src={Step7to6} alt="Step7to6" className={`hidden 3xl:inline-block 3xl:absolute left-[520px] bottom-[275px] ${processStep === 7 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step10to8 */}
                            <img src={Step10to8} alt="Step10to8" className={`hidden 3xl:inline-block 3xl:absolute left-[199px] bottom-[202px] ${processStep === 10 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step11to8 */}
                            <img src={Step11to8} alt="Step11to8" className={`hidden 3xl:inline-block 3xl:absolute left-[262px] bottom-[202px] ${processStep === 11 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Step13to3 */}
                            <img src={Step13to3} alt="Step13to3" className={`hidden 3xl:inline-block 3xl:absolute left-[714px] bottom-[199px] ${processStep === 13 ? "opacity-100" : "opacity-30"}`} loading='lazy' />

                            {/* ======= Laptop Step Arrow ====== */}
                            {/* Laptop Step1to2 */}
                            <img src={Laptop_Step1to2} alt="Step1to2" className={`hidden md:inline-block 3xl:hidden md:absolute left-[225px] top-[33px] ${processStep === 1 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step2to3 */}
                            <img src={Laptop_Step2to3} alt="Step2to3" className={`hidden md:inline-block 3xl:hidden md:absolute left-[413px] top-[33px] ${processStep === 2 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step3to4 */}
                            <img src={Laptop_Step3to4} alt="Step3to4" className={`hidden md:inline-block 3xl:hidden md:absolute left-[585px] top-[77px] ${processStep === 3 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step4to5 */}
                            <img src={Laptop_Step4to5} alt="Step4to5" className={`hidden md:inline-block 3xl:hidden md:absolute left-[327px] top-[185px] ${processStep === 4 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step5to6 */}
                            <img src={Laptop_Step5to6} alt="Step5to6" className={`hidden md:inline-block 3xl:hidden md:absolute left-[52px] top-[193px] ${processStep === 5 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step6to7 */}
                            <img src={Laptop_Step6to7} alt="Step6to7" className={`hidden md:inline-block 3xl:hidden md:absolute left-[355px] top-[343px] ${processStep === 6 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step7to8 */}
                            <img src={Laptop_Step7to8} alt="Step7to8" className={`hidden md:inline-block 3xl:hidden md:absolute left-[590px] top-[343px] ${processStep === 7 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step8to9 */}
                            <img src={Laptop_Step8to9} alt="Step8to9" className={`hidden md:inline-block 3xl:hidden md:absolute left-[697px] top-[389px] ${processStep === 8 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step9to10 */}
                            <img src={Laptop_Step9to10} alt="Step9to10" className={`hidden md:inline-block 3xl:hidden md:absolute left-[460px] bottom-[253px] ${processStep === 9 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step10to11 */}
                            <img src={Laptop_Step10to11} alt="Step10to11" className={`hidden md:inline-block 3xl:hidden md:absolute left-[247px] bottom-[253px] ${processStep === 10 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step11to12 */}
                            <img src={Laptop_Step11to12} alt="Step11to12" className={`hidden md:inline-block 3xl:hidden md:absolute left-[53px] bottom-[97px] ${processStep === 11 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step12to13 */}
                            <img src={Laptop_Step12to13} alt="Step12to13" className={`hidden md:inline-block 3xl:hidden md:absolute left-[275px] bottom-[97px] ${processStep === 12 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step6to2 */}
                            <img src={Laptop_Step6to2} alt="Step6to2" className={`hidden md:inline-block 3xl:hidden md:absolute left-[30px] top-[81px] ${processStep === 6 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step7to6 */}
                            <img src={Laptop_Step7to6} alt="Step7to6" className={`hidden md:inline-block 3xl:hidden md:absolute left-[277px] top-[277px] ${processStep === 7 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step10to8 */}
                            <img src={Laptop_Step10to8} alt="Step10to8" className={`hidden md:inline-block 3xl:hidden md:absolute left-[380px] bottom-[302px] ${processStep === 10 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step11to8 */}
                            <img src={Laptop_Step11to8} alt="Step11to8" className={`hidden md:inline-block 3xl:hidden md:absolute left-[177px] bottom-[302px] ${processStep === 11 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                            {/* Laptop Step13to3 */}
                            <img src={Laptop_Step13to3} alt="Step13to3" className={`hidden md:inline-block 3xl:hidden md:absolute left-[518px] bottom-[106px] ${processStep === 13 ? "opacity-100" : "opacity-30"}`} loading='lazy' />
                        </div>
                        {/* =========   Process Content Box  ============ */}
                        <div className={`relative pt-[50px] md:py-0 z-20 ${processStep === 12 ? "pb-[130px]" : processStep === 13 ? "pb-[215px] sm:pb-[195px]" : "pb-[30px]"}  ${processPopUp ? "bg-white bg-opacity-90 backdrop-blur-sm]" : ""}`}>
                            <div className="relative 3xl:static w-[300px] md:w-auto mx-auto h-auto md:h-[770px] 3xl:h-[730px] px-[25px] 3xl:ml-[70px] z-10">
                                {/* Get In Touch */}
                                <ProcessBox
                                    onClick={() => handleStepClick(1)}
                                    process_class="md:absolute top-0 left-[53px] 3xl:left-[65px] mb-4 md:mb-0"
                                    step_class={`${processStep === 1 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 1 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[181px] md:w-[174px] 3xl:w-[220px] ${processStep === 1 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="1"
                                    process_step_name="Get In Touch"
                                />
                                {/* Ideation*/}
                                <ProcessBox
                                    onClick={() => handleStepClick(2)}
                                    process_class="md:absolute top-0 3xl:top-[135px] left-[283px] 3xl:left-[258px] mb-4 md:mb-0"
                                    step_class={`${processStep === 2 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 2 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[145px] md:w-[136px] 3xl:w-[220px] ${processStep === 2 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="2"
                                    process_step_name="Ideation"
                                />
                                {/* Quick kick-off */}
                                <ProcessBox
                                    onClick={() => handleStepClick(3)}
                                    process_class="md:absolute top-0 3xl:top-[135px] left-[472px] 3xl:left-[598px] mb-4 md:mb-0"
                                    step_class={`${processStep === 3 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 3 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[196px] md:w-[193px] 3xl:w-[220px] ${processStep === 3 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="3"
                                    process_step_name="Quick kick-off"
                                />
                                {/* Work Elicitation */}
                                <ProcessBox
                                    onClick={() => handleStepClick(4)}
                                    process_class="md:absolute top-[153px] 3xl:top-[135px] left-[378px] 3xl:left-[938px] mb-4 md:mb-0"
                                    step_class={`${processStep === 4 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 4 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[204px] md:w-[202px] 3xl:w-[220px] ${processStep === 4 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="4"
                                    process_step_name="Work Elicitation"
                                />
                                {/* Contract transparency */}
                                <ProcessBox
                                    onClick={() => handleStepClick(5)}
                                    process_class="md:absolute top-[153px] 3xl:top-[318px] left-[105px] 3xl:left-[1073px] mb-4 md:mb-0"
                                    step_class={`${processStep === 5 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 5 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[192px] md:w-[220px] ${processStep === 5 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="5"
                                    process_step_name="Contract transparency"
                                />
                                {/* Planning & strategize Documentation */}
                                <ProcessBox
                                    onClick={() => handleStepClick(6)}
                                    process_class="md:absolute top-[309px] 3xl:top-[318px] left-[105px] 3xl:left-[697px] mb-4 md:mb-0"
                                    step_class={`${processStep === 6 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 6 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[252px] md:w-[257px] 3xl:w-[309px] ${processStep === 6 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="6"
                                    process_step_name="Planning & strategize Documentation"
                                />
                                {/* Step6to2Mobile */}
                                <img src={Step6to2Mobile} alt="Step6to2Mobile" className={`md:hidden absolute left-[60%] top-[118px] ${processStep === 6 ? "opacity-100" : "opacity-30"} ${processPopUp && processStep !== 6 ? "hidden" : ""}`} loading='lazy' />
                                {/* Requirement Walkthrough */}
                                <div className="">
                                    <ProcessBox
                                        onClick={() => handleStepClick(7)}
                                        process_class="md:absolute top-[309px] 3xl:top-[318px] left-[415px] 3xl:left-[409px] mb-4 md:mb-0"
                                        step_class={`${processStep === 7 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                        process_name_class={`${processStep === 7 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                        process_box={`w-[184px] md:w-[180px] 3xl:w-[220px] ${processStep === 7 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                        step_no="7"
                                        process_step_name="Requirement Walkthrough"
                                    />
                                    <img src={Step7to6Mobile} alt="Step7to6Mobile" className={`md:hidden absolute left-[208px] top-[506px] ${processStep === 7 ? "opacity-100" : "opacity-30"} ${processPopUp && processStep !== 7 ? "hidden" : ""}`} loading='lazy' />
                                </div>
                                {/* Design And Prototyping */}
                                <ProcessBox
                                    onClick={() => handleStepClick(8)}
                                    process_class="md:absolute top-[309px] 3xl:top-[318px] left-[650px] 3xl:left-[122px] mb-4 md:mb-0"
                                    step_class={`${processStep === 8 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 8 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[172px] md:w-[169px] 3xl:w-[220px] ${processStep === 8 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="8"
                                    process_step_name="Design And Prototyping"
                                />
                                {/* Development */}
                                <ProcessBox
                                    onClick={() => handleStepClick(9)}
                                    process_class="md:absolute bottom-[222px] 3xl:bottom-[103px] left-[508px] 3xl:left-[63px] mb-4 md:mb-0"
                                    step_class={`${processStep === 9 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 9 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[188px] md:w-[184px] 3xl:w-[220px] ${processStep === 9 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="9"
                                    process_step_name="Development"
                                />
                                {/* Quality assurance */}
                                <ProcessBox
                                    onClick={() => handleStepClick(10)}
                                    process_class="md:absolute bottom-[222px] 3xl:bottom-[103px] left-[296px] 3xl:left-[336px] mb-4 md:mb-0"
                                    step_class={`${processStep === 10 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 10 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[166px] md:w-[160px] 3xl:w-[220px] ${processStep === 10 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="10"
                                    process_step_name="Quality assurance"
                                />
                                {/* Step10to8Mobile */}
                                <img src={Step10to8Mobile} alt="Step10to8Mobile" className={`md:hidden absolute left-[190px] top-[650px] ${processStep === 10 ? "opacity-100" : "opacity-30"} ${processPopUp && processStep !== 10 ? "hidden" : ""}`} loading='lazy' />
                                {/* Security check */}
                                <ProcessBox
                                    onClick={() => handleStepClick(11)}
                                    process_class="md:absolute  bottom-[222px] 3xl:bottom-[103px] left-[105px] 3xl:left-[609px] mb-4 md:mb-0"
                                    step_class={`${processStep === 11 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 11 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[146px] md:w-[136px] 3xl:w-[220px] ${processStep === 11 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="11"
                                    process_step_name="Security check"
                                />
                                {/* Step11to8Mobile */}
                                <img src={Step11to8Mobile} alt="Step11to8Mobile" className={`md:hidden absolute left-[170px] top-[625px] ${processStep === 11 ? "opacity-100" : "opacity-30"} ${processPopUp && processStep !== 11 ? "hidden" : ""}`} loading='lazy' />
                                {/* Deployment */}
                                <ProcessBox
                                    onClick={() => handleStepClick(12)}
                                    process_class="md:absolute bottom-[66px] 3xl:bottom-[103px] left-[106px] 3xl:left-[883px] mb-4 md:mb-0"
                                    step_class={`${processStep === 12 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 12 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[179px] md:w-[173px] 3xl:w-[220px] ${processStep === 12 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="12"
                                    process_step_name="Deployment"
                                />
                                {/* Maintenance and care */}
                                <ProcessBox
                                    onClick={() => handleStepClick(13)}
                                    process_class="md:absolute bottom-[66px] 3xl:bottom-[103px] left-[335px] 3xl:left-[1156px]"
                                    step_class={`${processStep === 13 ? processPopUp ? "process-inner-active drop-shadow-step_box scale-110" : "process-inner-active" : "process-inner"}`}
                                    process_name_class={`${processStep === 13 ? "text-enthusia-primary" : "text-[#737373]"}`}
                                    process_box={`w-[186px] md:w-[182px] 3xl:w-[220px] ${processStep === 13 ? "process-gradient-border" : "process-gradient-border-gray"}`}
                                    step_no="13"
                                    process_step_name="Maintenance and care"
                                />
                                {/* Step13to2Mobile */}
                                <img src={Step13to2Mobile} alt="Step13to2Mobile" className={`md:hidden absolute left-[210px] top-[205px] animation ${processStep === 13 ? "opacity-100" : "opacity-30"} ${processPopUp && processStep !== 13 ? "hidden" : ""}`} />
                            </div>

                            {/* Mobile Process Content Popup Small Box */}
                            <div className={`w-full inset-x-0 mt-[50px] z-50 ${processPopUp ? "absolute inset-0 bg-white bg-opacity-50 backdrop-blur-md animation-blur" : "hidden"} ${processStep === 1 ? "top-[77px]" : processStep === 2 ? "top-[164px]" : processStep === 3 ? "top-[255px]" : processStep === 4 ? "top-[340px]" : processStep === 5 ? "top-[425px]" : processStep === 6 ? "top-[510px]" : processStep === 7 ? "top-[600px]" : processStep === 8 ? "top-[680px]" : processStep === 9 ? "top-[770px]" : processStep === 10 ? "top-[860px]" : processStep === 11 ? "top-[940px]" : processStep === 12 ? "top-[1025px]" : processStep === 13 ? "top-[1115px]" : "hidden"}`}>
                                <div className="relative w-full contact-border rounded-2xl p-4">
                                    <img onClick={() => { setProcessPopUp(false); setProcessStep(1) }} src={Close_Icon} alt="close_icon" className='absolute top-2 sm:top-3 right-2 sm:right-3 cursor-pointer scale-90' />
                                    <div className="flex items-center justify-between">
                                        <span className='text-base font-medium text-enthusia-primary'><span className='pr-1.5'>{processStep}.</span>{processStepNames[processStep]}</span>
                                    </div>
                                    <p className='block text-sm font-normal text-enthusia-text text-justify pt-3 pb-1'>{processStepContent[processStep]}</p>
                                    <span onClick={() => setProcessContentPopUp(true)} className='flex justify-end text-end text-sm font-medium text-enthusia-primary underline'>Read More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* content Box */}
                    <div className="relative hidden md:block contact-border rounded-[30px] rounded-tl-none p-10">
                        <img
                            src={ProcessesStepCircleLine}
                            alt="processes_Step_circle_line"
                            className={`absolute -left-[23px] -top-[8px] ${processStep === null ? "opacity-0" : "inline-block"}`}
                        />
                        <div id='targetSection' className="absolute -top-28"></div>
                        <ProcessContentBox processStep={processStep} setProcessStep={setProcessStep} scrollToSection={scrollToSection} />
                    </div>
                </div>
                <Modal isOpen={processContentPopUp}>
                    <ProcessPopUpBox smallPopupClose={setProcessPopUp} handleClose={setProcessContentPopUp} processStep={processStep} processStepNames={processStepNames} setProcessStep={setProcessStep} />
                </Modal>
            </main>
        </React.Fragment>
    );
}

export default Process