import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from '../../Utility/Button'

import rightArrow2 from "../../Assets/Webp/right_arrow2.webp"
import bottom_arrow from "../../Assets/svg/bottom_arrow.svg"

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isActiveServices, setIsActiveServices] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [linkPath, setLinkPath] = useState()
    const [isHovered, setIsHovered] = useState(false);
    const [isMenuHovered, setIsMenuHovered] = useState(false);

    // On hover Class ADD *
    const handleMouseEnter = (value) => {
        setIsHovered(value);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isHomeActive = linkPath && typeof linkPath === 'string' && linkPath === '/';
    const isServicesActive = linkPath && typeof linkPath === 'string' && linkPath.includes('/services');
    /*     const isInfoActive = linkPath && typeof linkPath === 'string' && linkPath.includes('/infohub'); */
    const isProcessActive = linkPath && typeof linkPath === 'string' && linkPath.includes('/process');
    const isAboutActive = linkPath && typeof linkPath === 'string' && linkPath.includes('/about-us');

    const handleArrowClickServices = () => {
        setIsActiveServices(!isActiveServices)
    }

    const handleArrowClick = () => {
        setIsActive(!isActive)
    }

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        if (!isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    };
    const onMenuClick = () => {
        setIsHovered(false);
        setIsMenuHovered(false);
    }

    const MobileMenuClose = () => {
        setIsActive(false);
        setIsActiveServices(false);
    }

    useEffect(() => {
        setLinkPath(location.pathname)
    }, [location])

    return (
        <React.Fragment>
            {/* Desktop Menu */}
            <header className={`lg:block hidden fixed top-1/2 -translate-y-1/2 left-0 max-w-[157px] w-full text-white bg-enthusia-primary rounded-tr-[15px] rounded-br-[15px] py-[36px] z-50 animation`}>
                <ul className="dropdown w-full">
                    <li onClick={() => navigate("/")} className={`dropList_list w-full flex items-center cursor-pointer px-[17px] py-5 anim font-semibold ${isHomeActive ? "opacity-100" : "opacity-70 hover:opacity-100"}`}>
                        <div className="">
                            <span className='flex items-center justify-center header-linier mr-2.5'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2340_1588)">
                                        <path d="M8 9.99414C6.89544 9.99414 6 10.8896 6 11.9941V15.9941H10V11.9941C10 10.8896 9.10456 9.99414 8 9.99414Z" fill="white" />
                                        <path d="M11.3333 11.9939V15.9939H14C15.1046 15.9939 16 15.0985 16 13.9939V7.91329C16.0002 7.56694 15.8656 7.23413 15.6247 6.98529L9.95934 0.8606C8.95972 -0.220963 7.27259 -0.287369 6.19103 0.712256C6.13966 0.759756 6.09016 0.809225 6.04269 0.8606L0.387344 6.98329C0.139156 7.23316 -9.37027e-05 7.5711 4.73072e-08 7.92329V13.9939C4.73072e-08 15.0985 0.895437 15.9939 2 15.9939H4.66666V11.9939C4.67912 10.1761 6.14684 8.69157 7.91894 8.64882C9.75031 8.60463 11.3194 10.1144 11.3333 11.9939Z" fill="white" />
                                        <path d="M8 9.99414C6.89544 9.99414 6 10.8896 6 11.9941V15.9941H10V11.9941C10 10.8896 9.10456 9.99414 8 9.99414Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2340_1588">
                                            <rect width="16" height="16" fill="white" transform="translate(0 -0.00195312)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                {/*  <img src={home_icon} alt="Home Icon" className='w-4 h-4 object-cover' loading='lazy' /> */}
                            </span>
                        </div>
                        <span className='inline-block capitalize'>Home</span>
                    </li>
                    <li onMouseEnter={() => setIsMenuHovered(true)} className={`dropList_list w-full flex items-center`}>
                        <div onClick={() => { navigate("/services"); onMenuClick() }} className={`w-full flex items-center px-[17px] py-5 anim font-semibold cursor-pointer ${isServicesActive ? "opacity-100" : "opacity-70 hover:opacity-100"}`}>
                            <div className="">
                                <span className='flex items-center justify-center header-linier mr-2.5'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2340_1583)">
                                            <path d="M8 12H2C0.893333 12 0 11.1067 0 10C0 9.63181 0.298477 9.33333 0.666667 9.33333H4.99585C5.27339 9.33333 5.53692 9.4552 5.71667 9.66667C5.89641 9.87813 6.15995 10 6.43748 10H7.00667C7.55895 10 8.00667 10.4477 8.00667 11V11.9933C8.00667 11.997 8.00368 12 8 12ZM16 8C16 6.9 15.1 6 14 6H11.3333C10.2333 6 9.33333 6.9 9.33333 8V15C9.33333 15.5523 9.78105 16 10.3333 16H15C15.5523 16 16 15.5523 16 15V8ZM8 8C8 6.16 9.49333 4.66667 11.3333 4.66667H14C14.3487 4.66667 14.6667 4.41104 14.6667 4.06239V1C14.6667 0.447715 14.219 0 13.6667 0H2.33333C1.78105 0 1.33333 0.447715 1.33333 1V7C1.33333 7.55228 1.78105 8 2.33333 8H5.61585C5.89339 8 6.15728 8.12229 6.33703 8.33376C6.51632 8.54468 6.77954 8.66667 7.05637 8.66667H7.33333C7.70152 8.66667 8 8.36819 8 8Z" fill="white" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2340_1583">
                                                <rect width="16" height="16" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {/* <img src={services_icon} alt="Services Icon" className='w-4 h-4 object-cover' loading='lazy' /> */}
                                </span>
                            </div>
                            <span className='inline-block text-enth capitalize'>Services</span>
                        </div>
                        <ul className={`dropdown_one absolute top-0 left-full w-[300px] bg-white z-50 py-5 ${isHovered === true ? " rounded-r-0" : isHovered === null ? "block rounded-tr-0 rounded-br-[10px]" : "rounded-r-[10px] rounded-bl-[10px]"} ${!isHovered && !isMenuHovered ? "!hidden" : "block"}`}>
                            <li onMouseEnter={() => { handleMouseEnter(true) }} onMouseLeave={handleMouseLeave} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim">
                                <div onClick={() => { navigate("/services/custom-services"); onMenuClick() }} className="w-full flex border-b border-enthusia-primary items-center justify-between pb-3">
                                    <span className="text-enthusia-primary dropdown_one_link">Custom Services</span>
                                    <span className="rigtht_arrow">
                                        <img src={rightArrow2} alt="right Arrow" className='w-auto h-auto object-contain' loading='lazy' />
                                    </span>
                                </div>
                                <ul className="dropdown_two absolute top-0 left-full w-[300px] bg-white py-5 px-5 z-50 rounded-r-[10px] rounded-bl-[10px]">
                                    <li onClick={() => { navigate("/services/custom-services/saas-development"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary pb-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">Saas development</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/web-development"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">web App development</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/mobile-app-development"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">Mobile app development</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/software-development"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">software development</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/data-analytics"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">Data Analytics & Al</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/app-modernization"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">App Modernization</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/digital-transformation"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">Digital transformation</span></li>
                                    <li onClick={() => { navigate("/services/custom-services/software-testing"); onMenuClick() }} className="dropdown_two_list relative w-full flex items-center cursor-pointer font-semibold hover:opacity-100 opacity-70 border-b border-enthusia-primary py-3 anim"><span className="text-enthusia-primary dropdown_two_link inline-block capitalize">QA & Testing</span></li>
                                </ul>
                            </li>
                            <li onClick={() => { navigate("/services/boosting-startups"); onMenuClick() }} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim">
                                <span className="w-full text-enthusia-primary dropdown_one_link inline-block capitalize border-b border-enthusia-primary items-center justify-between py-4">Boosting Startups</span>
                            </li>
                            <li onClick={() => { navigate("/services/technologies"); onMenuClick() }} onMouseEnter={() => handleMouseEnter(null)} onMouseLeave={handleMouseLeave} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim">
                                <div className="w-full flex items-center justify-between border-b border-enthusia-primary py-3">
                                    <span className="text-enthusia-primary dropdown_one_link inline-block capitalize">Technologies</span>
                                </div>
                            </li>
                            <li onClick={() => { navigate("/services/industries"); onMenuClick() }} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim"><span className="w-full text-enthusia-primary dropdown_one_link inline-block capitalize border-b border-enthusia-primary items-center justify-between py-4">Industries</span></li>
                            <li onClick={() => { navigate("/services/hire-resources"); onMenuClick() }} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim"><span className="w-full text-enthusia-primary dropdown_one_link inline-block capitalize border-b border-enthusia-primary items-center justify-between py-4">Hire Resources</span></li>
                            <li onClick={() => { navigate("/services/consultation"); onMenuClick() }} className="dropdown_one_list w-full flex items-center font-semibold opacity-70 hover:opacity-100 cursor-pointer px-[25px] anim"><span className="w-full text-enthusia-primary dropdown_one_link inline-block capitalize border-b border-enthusia-primary items-center justify-between py-4">Get A Consultation</span></li>
                            {/* left icon*/}
                            <svg className='absolute -left-[1px] top-[142px] -translate-y-1/2' width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 5.5L0.750001 10.2631L0.750001 0.73686L9 5.5Z" fill="#134046" />
                            </svg>
                            {/* 2step round icon*/}
                            <svg className={`absolute right-0 -bottom-[10px] ${isHovered === true ? "" : "hidden"}`} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 -4.37114e-07L10 0L10 10L9.82649 9.5245C9.01344 7.29834 7.70707 5.28507 6.00529 3.63558C4.30351 1.9861 2.25045 0.743185 0 -4.37114e-07Z" fill="#fff" />
                            </svg>
                        </ul>
                    </li>
                    <li onClick={() => navigate("/process")} className={`dropList_list w-full flex items-center cursor-pointer px-[17px] py-5 anim font-semibold ${isProcessActive ? "opacity-100" : "opacity-70 hover:opacity-100"}`}>
                        <div className="">
                            <span className='flex items-center justify-center header-linier mr-2.5'>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6562 9.46887L13.0941 10.2186L13.844 10.7812H12.1522C11.9426 9.97472 11.2151 9.375 10.3437 9.375H5.65625C4.78491 9.375 4.05737 9.97472 3.84778 10.7812H2.8125C1.77844 10.7812 0.9375 9.94031 0.9375 8.90625C0.9375 7.87219 1.77844 7.03125 2.8125 7.03125H3.84778C4.05737 7.83778 4.78491 8.4375 5.65625 8.4375H10.3438C11.2151 8.4375 11.9426 7.83778 12.1522 7.03125H13.1875C14.7384 7.03125 16 5.76966 16 4.21875C16 2.66784 14.7384 1.40625 13.1875 1.40625H12.1522C11.9426 0.599719 11.2151 0 10.3438 0H5.65625C4.78491 0 4.05737 0.599719 3.84778 1.40625H3.4375L1.68725 0.093875L1.12525 0.843625L1.87513 1.40625H0V2.34375H1.87513L1.12525 2.90637L1.68725 3.65612L3.4375 2.34375H3.84778C4.05737 3.15028 4.78491 3.75 5.65625 3.75H10.3438C11.2151 3.75 11.9426 3.15028 12.1522 2.34375H13.1875C14.2216 2.34375 15.0625 3.18469 15.0625 4.21875C15.0625 5.25281 14.2216 6.09375 13.1875 6.09375H12.1522C11.9426 5.28722 11.2151 4.6875 10.3438 4.6875H5.65625C4.78491 4.6875 4.05737 5.28722 3.84778 6.09375H2.8125C1.26159 6.09375 0 7.35534 0 8.90625C0 10.4572 1.26159 11.7187 2.8125 11.7187H3.84778C4.05737 12.5253 4.78491 13.125 5.65625 13.125H10.3438C11.2151 13.125 11.9426 12.5253 12.1522 11.7187H13.844L13.0941 12.2814L13.6562 13.0311L16 11.25L13.6562 9.46887Z" fill="white" />
                                </svg>
                                {/*   <img src={process_icon} alt="Process Icon" className='w-4 h-4 object-cover' loading='lazy' /> */}
                            </span>
                        </div>
                        <span className='inline-block capitalize'>Process</span>
                    </li>
                    <li onClick={() => navigate("/about-us")} className={`dropList_list w-full flex items-center cursor-pointer px-[17px] py-5 anim font-semibold ${isAboutActive ? "opacity-100" : "opacity-70 hover:opacity-100"}`}>
                        <div className="">
                            <span className='flex items-center justify-center header-linier mr-2.5'>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 0C3.57966 0 0 3.57966 0 8C0 12.4203 3.57966 16 8 16C12.4203 16 16 12.4203 16 8C16 3.57966 12.4203 0 8 0ZM9.25424 10.9424C8.65085 11.8508 8.0339 12.5492 7.00339 12.5492C6.29831 12.4339 6.00678 11.9254 6.16271 11.4169L7.49153 7.01695C7.52542 6.90847 7.47119 6.79322 7.36949 6.75932C7.27458 6.72542 7.07797 6.85424 6.91525 7.03051L6.11525 8C6.09491 7.83729 6.11525 7.5661 6.11525 7.46441C6.71864 6.55593 7.70847 5.84407 8.38644 5.84407C9.02373 5.91186 9.32881 6.42034 9.22034 6.98305L7.86441 11.3966C7.84407 11.4983 7.89831 11.6 7.99322 11.6271C8.08814 11.661 8.2983 11.5322 8.4678 11.3559L9.2678 10.3864C9.28814 10.5492 9.25424 10.8339 9.25424 10.9424ZM9.07797 5.19322C8.56949 5.19322 8.15593 4.82712 8.15593 4.27797C8.15593 3.73559 8.56949 3.36271 9.07797 3.36271C9.58644 3.36271 10 3.73559 10 4.27797C9.99322 4.82034 9.58644 5.19322 9.07797 5.19322Z" fill="white" />
                                </svg>
                                {/* <img src={contact_icon} alt="Contact Icon" className='w-4 h-4 object-cover' loading='lazy' /> */}
                            </span>
                        </div>
                        <span className='inline-block capitalize'>About Us</span>
                    </li>
                </ul>
            </header>
            {/* Mobile Menu */}
            <header className='relative lg:hidden z-50'>
                <div onClick={toggleMenu} className={`fixed top-5 left-5 items-center justify-center w-[50px] h-[50px] rounded-full bg-enthusia-primary border-[5px] border-[#C2CBCE] animation ${isMenuOpen ? "hidden" : "flex"} `}>
                    {/* <img src={Menubar} alt="menubar icon" loading='lazy' /> */}
                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.25 0.999756C11.25 0.585557 10.9142 0.249756 10.5 0.249756H0.75C0.335801 0.249756 0 0.585557 0 0.999756C0 1.41395 0.335801 1.74976 0.75 1.74976H10.5C10.9142 1.74976 11.25 1.41393 11.25 0.999756ZM0.75 4.74976H14.25C14.6642 4.74976 15 5.08559 15 5.49976C15 5.91396 14.6642 6.24976 14.25 6.24976H0.75C0.335801 6.24976 0 5.91396 0 5.49976C0 5.08559 0.335801 4.74976 0.75 4.74976ZM0.75 9.24976H7.5C7.91417 9.24976 8.25 9.58556 8.25 9.99976C8.25 10.4139 7.91417 10.7498 7.5 10.7498H0.75C0.335801 10.7498 0 10.4139 0 9.99976C0 9.58556 0.335801 9.24976 0.75 9.24976Z" fill="white" />
                    </svg>
                </div>
                <nav className={`fixed inset-0 w-full h-full bg-gradient-to-r from-[rgba(242,242,242,1)] to-[rgba(231,236,237,1)] space-y-9 px-5 overflow-y-auto pb-10 anim ${isMenuOpen ? "translate-x-0" : "translate-x-full"}`}>
                    {/* Enthusia Logo */}
                    <div className="relative">
                        <div className={`w-full grid place-items-center`} >
                            {/* <img src={Enthusia_Logo} alt="enthusia_logo" className="w-auto h-auto py-5 lg:py-8 2xl:py-[48px]" loading="lazy" /> */}
                            <svg className='my-5 lg:my-8 2xl:my-[46px]' width="175" height="38" viewBox="0 0 175 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <mask id="mask0_2350_22743" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="141" y="0" width="12" height="12">
                                    <path d="M147.148 11.4805C150.186 11.4805 152.648 9.01803 152.648 5.98047C152.648 2.9429 150.186 0.480469 147.148 0.480469C144.111 0.480469 141.648 2.9429 141.648 5.98047C141.648 9.01803 144.111 11.4805 147.148 11.4805Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_2350_22743)">
                                    <mask id="mask1_2350_22743" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="141" y="0" width="12" height="12">
                                        <path d="M152.707 6.03016C152.707 9.13016 150.207 11.6502 147.127 11.6502C144.047 11.6502 141.547 9.13016 141.547 6.03016C141.547 2.93016 144.047 0.410156 147.127 0.410156C150.207 0.410156 152.707 2.93016 152.707 6.03016ZM151.547 4.29016C151.427 2.54016 149.697 1.26016 149.067 0.950156C148.617 0.730156 148.207 0.610156 148.207 0.610156C148.117 0.590156 147.687 0.490156 147.137 0.500156C146.587 0.500156 146.197 0.610156 146.107 0.630156C146.107 0.630156 145.627 0.780156 145.177 1.00016C144.547 1.31016 142.847 2.54016 142.717 4.29016C142.637 5.46016 143.157 6.57016 144.847 8.21016C145.917 9.26016 147.017 10.0602 147.137 10.6402C147.247 10.0502 148.347 9.25016 149.427 8.20016C151.107 6.56016 151.637 5.44016 151.557 4.28016L151.547 4.29016Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask1_2350_22743)">
                                        <path d="M152.667 0.400391H147.117V11.4804H152.667V0.400391Z" fill="#009E74" />
                                        <path d="M147.146 0.400391H141.586V11.4804H147.146V0.400391Z" fill="#E84296" />
                                        <path style={{ mixBlendMode: "multiply" }} opacity="0.5" d="M147.115 8.6C149.997 8.6 152.335 6.45097 152.335 3.8C152.335 1.14903 149.997 -1 147.115 -1C144.232 -1 141.895 1.14903 141.895 3.8C141.895 6.45097 144.232 8.6 147.115 8.6Z" fill="url(#paint0_linear_2350_22743)" />
                                    </g>
                                    <mask id="mask2_2350_22743" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="142" y="0" width="11" height="12">
                                        <path d="M147.177 0.470703C144.497 0.470703 142.317 2.9507 142.297 6.0107C142.287 9.0707 144.447 11.5507 147.127 11.5507C149.807 11.5507 151.987 9.0707 152.007 6.0107C152.017 2.9507 149.857 0.470703 147.177 0.470703Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask2_2350_22743)">
                                        <path d="M144.417 10.6903C143.337 9.92031 143.257 8.78031 143.597 7.63031C143.987 6.32031 144.977 5.14031 145.827 3.97031C146.607 2.90031 147.147 2.00031 147.147 1.32031C147.147 2.00031 147.667 2.83031 148.477 3.91031C149.077 4.71031 149.827 5.57031 150.327 6.45031C150.717 7.15031 150.917 7.96031 150.847 8.77031C150.787 9.53031 150.517 10.3203 149.777 10.7703C149.477 10.9803 148.847 11.2703 148.247 11.3903C147.977 11.4503 147.377 11.5303 147.157 11.5303C147.027 11.5303 146.297 11.4603 145.807 11.3203C145.317 11.1803 144.877 10.9903 144.397 10.6803L144.417 10.6903Z" fill="white" />
                                    </g>
                                    <path d="M147.177 0.470703C144.497 0.470703 142.317 2.9507 142.297 6.0107C142.287 9.0707 144.447 11.5507 147.127 11.5507C149.807 11.5507 151.987 9.0707 152.007 6.0107C152.017 2.9507 149.857 0.470703 147.177 0.470703ZM149.257 7.4007C148.297 8.3407 147.257 9.0507 147.157 9.5707C147.057 9.0507 146.117 8.3307 145.147 7.4007C143.637 5.9307 143.157 4.9407 143.237 3.9007C143.347 2.3407 144.877 1.2707 145.447 0.990703C145.847 0.790703 146.267 0.690703 146.267 0.690703C146.347 0.670703 146.707 0.590703 147.207 0.600703C147.697 0.600703 148.067 0.670703 148.147 0.690703C148.147 0.690703 148.567 0.790703 148.967 0.990703C149.537 1.2707 151.067 2.3407 151.177 3.9007C151.247 4.9407 150.777 5.9407 149.267 7.4007H149.257Z" fill="#29AAE1" />
                                    <mask id="mask3_2350_22743" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="142" y="0" width="11" height="12">
                                        <path d="M147.177 0.470703C144.497 0.470703 142.317 2.9507 142.297 6.0107C142.287 9.0707 144.447 11.5507 147.127 11.5507C149.807 11.5507 151.987 9.0707 152.007 6.0107C152.017 2.9507 149.857 0.470703 147.177 0.470703Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask3_2350_22743)">
                                        <path style={{ mixBlendMode: "multiply" }} opacity="0.5" d="M144.398 10.6903C143.318 9.92031 143.268 8.77031 143.608 7.62031C143.998 6.31031 144.988 5.13031 145.838 3.97031C146.618 2.90031 147.148 2.00031 147.148 1.32031C147.148 2.00031 147.678 2.83031 148.478 3.91031C149.078 4.71031 149.828 5.57031 150.328 6.45031C150.718 7.15031 150.918 7.96031 150.848 8.77031C150.788 9.53031 150.478 10.2503 149.778 10.7703C149.478 10.9803 148.898 11.2703 148.258 11.3803C147.978 11.4303 147.398 11.5303 147.178 11.5303C147.048 11.5303 146.318 11.4503 145.828 11.3103C145.338 11.1703 144.818 11.0103 144.398 10.6803V10.6903Z" fill="url(#paint1_linear_2350_22743)" />
                                        <path style={{ mixBlendMode: "multiply" }} d="M145.559 11.0499C144.939 10.2799 144.909 9.12992 145.099 7.97992C145.319 6.66992 145.899 5.48992 146.389 4.31992C146.839 3.24992 147.149 2.34992 147.149 1.66992C147.149 2.34992 147.449 3.17992 147.919 4.25992C148.269 5.05992 148.699 5.91992 148.989 6.79992C149.219 7.49992 149.329 8.30992 149.289 9.11992C149.259 9.87992 149.079 10.5999 148.669 11.1199C148.499 11.3299 148.159 11.6199 147.789 11.7299C147.629 11.7799 147.289 11.8799 147.159 11.8799C147.079 11.8799 146.659 11.7999 146.379 11.6599C146.099 11.5199 145.799 11.3599 145.549 11.0299L145.559 11.0499Z" fill="url(#paint2_linear_2350_22743)" />
                                    </g>
                                </g>
                                <path d="M7.93 37.9213C7.23 37.9213 6.42 37.8513 5.51 37.7213C4.6 37.5913 3.73 37.2913 2.89 36.8213C2.05 36.3513 1.36 35.6413 0.82 34.6813C0.28 33.7313 0 32.4113 0 30.7413V12.5513C0 11.1113 0.22 9.91133 0.66 8.94133C1.1 7.97133 1.69 7.21133 2.42 6.65133C3.15 6.09133 3.96 5.70133 4.84 5.46133C5.72 5.23133 6.59 5.11133 7.44 5.11133C9.64 5.11133 11.59 5.14133 13.28 5.20133C14.97 5.26133 16.49 5.32133 17.84 5.40133C19.19 5.48133 20.42 5.57133 21.54 5.69133V11.1513H9.38C8.47 11.1513 7.77 11.3713 7.27 11.8113C6.77 12.2513 6.52 12.9313 6.52 13.8413V18.5513L19.43 18.9013V24.0513L6.52 24.4013V28.8913C6.52 29.6513 6.64 30.2513 6.89 30.6713C7.14 31.1013 7.48 31.3913 7.9 31.5513C8.33 31.7113 8.77 31.7913 9.24 31.7913H21.53V37.2513C20.24 37.4013 18.81 37.5113 17.24 37.6013C15.67 37.6913 14.08 37.7613 12.48 37.8213C10.88 37.8813 9.36 37.9113 7.92 37.9113L7.93 37.9213Z" fill="#124046" />
                                <path d="M25.9905 37.7004V14.0504H31.3605L32.5105 16.4704C33.3905 15.7404 34.5005 15.0804 35.8405 14.5104C37.1805 13.9404 38.5505 13.6504 39.9605 13.6504C41.9005 13.6504 43.4005 14.0304 44.4505 14.7904C45.5105 15.5504 46.2505 16.5704 46.6705 17.8504C47.1005 19.1304 47.3105 20.5504 47.3105 22.1004V37.6904H40.7905V22.9304C40.7905 22.1704 40.6505 21.5404 40.3705 21.0404C40.0905 20.5404 39.7005 20.1604 39.2005 19.9004C38.7005 19.6404 38.1005 19.5004 37.3905 19.5004C36.7705 19.5004 36.1805 19.5704 35.6105 19.7204C35.0405 19.8704 34.4905 20.0704 33.9805 20.3404C33.4705 20.6004 32.9705 20.9104 32.5005 21.2704V37.7004H25.9805H25.9905Z" fill="#124046" />
                                <path d="M61.1819 37.7014C59.3619 37.7014 57.8819 37.4414 56.7519 36.9114C55.6219 36.3814 54.8019 35.5014 54.3119 34.2714C53.8119 33.0414 53.5919 31.3814 53.6519 29.2914L53.8719 19.2914H50.1719V15.1114L54.0919 14.0514L54.9719 7.44141H60.1219V14.0514H65.6719V19.2914H60.1219V29.2414C60.1219 29.8914 60.1819 30.4214 60.3019 30.8314C60.4219 31.2414 60.5919 31.5714 60.8119 31.8214C61.0319 32.0714 61.2919 32.2514 61.5819 32.3714C61.8719 32.4914 62.1719 32.5614 62.4619 32.5914L65.4619 32.8514V37.6914H61.1919L61.1819 37.7014Z" fill="#124046" />
                                <path d="M69.3281 37.7006V4.14062H75.8481V16.4706C76.6981 15.7706 77.8081 15.1206 79.1781 14.5306C80.5481 13.9406 81.9481 13.6506 83.3881 13.6506C85.1781 13.6506 86.6081 14.0006 87.6581 14.7106C88.7181 15.4106 89.4781 16.4006 89.9481 17.6606C90.4181 18.9206 90.6481 20.3906 90.6481 22.0606V37.7006H84.1281V22.9006C84.1281 22.1406 83.9781 21.5106 83.6881 21.0106C83.3981 20.5106 82.9981 20.1406 82.4981 19.8906C81.9981 19.6406 81.4081 19.5206 80.7381 19.5206C80.1181 19.5206 79.5281 19.6006 78.9581 19.7606C78.3881 19.9206 77.8381 20.1306 77.3281 20.4006C76.8181 20.6606 76.3181 20.9606 75.8481 21.2806V37.7106H69.3281V37.7006Z" fill="#124046" />
                                <path d="M102.231 37.9608C100.121 37.9608 98.4614 37.3408 97.2514 36.0908C96.0514 34.8408 95.4414 33.0708 95.4414 30.7808V14.0508H101.961V28.8508C101.961 30.1708 102.281 31.0608 102.911 31.5108C103.541 31.9708 104.371 32.1908 105.401 32.1908C106.221 32.1908 107.071 32.0408 107.931 31.7308C108.801 31.4208 109.571 31.0008 110.241 30.4808V14.0508H116.801V37.7008H111.381L110.231 35.2808C109.171 35.9808 107.951 36.6108 106.571 37.1508C105.191 37.6908 103.741 37.9608 102.211 37.9608H102.231Z" fill="#124046" />
                                <path d="M130.33 37.9603C129.6 37.9603 128.8 37.9403 127.95 37.8903C127.1 37.8503 126.25 37.7803 125.4 37.6903C124.55 37.6003 123.76 37.4903 123.02 37.3603C122.29 37.2303 121.67 37.0703 121.17 36.9003V33.0703H131.65C132.27 33.0703 132.77 33.0203 133.17 32.9203C133.57 32.8203 133.86 32.6303 134.05 32.3503C134.24 32.0703 134.34 31.6703 134.34 31.1403V30.3903C134.34 29.8003 134.15 29.3503 133.77 29.0203C133.39 28.6903 132.68 28.5403 131.66 28.5403H127.96C126.61 28.5403 125.39 28.3303 124.3 27.9203C123.21 27.5103 122.35 26.8303 121.72 25.8903C121.09 24.9503 120.77 23.6903 120.77 22.1003V20.7303C120.77 19.2303 121.06 17.9703 121.65 16.9403C122.24 15.9103 123.22 15.1303 124.6 14.6103C125.98 14.0803 127.87 13.8203 130.28 13.8203C131.25 13.8203 132.28 13.8703 133.39 13.9703C134.49 14.0703 135.53 14.2103 136.52 14.3703C137.5 14.5303 138.29 14.7203 138.88 14.9203V18.7503H128.97C128.12 18.7503 127.47 18.8903 127.03 19.1703C126.59 19.4503 126.37 19.9703 126.37 20.7303V21.4303C126.37 21.9603 126.47 22.3503 126.68 22.6003C126.89 22.8503 127.2 23.0303 127.63 23.1303C128.06 23.2303 128.59 23.2803 129.24 23.2803H133.03C135.44 23.2803 137.19 23.8303 138.29 24.9303C139.39 26.0303 139.94 27.5503 139.94 29.4903V31.6003C139.94 33.1903 139.57 34.4403 138.82 35.3703C138.07 36.3003 136.98 36.9603 135.56 37.3503C134.14 37.7503 132.4 37.9403 130.34 37.9403L130.33 37.9603Z" fill="#124046" />
                                <path d="M150.5 14.0508H143.98V37.7008H150.5V14.0508Z" fill="#124046" />
                                <path d="M160.542 37.9607C158.752 37.9607 157.292 37.4307 156.182 36.3807C155.062 35.3207 154.512 33.8507 154.512 31.9707V29.8607C154.512 28.0707 155.142 26.5907 156.402 25.4307C157.662 24.2707 159.632 23.6907 162.302 23.6907H168.332V22.0607C168.332 21.3307 168.202 20.7207 167.932 20.2307C167.672 19.7507 167.172 19.3907 166.452 19.1707C165.732 18.9507 164.642 18.8407 163.172 18.8407H156.082V15.0507C157.232 14.7007 158.552 14.3907 160.072 14.1207C161.582 13.8607 163.412 13.7207 165.552 13.7207C167.522 13.7207 169.202 13.9607 170.592 14.4207C171.982 14.8907 173.042 15.7007 173.762 16.8407C174.482 17.9907 174.842 19.5707 174.842 21.6007V37.6807H169.692L168.632 35.1707C168.422 35.4107 168.072 35.6707 167.572 35.9607C167.072 36.2507 166.462 36.5507 165.742 36.8607C165.022 37.1707 164.212 37.4307 163.322 37.6307C162.422 37.8407 161.492 37.9407 160.522 37.9407L160.542 37.9607ZM163.842 33.4207C164.132 33.4207 164.462 33.3807 164.832 33.3107C165.202 33.2407 165.582 33.1507 165.982 33.0507C166.382 32.9507 166.742 32.8507 167.082 32.7407C167.422 32.6407 167.702 32.5407 167.922 32.4507C168.142 32.3607 168.282 32.3007 168.342 32.2707V26.7607L164.382 27.0207C163.232 27.1107 162.392 27.4007 161.852 27.9007C161.312 28.4007 161.032 29.0807 161.032 29.9307V30.9007C161.032 31.4907 161.152 31.9707 161.402 32.3507C161.652 32.7307 161.992 33.0007 162.412 33.1707C162.842 33.3307 163.312 33.4107 163.842 33.4107V33.4207Z" fill="#124046" />
                                <defs>
                                    <linearGradient id="paint0_linear_2350_22743" x1="135.215" y1="3.8" x2="168.035" y2="3.8" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#29AAE1" />
                                        <stop offset="0.22" stopColor="#E94096" />
                                        <stop offset="0.51" stopColor="#009E74" />
                                        <stop offset="0.75" stopColor="#F9A124" />
                                        <stop offset="1" stopColor="#EF5138" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_2350_22743" x1="147.148" y1="-2.33969" x2="147.148" y2="20.5903" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#29AAE1" />
                                        <stop offset="0.22" stopColor="#E94096" />
                                        <stop offset="0.51" stopColor="#00B38C" />
                                        <stop offset="0.75" stopColor="#F9A124" />
                                        <stop offset="1" stopColor="#EF5138" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_2350_22743" x1="147.149" y1="-1.99008" x2="147.149" y2="20.9499" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#29AAE1" />
                                        <stop offset="0.22" stopColor="#E94096" />
                                        <stop offset="0.51" stopColor="#00B38C" />
                                        <stop offset="0.75" stopColor="#F9A124" />
                                        <stop offset="1" stopColor="#EF5138" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span className={`block w-full h-[3px] bg-gradient-to-r from-[rgba(255,255,255,0.1)] via-[#134046] to-[rgba(255,255,255,0.1)] z-10`}></span>
                        </div>
                        <svg onClick={toggleMenu} className={`absolute top-[35px] right-0 z-50 anim`} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 9.70342L2.03802 15.6654C1.81496 15.8885 1.53105 16 1.18631 16C0.841572 16 0.557668 15.8885 0.334601 15.6654C0.111534 15.4423 0 15.1584 0 14.8137C0 14.4689 0.111534 14.185 0.334601 13.962L6.29658 8L0.334601 2.03802C0.111534 1.81496 0 1.53105 0 1.18631C0 0.841572 0.111534 0.557668 0.334601 0.334601C0.557668 0.111534 0.841572 0 1.18631 0C1.53105 0 1.81496 0.111534 2.03802 0.334601L8 6.29658L13.962 0.334601C14.185 0.111534 14.4689 0 14.8137 0C15.1584 0 15.4423 0.111534 15.6654 0.334601C15.8885 0.557668 16 0.841572 16 1.18631C16 1.53105 15.8885 1.81496 15.6654 2.03802L9.70342 8L15.6654 13.962C15.8885 14.185 16 14.4689 16 14.8137C16 15.1584 15.8885 15.4423 15.6654 15.6654C15.4423 15.8885 15.1584 16 14.8137 16C14.4689 16 14.185 15.8885 13.962 15.6654L8 9.70342Z" fill="#134046" />
                        </svg>
                    </div>
                    {/* Home */}
                    <div onClick={() => { navigate("/"); toggleMenu(); MobileMenuClose() }} className='flex items-center justify-start space-x-2.5' >
                        <span className='header-linier-mobile'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_2340_1588)">
                                    <path d="M8 9.99414C6.89544 9.99414 6 10.8896 6 11.9941V15.9941H10V11.9941C10 10.8896 9.10456 9.99414 8 9.99414Z" fill="#134046" />
                                    <path d="M11.3333 11.9939V15.9939H14C15.1046 15.9939 16 15.0985 16 13.9939V7.91329C16.0002 7.56694 15.8656 7.23413 15.6247 6.98529L9.95934 0.8606C8.95972 -0.220963 7.27259 -0.287369 6.19103 0.712256C6.13966 0.759756 6.09016 0.809225 6.04269 0.8606L0.387344 6.98329C0.139156 7.23316 -9.37027e-05 7.5711 4.73072e-08 7.92329V13.9939C4.73072e-08 15.0985 0.895437 15.9939 2 15.9939H4.66666V11.9939C4.67912 10.1761 6.14684 8.69157 7.91894 8.64882C9.75031 8.60463 11.3194 10.1144 11.3333 11.9939Z" fill="#134046" />
                                    <path d="M8 9.99414C6.89544 9.99414 6 10.8896 6 11.9941V15.9941H10V11.9941C10 10.8896 9.10456 9.99414 8 9.99414Z" fill="#134046" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_2340_1588">
                                        <rect width="16" height="16" fill="#134046" transform="translate(0 -0.00195312)" />
                                    </clipPath>
                                </defs>
                            </svg>

                            {/* <img src={mobile_home_icon} alt="Home Icon" className='w-4 h-4 object-contain' loading='lazy' /> */}
                        </span>
                        <span className='text-base font-medium text-enthusia-gray hover:text-enthusia-primary'>Home</span>
                    </div>
                    {/* Services */}
                    <div className='relative flex flex-col items-center justify-between space-x-2.5 h-auto' >
                        <div className="w-full flex items-center justify-between">
                            <div onClick={() => { navigate("/services"); toggleMenu(); MobileMenuClose() }} className="w-full flex items-center justify-start space-x-2.5">
                                <span className='header-linier-mobile'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_2340_1583)">
                                            <path d="M8 12H2C0.893333 12 0 11.1067 0 10C0 9.63181 0.298477 9.33333 0.666667 9.33333H4.99585C5.27339 9.33333 5.53692 9.4552 5.71667 9.66667C5.89641 9.87813 6.15995 10 6.43748 10H7.00667C7.55895 10 8.00667 10.4477 8.00667 11V11.9933C8.00667 11.997 8.00368 12 8 12ZM16 8C16 6.9 15.1 6 14 6H11.3333C10.2333 6 9.33333 6.9 9.33333 8V15C9.33333 15.5523 9.78105 16 10.3333 16H15C15.5523 16 16 15.5523 16 15V8ZM8 8C8 6.16 9.49333 4.66667 11.3333 4.66667H14C14.3487 4.66667 14.6667 4.41104 14.6667 4.06239V1C14.6667 0.447715 14.219 0 13.6667 0H2.33333C1.78105 0 1.33333 0.447715 1.33333 1V7C1.33333 7.55228 1.78105 8 2.33333 8H5.61585C5.89339 8 6.15728 8.12229 6.33703 8.33376C6.51632 8.54468 6.77954 8.66667 7.05637 8.66667H7.33333C7.70152 8.66667 8 8.36819 8 8Z" fill="#134046" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_2340_1583">
                                                <rect width="16" height="16" fill="#134046" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {/* <img src={mobile_services_icon} alt="Services Icon" className='w-4 h-4 object-contain' loading='lazy' /> */}
                                </span>
                                <span className='text-base font-medium text-enthusia-gray hover:text-enthusia-primary'>Services</span>
                            </div>
                            <div onClick={handleArrowClickServices} className="flex items-center justify-center">
                                <span className='header-linier-mobile'>
                                    <img src={bottom_arrow} alt="Bottom Arrow" className={`w-auto h-auto object-contain anim ${isActiveServices ? "" : "-rotate-90"}`} loading='lazy' />
                                </span>
                            </div>
                        </div>
                        {/* Services submenu */}
                        <ul className={`w-full top-0 pl-10 !ml-0 ${isActiveServices ? "h-full overflow-y-visible" : "h-0 overflow-y-hidden"}`}>
                            {/* Custom Services */}
                            <li className='flex flex-col items-center justify-between border-b border-[#D2D2D2] hover:border-enthusia-primary py-[2px] animation pt-2'>
                                <div className="flex w-full items-center">
                                    <span onClick={() => { navigate("/services/custom-services"); toggleMenu(); MobileMenuClose() }} className='block w-full text-xs font-medium text-enthusia-gray hover:text-enthusia-primary'>Custom Services</span>
                                    <div onClick={handleArrowClick} className="">
                                        <span className="header-linier-mobile">
                                            <img src={bottom_arrow} alt="Bottom Arrow" className={`w-auto h-auto anim ${isActive ? "" : "-rotate-90"}`} loading='lazy' />
                                        </span>
                                    </div>
                                </div>
                                {/* Custom Services Submenu */}
                                <ul className={`w-full !list-disc ml-[30px] ${isActive ? "h-full overflow-y-visible" : "h-0 overflow-y-hidden"}`}>
                                    <li onClick={() => { navigate("/services/custom-services/saas-development"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>SaaS development</li>
                                    <li onClick={() => { navigate("/services/custom-services/web-development"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>web App development</li>
                                    <li onClick={() => { navigate("/services/custom-services/mobile-app-development"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>Mobile app development</li>
                                    <li onClick={() => { navigate("/services/custom-services/software-development"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>software development</li>
                                    <li onClick={() => { navigate("/services/custom-services/data-analytics"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>Data Analytics & Al</li>
                                    <li onClick={() => { navigate("/services/custom-services/app-modernization"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>App Modernization</li>
                                    <li onClick={() => { navigate("/services/custom-services/digital-transformation"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>Digital transformation</li>
                                    <li onClick={() => { navigate("/services/custom-services/software-testing"); toggleMenu(); MobileMenuClose() }} className='text-xs font-medium text-enthusia-gray hover:text-enthusia-primary py-2 animation'>QA & Testing</li>
                                </ul>
                            </li>
                            {/* Boosting Startups */}
                            <li onClick={() => { navigate("/services/boosting-startups"); toggleMenu(); MobileMenuClose() }} className='block text-xs font-medium text-enthusia-gray hover:text-enthusia-primary border-b border-[#D2D2D2] hover:border-enthusia-primary py-2 animation'>Boosting Startups</li>
                            {/* Technologies */}
                            <li className='flex flex-col items-center justify-between border-b border-[#D2D2D2] hover:border-enthusia-primary py-2 animation'>
                                <div className="flex w-full items-center">
                                    <span onClick={() => { navigate("/services/technologies"); toggleMenu(); MobileMenuClose() }} className='block w-full text-xs font-medium text-enthusia-gray hover:text-enthusia-primary'>Technologies</span>
                                </div>
                            </li>
                            {/* Industries */}
                            <li onClick={() => { navigate("/services/industries"); toggleMenu(); MobileMenuClose() }} className='block text-xs font-medium text-enthusia-gray hover:text-enthusia-primary border-b border-[#D2D2D2] hover:border-enthusia-primary py-2 animation'>Industries</li>
                            {/* Hire Resources */}
                            <li onClick={() => { navigate("/services/hire-resources"); toggleMenu(); MobileMenuClose() }} className='block text-xs font-medium text-enthusia-gray hover:text-enthusia-primary border-b border-[#D2D2D2] hover:border-enthusia-primary py-2 animation'>Hire Resources</li>
                            {/* Get A Consultation */}
                            <li onClick={() => { navigate("/services/consultation"); toggleMenu(); MobileMenuClose() }} className='block text-xs font-medium text-enthusia-gray hover:text-enthusia-primary border-b border-[#D2D2D2] hover:border-enthusia-primary py-2 animation'>Get A Consultation</li>
                        </ul>
                    </div>
                    {/* Process */}
                    <div onClick={() => { navigate("/process"); toggleMenu(); MobileMenuClose() }} className='flex items-center justify-start space-x-2.5' >
                        <span className='header-linier-mobile'>
                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.6562 9.46887L13.0941 10.2186L13.844 10.7812H12.1522C11.9426 9.97472 11.2151 9.375 10.3437 9.375H5.65625C4.78491 9.375 4.05737 9.97472 3.84778 10.7812H2.8125C1.77844 10.7812 0.9375 9.94031 0.9375 8.90625C0.9375 7.87219 1.77844 7.03125 2.8125 7.03125H3.84778C4.05737 7.83778 4.78491 8.4375 5.65625 8.4375H10.3438C11.2151 8.4375 11.9426 7.83778 12.1522 7.03125H13.1875C14.7384 7.03125 16 5.76966 16 4.21875C16 2.66784 14.7384 1.40625 13.1875 1.40625H12.1522C11.9426 0.599719 11.2151 0 10.3438 0H5.65625C4.78491 0 4.05737 0.599719 3.84778 1.40625H3.4375L1.68725 0.093875L1.12525 0.843625L1.87513 1.40625H0V2.34375H1.87513L1.12525 2.90637L1.68725 3.65612L3.4375 2.34375H3.84778C4.05737 3.15028 4.78491 3.75 5.65625 3.75H10.3438C11.2151 3.75 11.9426 3.15028 12.1522 2.34375H13.1875C14.2216 2.34375 15.0625 3.18469 15.0625 4.21875C15.0625 5.25281 14.2216 6.09375 13.1875 6.09375H12.1522C11.9426 5.28722 11.2151 4.6875 10.3438 4.6875H5.65625C4.78491 4.6875 4.05737 5.28722 3.84778 6.09375H2.8125C1.26159 6.09375 0 7.35534 0 8.90625C0 10.4572 1.26159 11.7187 2.8125 11.7187H3.84778C4.05737 12.5253 4.78491 13.125 5.65625 13.125H10.3438C11.2151 13.125 11.9426 12.5253 12.1522 11.7187H13.844L13.0941 12.2814L13.6562 13.0311L16 11.25L13.6562 9.46887Z" fill="#134046" />
                            </svg>
                            {/* <img src={mobile_process_icon} alt="Home Icon" className='w-4 h-4 object-contain' loading='lazy' /> */}
                        </span>
                        <span className='text-base font-medium text-enthusia-gray hover:text-enthusia-primary'>Process</span>
                    </div>
                    {/* About Us */}
                    <div onClick={() => { navigate("/about-us"); toggleMenu(); MobileMenuClose() }} className='flex items-center justify-start space-x-2.5' >
                        <span className='header-linier-mobile'>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 0C3.57966 0 0 3.57966 0 8C0 12.4203 3.57966 16 8 16C12.4203 16 16 12.4203 16 8C16 3.57966 12.4203 0 8 0ZM9.25424 10.9424C8.65085 11.8508 8.0339 12.5492 7.00339 12.5492C6.29831 12.4339 6.00678 11.9254 6.16271 11.4169L7.49153 7.01695C7.52542 6.90847 7.47119 6.79322 7.36949 6.75932C7.27458 6.72542 7.07797 6.85424 6.91525 7.03051L6.11525 8C6.09491 7.83729 6.11525 7.5661 6.11525 7.46441C6.71864 6.55593 7.70847 5.84407 8.38644 5.84407C9.02373 5.91186 9.32881 6.42034 9.22034 6.98305L7.86441 11.3966C7.84407 11.4983 7.89831 11.6 7.99322 11.6271C8.08814 11.661 8.2983 11.5322 8.4678 11.3559L9.2678 10.3864C9.28814 10.5492 9.25424 10.8339 9.25424 10.9424ZM9.07797 5.19322C8.56949 5.19322 8.15593 4.82712 8.15593 4.27797C8.15593 3.73559 8.56949 3.36271 9.07797 3.36271C9.58644 3.36271 10 3.73559 10 4.27797C9.99322 4.82034 9.58644 5.19322 9.07797 5.19322Z" fill="#134046" />
                            </svg>

                            {/*  <img src={mobile_contact_icon} alt="Home Icon" className='w-4 h-4 object-contain' loading='lazy' /> */}
                        </span>
                        <span className='text-base font-medium text-enthusia-gray hover:text-enthusia-primary'>About Us</span>
                    </div>
                    {/* contact Button */}
                    <Button onClick={() => { navigate("/about-us"); toggleMenu(); MobileMenuClose() }} button_name="Contact Us" button_class="px-[30px] py-2" img_class="hidden" />
                </nav>
            </header>
        </React.Fragment>
    )
}

export default Header