const TechnologiesTabBox = ({ technologies_box_class, src, alt, technologies_title, technologies_title_class }) => {
    return (
        <div className={`flex flex-wrap flex-col justify-center group ${technologies_box_class}`}>
            <div className="w-9 h-9 sm:w-[50px] sm:h-[50px] lg:w-[70px] lg:h-[70px] anim mx-auto scale-90 group-hover:scale-100">
                <img src={src} alt={alt} className='w-full h-full md:w-auto md:h-auto object-contain md:object-cover mx-auto' loading="lazy" />
            </div>
            <span className={`inline-block text-center text-xs md:text-base lg:text-lg leading-5 font-light text-[#565656] group-hover:text-enthusia-primary pt-3 ${technologies_title_class}`}>{technologies_title}</span>
        </div>
    )
}

export default TechnologiesTabBox;