import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Python from '../../Assets/svg/python.svg'
import Javascript from '../../Assets/svg/Javascript.svg'
import NodeJs from '../../Assets/svg/nodejs.svg'
import Php from '../../Assets/svg/php.svg'
import ExpressJs from '../../Assets/svg/expressjs.svg'
import Django from '../../Assets/svg/django.svg'
import Flask from '../../Assets/svg/flask.svg'
import Laravel from '../../Assets/svg/laravel.svg'
import RubyOnRails from '../../Assets/svg/rubyonrails.svg'
import AspNet from '../../Assets/svg/asPnet.svg'

function WebBackendTechnologies() {
  return (
    <div className='w-full'>
      <div className="">
        <p className='font-medium text-[#737373]'>Languages :</p>
        <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={NodeJs} alt="NodeJs" technologies_title="NodeJs" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Python} alt="Python" technologies_title="Python" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Javascript} alt="Javascript" technologies_title="Javascript" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Php} alt="Php" technologies_title="Php" />
        </div>
      </div>
      <div className="pt-[45px]">
        <p className='font-medium text-[#737373]'>Frameworks & Tools :</p>
        <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={ExpressJs} alt="ExpressJs" technologies_title="ExpressJs" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Django} alt="Django" technologies_title="Django" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Flask} alt="Flask" technologies_title="Flask" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Laravel} alt="Laravel" technologies_title="Laravel" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={RubyOnRails} alt="Ruby on Rails" technologies_title="Ruby on Rails" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={AspNet} alt="ASP.NET" technologies_title="ASP.NET" />
        </div>
      </div>
    </div>
  )
}

export default WebBackendTechnologies