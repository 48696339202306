import React from 'react'
import customData from '../../static/SubServices.json'
import DOMPurify from 'dompurify';
import SEO from '../Common/SEO'
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from '../../Utility/HeadingTitle';
import LeftBorderHeading from '../../Utility/LeftBorderHeading';
import ScopeServicesBox from '../../Utility/ScopeServicesBox';
import OursProcessSection from '../../Utility/OursProcessSection';
import IndustriesWeServe from '../Others/IndustriesWeServe';
import ConsultationFrom from '../Others/ConsultationFrom'

import IndustriesIcon from "../../Assets/svg/industries_icon.svg";

function Industries() {
    const data = customData.Industries;
    const isHDImages = false;
    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className='wrapper'>
                {/* Industries Box*/}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[800px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? imageUrl + data.headingBox.src : IndustriesIcon}
                            alt="Industries We Serve"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                </div>
                {/* Industries */}
                <div className="pt-[50px] lg:pt-[100px]">
                    <HeadingTitle
                        title_name={data.IndustriesList.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <p className='text-enthusia-primary text-lg font-normal text-justify indent-16 pt-[50px]'>{data.IndustriesList.paragraph}</p>
                </div>
                {/* Industries We Serve */}
                <IndustriesWeServe industries_class="pt-5 md:pt-[50px] md:-mx-[15px]" />
                {/* Why Choose Us */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <LeftBorderHeading heading_title="Why Choose Us " />
                    <div className="flex flex-wrap pt-[35px] md:-mx-[15px]">
                        {(data.WhyChoose).map((data) => (
                            <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
                        ))}
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_2={data.Contact.paragraph_2}
                    />
                </div>
            </main>
        </React.Fragment>
    )
}

export default Industries