import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import ReactNative from '../../Assets/svg/reactjs.svg'
import Dart from '../../Assets/svg/dart.svg'
import Flutter from '../../Assets/svg/flutter.svg'
import Kotlin from '../../Assets/svg/kotlin.svg'
import Objectivec from '../../Assets/svg/objectiveC.svg'
import Java from '../../Assets/svg/Java.svg'
import Swift from '../../Assets/svg/swift.svg'

function AppFrontendTechnologies() {
  return (
    <div className='w-full'>
      <div className="">
        <p className='leading-9 font-medium text-[#737373]'>Languages :</p>
        <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Kotlin} alt="Kotlin" technologies_title="Kotlin" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Objectivec} alt="Objective-C" technologies_title="Objective-C" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={ReactNative} alt="ReactNative" technologies_title="Native" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Dart} alt="Dart" technologies_title="Dart" />
        </div>
      </div>
      <div className="pt-[45px]">
        <p className='leading-9 font-medium text-[#737373]'>Frameworks & Tools :</p>
        <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Java} alt="Java" technologies_title="Java" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Swift} alt="Swift" technologies_title="Swift" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={ReactNative} alt="ReactNative" technologies_title="ReactNative" />
          <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Flutter} alt="Flutter" technologies_title="Flutter" />
        </div>
      </div>
    </div>
  )
}

export default AppFrontendTechnologies