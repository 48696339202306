import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const SEO = ({ title, description, keywords, canonicalLink }) => {
    return (
        <HelmetProvider>
            <Helmet>
                {/* Static meta tag  */}
                <meta http-equiv="strict-transport-security" content="max-age=31536000" />
                <meta name="robots" content="index, follow" />
                <meta name="author" content="Enthusia Softech Pvt Ltd" />
                <meta name="publisher" content="Enthusia Softech Pvt Ltd" />
                <meta name="copyright" content="Enthusia Softech Pvt Ltd" />
                <meta name="google-site-verification" content="your-verification-code" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta name="Googlebot" content="index, follow, max-snippet:100, max-image-preview:standard, max-image-preview:large" />
                <meta name="Googlebot-Image" content="max-image-preview:standard, max-image-preview:large" />
                <meta name="Googlebot-Video" content="max-video-preview:-1" />
                {/* Open Graph Facebook */}
                {/* <meta property="og:title" content="Your Page Title" />
            <meta property="og:description" content="A brief description of your page content" />
            <meta property="og:image" content="URL to an image for social sharing" />
            <meta property="og:url" content="https://www.yourwebsite.com/your-page" />
            <meta property="og:type" content="website" /> */}
                {/* twitter */}
                {/*   <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Your Page Title" />
            <meta name="twitter:description" content="A brief description of your page content" />
            <meta name="twitter:image" content="URL to an image for Twitter sharing" /> */}
                {/* Dynamic meta tag  */}
                <title> {title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <link rel="canonical" href={canonicalLink} />
            </Helmet>
        </HelmetProvider>
    )
}

export default SEO