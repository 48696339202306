import React from 'react'
import DOMPurify from 'dompurify';
import CustomData from '../../static/CustomSubServices.json'
import { imageUrl } from '../../Api/baseUrl';
import SEO from '../Common/SEO'
import HeadingTitle from '../../Utility/HeadingTitle'
import ScopeServicesBox from '../../Utility/ScopeServicesBox'
import Development from '../../Utility/Development'
import LeftBorderHeading from '../../Utility/LeftBorderHeading'
import OursProcessSection from '../../Utility/OursProcessSection'
import ConsultationFrom from '../Others/ConsultationFrom'
import DigitalTransformationOfferServices from '../Others/DigitalTransformationOfferServices'

import DigitalTransformationBox from '../../Assets/svg/digital_transformation_box.svg'
import DigitalTransformationDevelopmentBox from '../../Assets/Webp/digital_transformation_development_box.webp'


function DigitalTransformation() {
    const data = CustomData.DigitalTransformation;
    const isHDImages = false;
    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className="wrapper">
                {/* Digital Transformation */}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[900px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? (imageUrl + data.headingBox.src) : DigitalTransformationBox}
                            alt="Digital Transformation"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                </div>
                {/* Digital Transformation Development */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.DigitalTransformation.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? (data + data.DigitalTransformation.src) : DigitalTransformationDevelopmentBox}
                        alt="Digital Transformation Development"
                        title_class="hidden"
                        development_paragraph="In the age of rapid technological advancement, embracing digital transformation is no longer an option—it's a necessity. At Enthusia Softech, we specialize in guiding organizations through their digital transformation journey, enabling them to thrive in an increasingly digital world. Whether you're seeking to streamline operations, enhance customer experiences, or gain a competitive edge, our digital transformation services are designed to catalyze your success."
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.DigitalTransformation.buttonName}
                    />
                </div>
                {/* Our Approach */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title={data.OurApproach.heading} />
                    <p className="text-enthusia-primary font-normal text-justify pl-[18px] pt-[30px]">{data.OurApproach.paragraph}</p>
                </div>
                {/* Services We Offer*/}
                <div className="pt-8 sm:pt-[50px] md:pt-[80px]">
                    <DigitalTransformationOfferServices />
                </div>
                {/* Why Choose Us */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Why Choose Us" />
                    <div className="flex flex-wrap pt-5 md:pt-[35px] md:-mx-[15px]">
                        {data.WhyChoose.map((data) => (
                            <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
                        ))}
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_2={data.Contact.paragraph_2}
                    />
                </div>
            </main>
        </React.Fragment>
    )
}

export default DigitalTransformation