import React from "react";
import DOMPurify from "dompurify";
import SEO from "../Common/SEO";
import data from "../../static/SubServices.json";
import { imageUrl } from "../../Api/baseUrl";

import LeftBorderHeading from "../../Utility/LeftBorderHeading";
import ScopeServicesBox from "../../Utility/ScopeServicesBox";
import OursProcessSection from "../../Utility/OursProcessSection";
import CustomOfferServices from "../Others/CustomOfferServices";
import ConsultationFrom from "../Others/ConsultationFrom";

import VisionSolutionSuccess from "../../Assets/svg/vision_solution_success.svg";

function CustomServices() {
    const CustomData = data.CustomServices;
    const isHDImages = false;
    return (
        <React.Fragment>
            <SEO
                title={CustomData.SEO.title}
                description={CustomData.SEO.Description}
                canonicalLink={CustomData.SEO.CanonicalLink}
                keywords={CustomData.SEO.keywords}
            />
            <main className="wrapper">
                {/* Vision Solution Success*/}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[920px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(CustomData.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? imageUrl + CustomData.headingBox.src : VisionSolutionSuccess}
                            alt="Vision Solution Success"
                            className="w-full h-full"
                            loading="lazy"
                        />
                    </div>
                </div>
                {/* CUSTOM SERVICES */}
                <CustomOfferServices />
                {/* Why Choose Our Custom Software Development Services */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title={CustomData.WhyChoose.heading} />
                    <div className="flex flex-wrap pt-[35px] md:-mx-[15px]">
                        {(CustomData.WhyChoose.WhyChooseBox).map((data) => (
                            <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
                        ))}
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-3.5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <ConsultationFrom
                        main_title={CustomData.Contact.MainTitle}
                        paragraph_1={CustomData.Contact.paragraph}
                        paragraph_2={CustomData.Contact.paragraph_2}
                    />
                </div>
            </main>
        </React.Fragment>
    );
}

export default CustomServices;
