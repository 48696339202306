import React, { useState } from 'react'
import CookiesData from '../../static/PolicyAndConditions.json'
import { baseUrl, imageUrl } from '../../Api/baseUrl';
import Cookies from 'js-cookie';
import Modal from '../Common/Modal';
import CookiePolicy from './CookiePolicy';
import Cookie_Icon from "../../Assets/svg/cookies_icon.svg"
import MinusIcon from '../../Assets/svg/minus_icon.svg'
import PlusIcon from '../../Assets/svg/plus_icon.svg'

function CustomCookie({ handleCustomClose, handleClose }) {
    const data = CookiesData.CustomCookie;
    const isHDImages = false;
    const [openIndex, setOpenIndex] = useState(null);
    const [cookiesAccept, setCookiesAccept] = useState({
        PerformanceCookies: true,
        FunctionalityCookies: true,
        ThirdPartyCookies: true,
        OtherCookies: true
    });

    const [cookiePolicyPopUp, setCookiePolicyPopUp] = useState(false)

    const toggleAccordion = (index) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    console.log({ cookiesAccept });

    const handleConsent = () => {
        if (cookiesAccept) {
            measureAndStorePerformance();
            Cookies.set('cookieConsent', 'true', { expires: 365 });
        }
        handleClose(false)
    };

    const measureAndStorePerformance = () => {
        // Measure Page Load Time
        const startTime = performance.now();

        // Attach an event listener to measure when the page has fully loaded
        window.addEventListener('load', () => {
            const endTime = performance.now();
            const pageLoadTime = endTime - startTime;
            Cookies.set('pageLoadTime', pageLoadTime.toFixed(2)); // Store in a cookie
        });
        // Simulate Server Response Time
        const serverStartTime = performance.now();
        // Simulate a server request (replace this with your actual API request)
        fetch(`${baseUrl}blogs/getInfohub`)
            .then(response => {
                const serverEndTime = performance.now();
                const serverResponseTime = serverEndTime - serverStartTime;
                Cookies.set('serverResponseTime', serverResponseTime.toFixed(2));
                return response.json();
            })
            .catch(error => {
                Cookies.set('error', 'Error occurred'); // Store error message in a cookie
            });
        // Simulate User Interactions
        document.addEventListener('click', () => {
            Cookies.set('userInteraction', 'User clicked'); // Store user interaction in a cookie
        });

        // Simulate Browser and Device Information
        const browserInfo = `Browser: ${navigator.userAgent}, Screen Size: ${window.screen.width}x${window.screen.height}`;
        Cookies.set('browserInfo', browserInfo);
        // Simulate Network Performance
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        if (connection) {
            const networkInfo = `Type: ${connection.effectiveType}, Downlink: ${connection.downlink} Mbps`;
            Cookies.set('networkInfo', networkInfo);
        }
    };

    const handleCheckboxChange = (cookieType) => {
        setCookiesAccept((prevCookiesAccept) => ({
            ...prevCookiesAccept,
            [cookieType]: !prevCookiesAccept[cookieType]
        }));
    };

    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50">
            <div className='flex items-start xsm:items-center justify-center max-w-[800px] w-full mx-auto animated-slideUp mt-7 xsm:mt-0'>
                <div className="relative w-full bg-enthusia-primary rounded-[30px] p-5 xl:p-8 m-3 md:mx-0">
                    <div className="flex items-center justify-start">
                        <img src={isHDImages ? (imageUrl + data.src) : Cookie_Icon} alt="cookie_icon" className='w-8 md:w-12 h-8 md:h-12' />
                        <h4 className='text-white text-xl md:text-2xl font-semibold pl-[22px]'>{CookiesData.CustomCookie.heading}</h4>
                    </div>
                    <p className='font-normal text-white py-3 2xl:py-5 text-justify 2xl:pt-[22px] 2xl:pb-[35px]'>
                        {data.Description}
                        <span onClick={() => setCookiePolicyPopUp(true)} className='inline-flex items-center font-medium text-white cursor-pointer'><span className='underline'>Read More (</span><span className='underline inline-block'>Cookie Policy</span>)
                        </span>
                    </p>
                    <h4 className='text-white text-xl md:text-2xl font-semibold'>{data.subHading}</h4>
                    <div className="border-[2px] border-[#3A6166] my-5 2xl:my-[32px]">
                        <div className="group border-b border-[#3A6166] py-3">
                            <div onClick={() => toggleAccordion(1)} className="w-full flex items-center cursor-pointer">
                                <div className="w-8 md:w-10">
                                    <img src={openIndex === 1 ? MinusIcon : PlusIcon} alt={openIndex === 1 ? "Minus Icon" : "Plus Icon"} className='w-4 h-4 object-contain mx-auto' />
                                </div>
                                <div className="w-full flex items-center justify-between pr-5 md:pr-10">
                                    <p className='font-bold text-white'>Essential Cookies</p>
                                    <p className='font-bold text-white whitespace-nowrap pl-2'>Always Active</p>
                                </div>
                            </div>
                            {openIndex === 1 && <p className='text-xs md:leading-5 md:tracking-wide text-justify font-normal text-white px-8 pr-5 md:px-10 anim'>These essential cookies are integral for site functionality and are automatically activated in response to your actions, like privacy settings, logins, or form submissions. While you can block them in your browser, it may impact certain site features, yet they do not store personal information.</p>}
                        </div>
                        <div className="group border-b border-[#3A6166] py-3">
                            <div onClick={() => toggleAccordion(2)} className="w-full flex items-center cursor-pointer">
                                <div className="w-8 md:w-10">
                                    <img src={openIndex === 2 ? MinusIcon : PlusIcon} alt={openIndex === 2 ? "Minus Icon" : "Plus Icon"} className='w-4 h-4 object-contain mx-auto' />
                                </div>
                                <div className="w-full flex items-center justify-between pr-5 md:pr-10">
                                    <p className='font-bold text-white'>Performance Cookies</p>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" checked={cookiesAccept.PerformanceCookies} onChange={() => handleCheckboxChange("PerformanceCookies")} />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#737373] peer-checked:after:bg-enthusia-primary  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                                    </label>
                                </div>
                            </div>
                            {openIndex === 2 && <p className='text-xs md:leading-5 md:tracking-wide text-justify font-normal text-white px-8 pr-5 md:px-10 anim'>These cookies enable us to analyze website performance, track page popularity, and enhance the user experience. While data may be associated with identifiable individuals, it's used to provide more relevant content and insights. Disabling these cookies means we can't monitor site performance or tailor content to your interests.</p>}
                        </div>
                        <div className="group border-b border-[#3A6166] py-3">
                            <div onClick={() => toggleAccordion(3)} className="w-full flex items-center cursor-pointer">
                                <div className="w-8 md:w-10">
                                    <img src={openIndex === 3 ? MinusIcon : PlusIcon} alt={openIndex === 3 ? "Minus Icon" : "Plus Icon"} className='w-4 h-4 object-contain mx-auto' />
                                </div>
                                <div className="w-full flex items-center justify-between pr-5 md:pr-10">
                                    <p className='font-bold text-white'>Functionality Cookies</p>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" checked={cookiesAccept.FunctionalityCookies} onChange={() => handleCheckboxChange("FunctionalityCookies")} className="sr-only peer" />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#737373] peer-checked:after:bg-enthusia-primary  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                                    </label>
                                </div>
                            </div>
                            {openIndex === 3 && <p className='text-xs md:leading-5 md:tracking-wide text-justify font-normal text-white px-8 pr-5 md:px-10 anim'>These cookies enhance website functionality and personalization. They can be set by us or third-party providers whose services we've incorporated. Disabling them may affect the proper functioning of these services.</p>}
                        </div>
                        <div className="group border-b border-[#3A6166] py-3">
                            <div onClick={() => toggleAccordion(4)} className="w-full flex items-center cursor-pointer">
                                <div className="w-8 md:w-10">
                                    <img src={openIndex === 4 ? MinusIcon : PlusIcon} alt={openIndex === 4 ? "Minus Icon" : "Plus Icon"} className='w-4 h-4 object-contain mx-auto' />
                                </div>
                                <div className="w-full flex items-center justify-between pr-5 md:pr-10">
                                    <p className='font-bold text-white'>Third-Party Cookies</p>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" checked={cookiesAccept.ThirdPartyCookies} onChange={() => handleCheckboxChange("ThirdPartyCookies")} className="sr-only peer" />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#737373] peer-checked:after:bg-enthusia-primary  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                                    </label>
                                </div>
                            </div>
                            {openIndex === 4 && <p className='text-xs md:leading-5 md:tracking-wide text-justify font-normal text-white px-8 pr-5 md:px-10 anim'>These cookies, set by our advertising partners, create interest profiles for tailored ads on other sites. They don't store personal data but identify your browser and device. Disabling them reduces targeted advertising. </p>}
                        </div>
                        <div className="group border-b border-[#3A6166] py-3">
                            <div onClick={() => toggleAccordion(5)} className="w-full flex items-center cursor-pointer">
                                <div className="w-8 md:w-10">
                                    <img src={openIndex === 5 ? MinusIcon : PlusIcon} alt={openIndex === 5 ? "Minus Icon" : "Plus Icon"} className='w-4 h-4 object-contain mx-auto' />
                                </div>
                                <div className="w-full flex items-center justify-between pr-5 md:pr-10">
                                    <p className='font-bold text-white'>Other Cookies</p>
                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input type="checkbox" value="" checked={cookiesAccept.OtherCookies} onChange={() => handleCheckboxChange("OtherCookies")} className="sr-only peer" />
                                        <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-[#737373] peer-checked:after:bg-enthusia-primary  after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                                    </label>
                                </div>
                            </div>
                            {openIndex === 5 && <p className='text-xs md:leading-5 md:tracking-wide text-justify font-normal text-white px-8 pr-5 md:px-10 anim'>Other uncategorized cookies are those that are being analyzed and have not been classified into a category as yet.</p>}
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap sm:flex-nowrap  items-center justify-start space-x-3 sm:space-x-[18px]">
                        <button onClick={() => { handleConsent(); handleCustomClose(false); handleClose(false) }} className='text-enthusia-primary bg-white text-sm 2xl:text-base md:text-xl border border-white font-medium rounded-full px-3 sm:px-[30px] py-1.5 animation'>{data.buttonCustomCookies}</button>
                        <button onClick={() => { handleConsent(); handleCustomClose(false); handleClose(false) }} className='text-white bg-enthusia-primary text-sm 2xl:text-base md:text-xl hover:bg-white hover:text-enthusia-primary border border-white font-medium rounded-full px-3 sm:px-[30px] py-1.5 animation'>{data.buttonAllCookies}</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={cookiePolicyPopUp} >
                <CookiePolicy data={CookiesData} handleClose={setCookiePolicyPopUp} />
            </Modal>
        </div>
    )
}

export default CustomCookie