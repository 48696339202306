import { BrowserRouter } from 'react-router-dom';
import AllRouters from './Components/Routes/AllRouters';
import TagManager from 'react-gtm-module';
import useDisableInspect from './Components/hook/useDisableInspect';

const tagManagerArgs = {
  gtmId: 'GTM-M7TVL8NL',
};

TagManager.initialize(tagManagerArgs);


function App() {
  useDisableInspect();;
  return (
    <BrowserRouter>
      <AllRouters />
    </BrowserRouter>
  );
}

export default App;
