import { useNavigate } from "react-router-dom";

const IndustriesBox = ({ industries_class, src, alt, industries_title }) => {
    const navigator = useNavigate();
    return (
        <div onClick={() => navigator("/services/industries")} className={`relative group ${industries_class}`}>
            <div className="border border-[#565656] group-hover:border-enthusia-primary h-full rounded-[24px] p-4 overflow-hidden anim">
                <div className="relative w-full h-[170px] rounded-2xl overflow-hidden">
                    <img src={src} alt={alt} className="w-full h-full object-cover overflow-hidden group-hover:scale-105 grayscale group-hover:grayscale-0 anim" loading="lazy" />
                    <span className="w-full h-full absolute inset-0 bg-enthusia-primary mix-blend-soft-light group-hover:opacity-0 overflow-hidden anim"></span>
                </div>
                <span className={`block w-full h-[2px] bg-gradient-to-r group-hover:from-[rgba(255,255,255,0.1)] group-hover:via-[#134046] group-hover:to-[rgba(255,255,255,0.1)] from-[rgba(86,86,86,0)] via-[rgba(86,86,86,1)] to-[rgba(86,86,86,0)] z-10 my-4`}></span>
                <p className="text-center font-semibold text-[#737373] capitalize group-hover:text-enthusia-primary">{industries_title}</p>
            </div>
        </div>
    )
}

export default IndustriesBox;