import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { imageUrl } from "../../Api/baseUrl";
import data from '../../static/Services.json'
import DOMPurify from 'dompurify';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import SEO from "../Common/SEO";
import useWindowWidth from '../hook/useWindowWidth'
import HeadingTitle from "../../Utility/HeadingTitle";
import CustomServicesBox from "../../Utility/CustomServicesBox";
import Button from '../../Utility/Button'
import IndustriesBox from "../../Utility/IndustriesBox";

import WebFrontendTechnologies from "../Others/WebFrontendTechnologies";
import WebBackendTechnologies from "../Others/WebBackendTechnologies";
import WebPlatformsTechnologies from "../Others/WebPlatformsTechnologies";
import DatabaseTechnologies from "../Others/DatabaseTechnologies";
import AppFrontendTechnologies from "../Others/AppFrontendTechnologies";
import AppBackendTechnologies from "../Others/AppBackendTechnologies";
import AppPlatformsTechnologies from "../Others/AppPlatformsTechnologies";
import UiUxDesignTools from "../Others/UiUxDesignTools";
import ConsultationFrom from '../../Components/Others/ConsultationFrom'
import Modal from "../Common/Modal";
import ContactFrom from "../Popup/ContactFrom";

import Service_Development from "../../Assets/svg/services_development_icon.svg";
import SaasDevelopment from '../../Assets/svg/saas_development.svg'
import WebDevelopment from '../../Assets/svg/web_development.svg'
import AppDevelopment from '../../Assets/svg/app_development.svg'
import SoftwareDevelopment from '../../Assets/svg/software_development.svg'
import DataAnalyticsAl from '../../Assets/svg/data_analytics_al.svg'
import AppModernization from '../../Assets/svg/app_modernization.svg'
import DigitalTransformation from '../../Assets/svg/digital_transformation.svg'
import QaTesting from '../../Assets/svg/qa_testing.svg'
import BoostingStartups from '../../Assets/Webp/boosting_startups.webp'
import HireResources from '../../Assets/Webp/hire_resources.webp'
import Development from "../../Utility/Development";

import Education from '../../Assets/png/education.png'
import RealEstate from '../../Assets/png/real-estate.png'
import Manufacturing from '../../Assets/png/manufacturing.png'
import Insurance from '../../Assets/png/insurance.png'
import LifeSciencesWellness from '../../Assets/png/life-sciences-wellness.png'
import MediaInfoServices from '../../Assets/png/media-info-services.png'
import LogisticsDistribution from '../../Assets/png/logistics-distribution.png'
import Financial from '../../Assets/png/financial.png'
import TravelHospitality from '../../Assets/png/travel-hospitality.png'
import MediaAndEntertainment from '../../Assets/png/media-and-entertainment.png'
import WasteManagement from '../../Assets/png/waste-management.png'
import Healthcare from '../../Assets/png/healthcare_services.png'
import RetailAndECommerce from '../../Assets/png/retail_and_e_commerce.png'
import EnergyAndUtilities from '../../Assets/png/energy_and_utilities_services.png'

function Services() {
  const isHDImages = false;
  const navigator = useNavigate();
  const [consultFrom, setConsultFrom] = useState(false);
  const [technologiesTab, setTechnologiesTab] = useState(1);
  const [technologiesSideTab, setTechnologiesSideTab] = useState(1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const windowWidth = useWindowWidth();

  // Function to handle Swiper slide change and update the current slide index
  const handleSlideChange = (swiper) => {
    setCurrentSlideIndex(swiper.activeIndex);
  };

  const CustomStaticImages = [SaasDevelopment, WebDevelopment, AppDevelopment, SoftwareDevelopment, DataAnalyticsAl, AppModernization, DigitalTransformation, QaTesting, BoostingStartups, HireResources]

  useEffect(() => {
    setTechnologiesSideTab(1);
  }, [technologiesTab]);

  useEffect(() => {
    if (currentSlideIndex === 0) {
      setTechnologiesTab(1)
    }
    if (currentSlideIndex === 1) {
      setTechnologiesTab(2)
    }
    if (currentSlideIndex === 2) {
      setTechnologiesTab(3)
    }
  }, [currentSlideIndex])

  return (
    <React.Fragment>
      <SEO
        title={data.SEO.title}
        description={data.SEO.Description}
        canonicalLink={data.SEO.CanonicalLink}
        keywords={data.SEO.keywords}
      />
      <main className="wrapper">
        {/* Software Development */}
        <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
          <h4 className="xl:max-w-[819px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
          </h4>
          <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
            <img
              src={isHDImages ? (imageUrl + data.headingBox.src) : Service_Development}
              alt="Service Development"
              className="w-full h-full"
              loading='lazy'
            />

          </div>
        </div>
        {/* CUSTOM SERVICES */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <HeadingTitle
            onClick={() => navigator("/services/custom-services")}
            title_name={data.CustomServices.heading}
            heading_class="cursor-pointer order-1"
            line_class="bg-gradient-to-r order-2 sm:ml-[30px]"
          />
        </div>
        <div className="flex flex-wrap justify-center pt-4 sm:pt-[35px] md:-mx-[15px]">
          {(data && data.CustomServices && data.CustomServices.CustomServices).map((data, index) => (
            <CustomServicesBox
              key={data.id}
              onClick={() => navigator(`${data.link}`)}
              custom_class={`border-[2px] border-transparent rounded-2xl md:rounded-[24px] p-5 md:p-10 grayscale group-hover:grayscale-0 opacity-80 group-hover:opacity-100 anim`}
              custom_box_class="w-full md:w-1/2 pb-4 md:p-[15px] anim"
              src={isHDImages ? (imageUrl + data.src) : CustomStaticImages.map((data) => (data))[index]}
              alt={data.title}
              custom_services_title={data.title}
              custom_services_paragraph={data.description}
            />
          ))}
        </div>
        {/*  BOOSTING STARTUPS */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <HeadingTitle
            onClick={() => navigator("/services/boosting-startups")}
            title_name={data.BoostingStartups.heading}
            heading_class="cursor-pointer order-2"
            line_class="bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5"
          />
          <div className="group flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-2xl md:rounded-[30px] lg:rounded-[50px] p-5 sm:p-8 md:p-10 lg:p-[60px] mt-5 md:mt-[60px]">
            <div className="max-w-[826px] w-full order-2 lg:order-1">
              <h3 className="xl:max-w-[622px] w-full text-center leading-[28px] md:leading-[34px] 2xl:leading-[42px] lg:text-left font-semibold text-enthusia-primary pt-5 lg:pt-0">
                {data.BoostingStartups.description}
              </h3>
              <div className="flex items-center justify-center lg:justify-start mt-4">
                <Button
                  onClick={() => setConsultFrom(true)}
                  button_name={data.BoostingStartups.buttonName}
                  img_class="hidden"
                />
              </div>
            </div>
            <div className="md:w-[355px] h-[150px] lg:h-[250px] order-1 lg:order-2 mx-auto">
              <img
                src={isHDImages ? (imageUrl + data.BoostingStartups.src) : BoostingStartups}
                alt={data.BoostingStartups.heading}
                className="w-full h-full object-contain"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        {/* TECHNOLOGIES */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <HeadingTitle
            onClick={() => navigator("/services/technologies")}
            title_name="TECHNOLOGIES"
            heading_class="cursor-pointer order-1"
            line_class="bg-gradient-to-r order-2 sm:ml-5"
          />
          <div className="relative customs-services border-[1px] border-transparent rounded-2xl md:rounder-[30px] lg:rounded-[50px] mt-8 md:mt-[50px]">
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              /*  pagination={{
                 clickable: true,
               }} */
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper-services"
              onSlideChange={handleSlideChange}
            >
              <div className="relative flex justify-between items-center border-b border-[rgba(19,70,64,0.2)] px-5 md:px-[30px] overflow-x-hidden">
                {windowWidth < 768 ? (
                  <React.Fragment>
                    <SwiperSlide>
                      <h3
                        className={`technology-tab inline-block ml-4 anim ${technologiesTab === 1 ? "active" : ""
                          }`}
                        data-tab="WebDevelopment"
                        onClick={() => setTechnologiesTab(1)}
                      >
                        Web Development
                      </h3>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h3
                        className={`technology-tab inline-block ml-4 anim ${technologiesTab === 2 ? "active" : ""
                          }`}
                        data-tab="AppDevelopment"
                        onClick={() => setTechnologiesTab(2)}
                      >
                        mobileApp development
                      </h3>
                    </SwiperSlide>
                    <SwiperSlide>
                      <h3
                        className={`technology-tab inline-block ml-4 anim ${technologiesTab === 3 ? "active" : ""
                          }`}
                        data-tab="UiUxDesign"
                        onClick={() => setTechnologiesTab(3)}
                      >
                        Ui/Ux design
                      </h3>
                    </SwiperSlide>
                  </React.Fragment>
                )
                  : (
                    <React.Fragment>
                      <h3
                        className={`technology-tab anim ${technologiesTab === 1 ? "active" : ""
                          }`}
                        data-tab="WebDevelopment"
                        onClick={() => setTechnologiesTab(1)}
                      >
                        Web Development
                      </h3>
                      <h3
                        className={`technology-tab anim ${technologiesTab === 2 ? "active" : ""
                          }`}
                        data-tab="AppDevelopment"
                        onClick={() => setTechnologiesTab(2)}
                      >
                        mobile app development
                      </h3>
                      <h3
                        className={`technology-tab anim ${technologiesTab === 3 ? "active" : ""
                          }`}
                        data-tab="UiUxDesign"
                        onClick={() => setTechnologiesTab(3)}
                      >
                        Ui/Ux design
                      </h3>
                    </React.Fragment>
                  )}
              </div>
              <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                {/* WebDevelopment TechnologiesTab Left side*/}
                {technologiesTab === 1 && (
                  <div className="w-full md:w-auto flex md:flex-col justify-between border-y md:border-y-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 1 ? "active" : ""
                        }`}
                      data-tab="Frontend"
                      onClick={() => setTechnologiesSideTab(1)}
                    >
                      Frontend
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 2 ? "active" : ""
                        }`}
                      data-tab="Backend"
                      onClick={() => setTechnologiesSideTab(2)}
                    >
                      Backend
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 3 ? "active" : ""
                        }`}
                      data-tab="Platforms"
                      onClick={() => setTechnologiesSideTab(3)}
                    >
                      Platforms
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 4 ? "active" : ""
                        }`}
                      data-tab="Database"
                      onClick={() => setTechnologiesSideTab(4)}
                    >
                      Database
                    </h3>
                  </div>
                )}
                {/* AppDevelopment TechnologiesTab Left side*/}
                {technologiesTab === 2 && (
                  <div className="w-full md:w-auto flex md:flex-col justify-between border-y md:border-y-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 1 ? "active" : ""
                        }`}
                      data-tab="Frontend"
                      onClick={() => setTechnologiesSideTab(1)}
                    >
                      Frontend
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 2 ? "active" : ""
                        }`}
                      data-tab="Backend"
                      onClick={() => setTechnologiesSideTab(2)}
                    >
                      Backend
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 3 ? "active" : ""
                        }`}
                      data-tab="OperatingSystem"
                      onClick={() => setTechnologiesSideTab(3)}
                    >
                      Platforms
                    </h3>
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 4 ? "active" : ""
                        }`}
                      data-tab="Database"
                      onClick={() => setTechnologiesSideTab(4)}
                    >
                      Database
                    </h3>
                  </div>
                )}
                {/* UI/UX Design TechnologiesTab Left side*/}
                {technologiesTab === 3 && (
                  <div className="w-full md:w-auto min-w-[216px] lg:min-w-[286px] xl:min-w-[353px] 2xl:min-w-[432px] flex md:flex-col justify-between border-y md:border-y-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                    <h3
                      className={`technology-sideTab ${technologiesSideTab === 1 ? "active" : ""
                        }`}
                      data-tab="UI/UXDesignTools"
                      onClick={() => setTechnologiesSideTab(1)}
                    >
                      UI/UX
                    </h3>
                  </div>
                )}
                {/* WebDevelopment TechnologiesTab Right side*/}
                {technologiesTab === 1 && (
                  <div className="w-full p-4 md:p-5 lg:p-[30px]">
                    {technologiesSideTab === 1 && <WebFrontendTechnologies />}
                    {technologiesSideTab === 2 && <WebBackendTechnologies />}
                    {technologiesSideTab === 3 && <WebPlatformsTechnologies />}
                    {technologiesSideTab === 4 && <DatabaseTechnologies />}
                  </div>
                )}
                {/* AppDevelopment TechnologiesTab Right side*/}
                {technologiesTab === 2 && (
                  <div className="w-full p-4 md:p-5 lg:p-[30px]">
                    {technologiesSideTab === 1 && <AppFrontendTechnologies />}
                    {technologiesSideTab === 2 && <AppBackendTechnologies />}
                    {technologiesSideTab === 3 && <AppPlatformsTechnologies />}
                    {technologiesSideTab === 4 && <DatabaseTechnologies />}
                  </div>
                )}
                {/* UI/UX Design TechnologiesTab Right side*/}
                {technologiesTab === 3 && (
                  <div className="w-full p-4 md:p-5 lg:p-[30px]">
                    {technologiesSideTab === 1 && <UiUxDesignTools />}
                  </div>
                )}
              </div>
            </Swiper>
          </div>
        </div>
        {/* INDUSTRIES */}
        <div className="pt-[50px] lg:pt-[100px]">
          <HeadingTitle
            onClick={() => navigator("/services/industries")}
            title_name="INDUSTRIES"
            heading_class="cursor-pointer order-2"
            line_class="bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5"
          />
          <div className="flex flex-wrap justify-center md:-mx-[15px] pt-5 sm:pt-[35px]">
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={Education}
              alt="Education"
              industries_title="Education"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={RealEstate}
              alt="Real Estate"
              industries_title="real estate"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={Manufacturing}
              alt="Manufacturing"
              industries_title="manufacturing"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={Insurance}
              alt="Insurance"
              industries_title="insurance"
            />
            <div className="hidden xl:flex xl:flex-wrap xl:justify-center">
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={LifeSciencesWellness}
                alt="Life sciences & wellness"
                industries_title="Life sciences & wellness"
              />
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={MediaInfoServices}
                alt="Media & Info Services"
                industries_title="media & info services"
              />
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={LogisticsDistribution}
                alt="Logistics & Distribution"
                industries_title="Logistics & Distribution"
              />
            </div>
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={LifeSciencesWellness}
              alt="Life sciences & wellness"
              industries_title="Life sciences & wellness"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={MediaInfoServices}
              alt="Media & Info Services"
              industries_title="media & info services"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={LogisticsDistribution}
              alt="Logistics & Distribution"
              industries_title="Logistics & Distribution"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={Financial}
              alt="Financial"
              industries_title="Financial"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={TravelHospitality}
              alt="Travel & Hospitality"
              industries_title="travel & hospitality"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={MediaAndEntertainment}
              alt="Media and Entertainment"
              industries_title="Media and Entertainment"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 md:p-[15px]"
              src={WasteManagement}
              alt="Waste Management"
              industries_title="Waste Management"
            />
            <div className="hidden xl:flex xl:flex-wrap xl:justify-center">
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={Healthcare}
                alt="Healthcare"
                industries_title="Healthcare"
              />
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={RetailAndECommerce}
                alt="Retail and E-commerce"
                industries_title="Retail and E-commerce"
              />
              <IndustriesBox
                industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px]"
                src={EnergyAndUtilities}
                alt="Energy and Utilities"
                industries_title="Energy and Utilities"
              />
            </div>
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={Healthcare}
              alt="Healthcare"
              industries_title="Healthcare"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={RetailAndECommerce}
              alt="Retail and E-commerce"
              industries_title="Retail and E-commerce"
            />
            <IndustriesBox
              industries_class="w-full md:w-1/2 lg:w-1/3 pb-4 md:p-[15px] block xl:hidden"
              src={EnergyAndUtilities}
              alt="Energy and Utilities"
              industries_title="Energy and Utilities"
            />
          </div>
        </div>
        {/* HIRE RESOURCES */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <HeadingTitle
            onClick={() => navigator("/services/hire-resources")}
            title_name={data.HireResources.heading}
            heading_class="cursor-pointer order-1"
            line_class="bg-gradient-to-r order-2 sm:ml-5"
          />
          <Development
            development_class="mt-7 lg:mt-[50px]"
            src={isHDImages ? (imageUrl + data.HireResources.src) : HireResources}
            alt={data.HireResources.heading}
            title_class="hidden"
            development_paragraph="When it comes to bringing your software projects to life, having the right talent is paramount. At Enthusia Softech, we offer a unique opportunity to hire top-tier software development resources that are ready to join your team and contribute to your project's success. Whether you need developers, designers, QA engineers, or project managers, we provide the expertise and flexibility you require to execute your vision."
            paragraph_2_class="hidden"
            paragraph_1_class="pb-5"
            button_name={data.HireResources.buttonName}
          />
        </div>
        {/* GET A CONSULTATION */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px] space-y-5 md:space-y-[50px]">
          <HeadingTitle
            onClick={() => navigator("/services/consultation")}
            title_name={data.Contact.heading}
            heading_class="cursor-pointer order-2"
            line_class="bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5"
          />
          <ConsultationFrom
            main_title={data.Contact.MainTitle}
            paragraph_class_2="hidden"
            paragraph_1={data.Contact.paragraph}
          />
        </div>
        {/* consultFrom Popup */}
        <Modal isOpen={consultFrom}>
          <ContactFrom handleClose={setConsultFrom} />
        </Modal>
      </main>
    </React.Fragment>
  );
}

export default Services;