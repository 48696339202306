import React from 'react'
import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import owsp from '../../Assets/Webp/owasp_top_ten.webp'
import SslTls from '../../Assets/Webp/tls.webp'
import Waf from '../../Assets/Webp/wap.webp'
import AesEncryption from '../../Assets/Webp/aes_encryption.webp'
import WafEncryption from '../../Assets/Webp/tls_encryption.webp'

function SecurityTechnologies() {
    return (
        <div className='w-full'>
            <div className="">
                <p className='leading-9 font-medium text-[#737373]'>Web Application Security :</p>
                <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={owsp} alt="OWASP Top Ten" technologies_title="OWASP Top Ten" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={SslTls} alt="SSL/TLS" technologies_title="SSL/TLS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Waf} alt="Web Application Firewalls (WAF)" technologies_title="Web Application Firewalls (WAF)" />
                </div>
            </div>
            <div className="pt-[45px]">
                <p className='leading-9 font-medium text-[#737373]'>Data Encryption :</p>
                <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={AesEncryption} alt="AES Encryption" technologies_title="AES Encryption" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={WafEncryption} alt="SSL/TLS Encryption" technologies_title="SSL/TLS Encryption" />
                </div>
            </div>
        </div>
    )
}

export default SecurityTechnologies