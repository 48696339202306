import Cookies from 'js-cookie';
import { useEffect } from 'react'

function useDisableInspect() {
    useEffect(() => {
        const cookiesSet = Cookies.get("cookieConsent");

        const disableContextMenu = (e) => {
            e.preventDefault();
        };

        const disableF12Key = (e) => {
            if (e.keyCode === 123) {
                e.preventDefault();
            }
        };

        const disableCtrlShiftC = (e) => {
            if (e.ctrlKey && e.shiftKey && e.key === 'C') {
                e.preventDefault();
            }
        };

        const disableCtrlShiftI = (e) => {
            if (e.ctrlKey && e.shiftKey && e.key === 'I') {
                e.preventDefault();
            }
        };

        const disableCmdShiptI = (e) => {
            if ((e.metaKey || e.ctrlKey) && e.altKey && e.key === 'I') {
                e.preventDefault();
            }
        };


        /* Tab Key Disable */
        const handleKeyDown = (event) => {
            if (event.key === 'Tab') {
                event.preventDefault();
            }
        };

        if (!cookiesSet) {
            document.addEventListener('keydown', handleKeyDown);
        }
        document.addEventListener('contextmenu', disableContextMenu);
        document.addEventListener('keydown', disableF12Key);
        document.addEventListener('keydown', disableCtrlShiftC);
        document.addEventListener('keydown', disableCtrlShiftI);
        document.addEventListener('keydown', disableCmdShiptI);

        return () => {
            if (!cookiesSet) {
                document.addEventListener('keydown', handleKeyDown);
            }
            document.removeEventListener('contextmenu', disableContextMenu);
            document.removeEventListener('keydown', disableF12Key);
            document.removeEventListener('keydown', disableCtrlShiftC);
            document.removeEventListener('keydown', disableCtrlShiftI);
            document.addEventListener('keydown', disableCmdShiptI);
        };
    }, []);
}

export default useDisableInspect