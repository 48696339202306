import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Html from '../../Assets/svg/html_icon.svg'
import Css from '../../Assets/svg/css.svg'
import Python from '../../Assets/svg/python.svg'
import Javascript from '../../Assets/svg/Javascript.svg'
import Typescript from '../../Assets/svg/typescript.svg'
import Bootstrap from '../../Assets/svg/bootstrap.svg'
import Materialui from '../../Assets/svg/materialui.svg'
import Tailwind from '../../Assets/svg/tailwind.svg'
import Chakraui from '../../Assets/svg/chakraui.svg'
import Reactjs from '../../Assets/svg/reactjs.svg'
import Angularjs from '../../Assets/svg/angularjs.svg'
import Vuejs from '../../Assets/svg/vuejs.svg'
import Nextjs from '../../Assets/svg/nextjs.svg'
import Threejs from '../../Assets/svg/threejs.svg'
import AntDesign from '../../Assets/svg/ant_design.svg'
import Foundation from '../../Assets/svg/foundation.svg'
import SemanticUi from '../../Assets/svg/semantic_ui.svg'

function WebFrontendTechnologies() {
    return (
        <div className='w-full'>
            <div className="">
                <p className='leading-9 font-medium text-[#737373]'>Languages :</p>
                <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Html} alt="Html" technologies_title="HTML" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Css} alt="Css" technologies_title="CSS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Python} alt="Python" technologies_title="Python" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Javascript} alt="Javascript (ES6+)" technologies_title="Javascript (ES6+)" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Typescript} alt="Typescript" technologies_title="Typescript" />
                </div>
            </div>
            <div className="pt-5">
                <p className='leading-9 font-medium text-[#737373]'>Frameworks & Tools :</p>
                <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Reactjs} alt="Reactjs" technologies_title="ReactJS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Nextjs} alt="Nextjs" technologies_title="NextJS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Vuejs} alt="Vuejs" technologies_title="VueJS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Angularjs} alt="Angularjs" technologies_title="AngularJS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Threejs} alt="Threejs" technologies_title="ThreeJS" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Bootstrap} alt="Bootstrap" technologies_title="Bootstrap" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Materialui} alt="Materialui" technologies_title="Material Ui" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Tailwind} alt="Tailwind" technologies_title="Tailwind" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Chakraui} alt="Chakraui" technologies_title="Chakra Ui" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={AntDesign} alt="Ant Design" technologies_title="Ant Design" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Foundation} alt="Foundation" technologies_title="Foundation" />
                    <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={SemanticUi} alt="Semantic UI" technologies_title="Semantic UI" />
                </div>
            </div>
        </div>
    )
}

export default WebFrontendTechnologies