import Button from "./Button";

const Hiring = ({ src, onClick, hiringImg }) => {
    return (
        <div className="relative bg-opacity-20 text-center md:text-left hiring-gradient group rounded-2xl md:rounded-[30px] lg:rounded-[50px] p-5 md:px-[60px] md:py-14">
            <img src={src} alt="hiring_model" className={`absolute right-1/4 bottom-0 group-hover:scale-y-105 pointer-events-none anim ${hiringImg}`} loading="lazy" />
            <div className="relative flex flex-wrap md:flex-nowrap justify-center items-center lg:justify-between">
                <div className="">
                    <h3 className="text-enthusia-primary text-center leading-[28px] md:leading-[34px] 2xl:leading-10 lg:text-left font-semibold uppercase">WE’RE HIRING</h3>
                    <p className="max-w-[400px] 2xl:max-w-[616px] text-center lg:text-left w-full text-enthusia-text font-normal lg:pb-4">Learn more about career opportunities with Enthusia Softech Pvt. Ltd.</p>
                    <div className="inline-block lg:hidden w-full">
                        <img src={src} alt="hiring_model" className={`w-full h-[100px] my-5 lg:my-0 lg:h-full object-contain group-hover:scale-105 pointer-events-none anim`} loading="lazy" />
                    </div>
                    <div className="flex items-center justify-center lg:justify-start">
                        <Button onClick={onClick} button_class="font-medium px-[30px] py-2" button_name="explore career" img_class="hidden" />
                    </div>
                </div>
                <div className="text-[52px] leading-[66px] uppercase text-center pt-5 md:pt-0 lg:inline-block hidden">
                    <h3 className="block font-bold leading-[28px] md:leading-[34px] 2xl:leading-[50px] hiring-text-gradient">NAVIGATE</h3>
                    <h3 className="block font-medium leading-[28px] md:leading-[34px] 2xl:leading-[50px] hiring-text-gradient-span2">YOUR</h3>
                    <h3 className="block font-bold leading-[28px] md:leading-[34px] 2xl:leading-[50px] hiring-text-gradient">NEXT</h3>
                </div>
            </div>
        </div>
    )
}

export default Hiring;