import React, { useState } from 'react'
import customData from '../../static/CustomSubServices.json'
import DOMPurify from 'dompurify';
import SEO from '../Common/SEO';
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from '../../Utility/HeadingTitle';
import Button from '../../Utility/Button';
import OursProcessSection from '../../Utility/OursProcessSection';
import WeDevelopBox from '../../Utility/WeDevelopBox';
import LeftBorderHeading from '../../Utility/LeftBorderHeading';
import Development from '../../Utility/Development';
import ScopeServicesBox from '../../Utility/ScopeServicesBox'
import IndustriesWeServe from '../Others/IndustriesWeServe';
import ConsultationFrom from '../Others/ConsultationFrom';
import OurSaasProcess from '../Others/OurSaasProcess';
import ContactFrom from '../Popup/ContactFrom';
import Modal from '../Common/Modal';

import Saasdevelopmentbox from '../../Assets/svg/saasdevlopmentbox.svg'
import SaasDevelopmentShortList from '../../Assets/Webp/saas-development-border-box.webp'
import SaasConsulting from '../../Assets/svg/saas_consulting.svg'
import SaasDevelopmentOutSourcing from '../../Assets/svg/saas_development_outsourcing.svg'
import SaasModernizationAndSupport from '../../Assets/svg/saas_modernization_and_support.svg'
import Crm from '../../Assets/svg/crm.svg'
import Erp from '../../Assets/svg/erp.svg'
import Billing from '../../Assets/svg/billing.svg'
import DataAnalytics from '../../Assets/svg/data_analytics.svg'
import Hr from '../../Assets/svg/hr.svg'
import Ecommerce from '../../Assets/svg/ecommerce.svg'
import ProjectManagement from '../../Assets/svg/project_management.svg'
import Lms from '../../Assets/svg/lms.svg'
import Ehr from '../../Assets/svg/ehr.svg'
import CustomerServiceApps from '../../Assets/svg/customer_service_apps.svg'
import DocumentManagement from '../../Assets/svg/document_management.svg'
import FleetManagement from '../../Assets/svg/fleet_management.svg'
import CollaborationProductivitySolutions from '../../Assets/svg/collaboration_productivity_solutions.svg'
import Itsm from '../../Assets/svg/itsm.svg'

function SaasDevelopment() {
  const data = customData.SaaSDevelopment;
  const isHDImages = false;
  const [consultFrom, setConsultFrom] = useState(false);

  const ChooseYourServiceImageges = [SaasConsulting, SaasDevelopmentOutSourcing, SaasModernizationAndSupport]
  const SaaSApplicationsDevelop = [Crm, Erp, Billing, DataAnalytics, Hr, Ecommerce, ProjectManagement, Lms, Ehr, CustomerServiceApps, DocumentManagement, FleetManagement, CollaborationProductivitySolutions, Itsm]
  return (
    <React.Fragment>
      <SEO
        title={data.SEO.title}
        description={data.SEO.Description}
        canonicalLink={data.SEO.CanonicalLink}
        keywords={data.SEO.keywords}
      />
      <main className="wrapper">
        {/* SaaS Solutions */}
        <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
          <h3 className="xl:max-w-[819px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
          </h3>
          <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
            <img
              src={isHDImages ? (imageUrl + data.headingBox.src) : Saasdevelopmentbox}
              alt="Saas Development"
              className="w-full h-full"
              loading='lazy'
            />
          </div>
        </div>
        {/* Saas development */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <HeadingTitle
            title_name={data.SaasDevelopment.heading}
            heading_main="cursor-auto"
            heading_class="order-1"
            line_class="bg-gradient-to-r order-2 sm:ml-5"
          />
          <Development
            development_class="mt-7 lg:mt-[50px]"
            src={isHDImages ? (imageUrl + data.SaasDevelopment.src) : SaasDevelopmentShortList}
            alt="Saas Development"
            title_class="hidden"
            development_paragraph={data.SaasDevelopment.paragraph}
            development_paragraph_2={data.SaasDevelopment.paragraph_2}
            button_name={data.SaasDevelopment.buttonName}
          />
        </div>
        {/* Expert SaaS Development Services */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <LeftBorderHeading heading_title={data.ExpertSaaSDevelopment.heading} />
          <p className="text-enthusia-primary font-normal text-justify pl-[18px] pt-[30px]">{data.ExpertSaaSDevelopment.paragraph}</p>
        </div>
        {/* Our SaaS Process: */}
        <div className="pt-8 sm:pt-[50px] md:pt-[80px]">
          <div className="lg:sticky lg:top-0 bg-white md:py-5 z-10">
            <HeadingTitle
              title_name={data.SaasProcess.hading}
              heading_main="cursor-auto"
              heading_class="order-2"
              line_class="bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5"
            />
          </div>
          <OurSaasProcess />
        </div>
        {/*  Choose Your Service Option */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <LeftBorderHeading heading_title={data.ChooseYourService.hading} />
          <div className="hiring-gradient rounded-2xl md:rounded-[24px] p-5 md:p-[30px] lg:p-[50px] mt-[50px] -mx-[42.5]">
            <div className="flex flex-wrap justify-center xl:justify-start ">
              {(data.ChooseYourService.services).map((data, index) => (
                <div key={data.id} className="w-full md:w-1/2 xl:w-1/3 pb-5 xl:pb-0 md:px-[42.5px]">
                  <div className="flex items-center justify-start">
                    <div className="w-10 h-10 lg:w-[65px] lg:h-[65px]">
                      <img src={isHDImages ? (imageUrl + data.src) : ChooseYourServiceImageges.map((data) => (data))[index]} alt={data.title} className='w-full h-full object-contain' loading='lazy' />
                    </div>
                    <span className='text-lg lg:text-2xl font-medium text-enthusia-primary pl-4 lg:pl-[30px] capitalize'>{data.title}</span>
                  </div>
                  <p className='font-normal text-enthusia-primary pt-5 text-justify lg:pt-[30px]'>{data.Description}</p>
                </div>
              ))}
            </div>
            <p className='w-full font-normal text-enthusia-primary text-justify sm:pt-3 md:pt-[30px] md:px-[42.5px]'>{data.ChooseYourService.paragraph}</p>
            <div className="flex items-center justify-center pt-[30px]">
              <Button onClick={() => setConsultFrom(true)} button_name={data.ChooseYourService.buttonName} button_class="px-[30px] py-2" img_class="hidden" />
            </div>
          </div>
        </div>
        {/* Why Choose Us */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <LeftBorderHeading heading_title="Why Choose Us " />
          <div className="flex flex-wrap pt-[35px] md:-mx-[15px]">
            {(data.WhyChoose).map((data) => (
              <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
            ))}
          </div>
        </div>
        {/* Bringing Dreams to Digital Reality section */}
        <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
          <OursProcessSection />
        </div>
        {/* Types of SaaS Applications We Develop */}
        <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
          <LeftBorderHeading heading_title="Types of SaaS Applications We Develop" />
          <div className="flex flex-wrap pt-[35px] -mx-2.5 md:-mx-[15px]">
            {(data.TypesOfSaaSApplications).map((data, index) => (
              <WeDevelopBox key={data.id} develop_class="w-1/2 lg:w-1/3 2xl:w-1/4 p-2.5 md:p-[15px]" src={isHDImages ? (imageUrl + data.src) : SaaSApplicationsDevelop.map((data) => (data))[index]} alt={data.title} develop_title={data.title} />
            ))}
          </div>
        </div>
        {/* Industries We Serve */}
        <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
          <LeftBorderHeading heading_title="Industries We Serve" />
          <IndustriesWeServe industries_class="pt-8 sm:pt-[50px]" />
        </div>
        {/* GET A CONSULTATION */}
        <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
          <ConsultationFrom
            main_title={data.Contact.MainTitle}
            paragraph_1={data.Contact.paragraph}
            paragraph_2={data.Contact.paragraph_2}
          />
        </div>
        {/* consultFrom Popup */}
        <Modal isOpen={consultFrom}>
          <ContactFrom handleClose={setConsultFrom} />
        </Modal>
      </main>
    </React.Fragment>
  )
}

export default SaasDevelopment