import Home from "../Pages/Home";
import Services from "../Pages/Services";
import Process from "../Pages/Process"
import Contact from "../Pages/Contact";

import CustomServices from "../Pages/CustomServices";
import BoostingStartups from "../Pages/BoostingStartups";
import Industries from "../Pages/Industries";
import HireResources from "../Pages/HireResources";
import Consultation from "../Pages/Consultation";
import Technologies from "../Pages/Technologies";

import SaasDevelopment from "../Pages/Saasdevelopmen";
import WebDevelopment from "../Pages/WebDevelopment";
import MobileAppDevelopment from '../Pages/MobileAppDevelopment';
import DataAnalyticsAndAl from "../Pages/DataAnalyticsAndAl";
import SoftwareDevelopment from "../Pages/SoftwareDevelopment";
import AppModernization from "../Pages/AppModernization";
import DigitalTransformation from "../Pages/DigitalTransformation";
import QAAndSoftwareTesting from "../Pages/QAAndSoftwareTesting";
import TermsAndConditions from "../Pages/TermsAndConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import CopyRight from "../Pages/CopyRight";

export const Routers = [
  {
    path: "/*",
    Component: Home,
  },
  {
    path: "/services",
    Component: Services,
  },
  {
    path: "/process",
    Component: Process,
  },
  {
    path: "/about-us",
    Component: Contact,
  },
  {
    path: "/services/custom-services",
    Component: CustomServices,
  },
  {
    path: "/services/boosting-startups",
    Component: BoostingStartups,
  },
  {
    path: "/services/technologies",
    Component: Technologies,
  },
  {
    path: "/services/industries",
    Component: Industries,
  },
  {
    path: "/services/hire-resources",
    Component: HireResources,
  },
  {
    path: "/services/consultation",
    Component: Consultation,
  },
  {
    path: "/services/custom-services/saas-development",
    Component: SaasDevelopment,
  },
  {
    path: "/services/custom-services/web-development",
    Component: WebDevelopment,
  },
  {
    path: "/services/custom-services/mobile-app-development",
    Component: MobileAppDevelopment,
  },
  {
    path: "/services/custom-services/data-analytics",
    Component: DataAnalyticsAndAl,
  },
  {
    path: "/services/custom-services/software-development",
    Component: SoftwareDevelopment,
  },
  {
    path: "/services/custom-services/app-modernization",
    Component: AppModernization,
  },
  {
    path: "/services/custom-services/digital-transformation",
    Component: DigitalTransformation,
  },
  {
    path: "/services/custom-services/software-testing",
    Component: QAAndSoftwareTesting,
  },
  {
    path: "/terms-and-conditions",
    Component: TermsAndConditions
  },
  {
    path: "/privacy-policy",
    Component: PrivacyPolicy
  },
  {
    path: "/copyright",
    Component: CopyRight
  }
]