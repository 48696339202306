import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Figma from '../../Assets/svg/figma.svg'
import Photoshop from '../../Assets/svg/photoshop.svg'
import Adobexd from '../../Assets/svg/adobexd.svg'
import Sketch from '../../Assets/svg/sketch.svg'
import InVision from '../../Assets/svg/invision.svg'
import Zeplin from '../../Assets/svg/zeplin.svg'

function UiUxDesignTools() {
    return (
        <React.Fragment>
            <p className='leading-9 font-medium text-[#737373]'>Design Tools :</p>
            <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Figma} alt="Figma" technologies_title_class="break-all" technologies_title="Figma" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Photoshop} alt="Photoshop" technologies_title_class="break-all" technologies_title="Photoshop" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Adobexd} alt="Adobexd" technologies_title_class="break-all" technologies_title="Adobexd" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={InVision} alt="InVision" technologies_title_class="break-all" technologies_title="InVision" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Sketch} alt="Sketch" technologies_title_class="break-all" technologies_title="Sketch" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Zeplin} alt="Zeplin" technologies_title_class="break-all" technologies_title="Zeplin" />
            </div>
        </React.Fragment>
    )
}

export default UiUxDesignTools