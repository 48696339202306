import React from 'react';

import Close_Icon from '../../Assets/svg/close_icon.svg'
import DownloadIcon from '../../Assets/Webp/pdf_download_icon.webp'
import SamplePDF from '../../Assets/Pdf/sample.pdf';

function PdfPopUp({ handleClose }) {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = SamplePDF;
    link.download = 'sample.pdf';
    link.click();
  };

  return (
    <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50 overflow-y-auto py-10">
      <div className="flex items-center justify-center max-w-[721px] w-full mx-auto h-full animation-slideUp overflow-y-auto">
        <div className="relative w-full bg-enthusia-primary rounded-[20px] h-full p-8 overflow-y-auto">
          <img onClick={() => handleClose(false)} src={Close_Icon} alt="close_icon" className="absolute top-3 right-3 cursor-pointer" />
          <div className="flex w-full items-center justify-end space-x-[15px] pb-5">
            <button onClick={handleDownload} className='flex items-center justify-center w-10 h-10 bg-white rounded-full'>
              <img src={DownloadIcon} alt="DownloadIcon Icon" className='p-3' />
            </button>
          </div>
          <div className="pdfview-box h-full overflow-y-scroll">
            <object data={SamplePDF} type="application/pdf" width="100%" height="100%">
              <p>This browser does not support PDFs. Please download the PDF to view it: <a href={SamplePDF}>Download PDF</a></p>
            </object>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PdfPopUp;
