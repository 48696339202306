import React from 'react'
import Button from './Button'
import { useNavigate } from 'react-router-dom';

function OursProcessSection() {
  const navigator = useNavigate();
  return (
    <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px]">
      <div className="xl:max-w-[819px] w-full">
        <h3 className="leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize">
          "Bringing Dreams to Digital Reality"
        </h3>
        <p className='text-center lg:text-left lg:pl-2.5 py-3 lg:py-0'>Understand our working flow</p>
      </div>
      <div className="w-auto auto order-1 mx-auto lg:mx-0 lg:ml-auto whitespace-nowrap">
        <Button onClick={() => navigator("/process")} button_name="Our Process" img_class="hidden" button_class="px-[30px] py-2" />
      </div>
    </div>
  )
}

export default OursProcessSection