import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Mysql from '../../Assets/svg/mysql.svg'
import Postgresql from '../../Assets/svg/postgresql.svg'
import Redis from '../../Assets/svg/redis.svg'
import Mongodb from '../../Assets/svg/mongodb.svg'
import SqLite from '../../Assets/svg/sqlite.svg'

function DatabaseTechnologies() {
  return (
    <React.Fragment>
      <p className='leading-9 font-medium text-[#737373]'>Technologies & Tools :</p>
      <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Mysql} alt="Mysql" technologies_title="Mysql" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Postgresql} alt="Postgresql" technologies_title="Postgresql" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Redis} alt="Redis" technologies_title="Redis" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Mongodb} alt="Mongodb" technologies_title="Mongodb" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={SqLite} alt="SQLite" technologies_title="SQLite" />
      </div>
    </React.Fragment>
  )
}

export default DatabaseTechnologies