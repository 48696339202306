import React, { useEffect, useState } from "react";
import bottom_arrow from '../Assets/svg/bottom_arrow.svg'

const IndustrySectorsBox = ({ paragraph_title, src, paragraph_title_2, industry_sectors_class, alt, industry_sectors_title, industry_sectors_paragraph, industry_sectors_paragraph_2 }) => {
    const [isActive, setIsActive] = useState(true);
    const [isShow, setIsShow] = useState(false);

    const handleAccordion = () => {
        if (window.innerWidth < 768) {
            setIsActive(!isActive);
        } else {
            setIsShow(true)
        }
    };

    useEffect(() => {
        handleAccordion()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={`hover:drop-shadow-xl anim ${industry_sectors_class}`}>
            <div className="customs-services anim border md:border-[2px] border-transparent rounded-2xl md:rounded-[24px] p-5 h-full">
                <div onClick={handleAccordion} className="flex items-center justify-between">
                    <div className="flex items-center justify-start">
                        <div className="flex items-center justify-center w-[30px] h-[30px] md:w-[38px] md:h-[38px] object-contain">
                            <img src={src} alt={alt} className="w-aut3 h-auto group-hover:scale-110 pointer-events-none anim" loading="lazy" />
                        </div>
                        <h6 className='flex items-center text-lg md:text-xl font-medium text-enthusia-primary capitalize anim'><span className="w-1 h-[30px] md:h-[38px] bg-enthusia-primary inline-block rounded-tr-[4px] rounded-br-[4px] mx-4"></span><span>{industry_sectors_title}</span></h6>
                    </div>
                    <div className="md:hidden">
                        <img src={bottom_arrow} alt="bottom Arrow" className="w-full h-full group-hover:scale-110 anim" loading="lazy" />
                    </div>
                </div>
                <div className={`"relative md:pl-[75px] anim ${isActive ? "block" : isShow ? "block" : "hidden"}`}>
                    <p className='block font-normal text-enthusia-primary pt-2.5 anim'><b className="pr-1">{paragraph_title}</b> {industry_sectors_paragraph}</p>
                    <p className='block font-normal text-enthusia-primary pt-2.5 anim'><b className="pr-1">{paragraph_title_2}</b>{industry_sectors_paragraph_2}</p>
                </div>
            </div>
        </div>
    )
}

export default IndustrySectorsBox;