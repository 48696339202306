import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SEO from "../Common/SEO";
import Tilt from 'react-parallax-tilt';
import DOMPurify from "dompurify";
import data from '../../static/Aboutus.json'
import { imageUrl } from '../../Api/baseUrl'

import HeadingTitle from "../../Utility/HeadingTitle";
import ConsultationFrom from "../Others/ConsultationFrom";
import ScopeServicesBox from "../../Utility/ScopeServicesBox";
import LeftBorderHeading from "../../Utility/LeftBorderHeading";
import WeDevelopBox from "../../Utility/WeDevelopBox";
import Button from "../../Utility/Button";

import Contact_Us from "../../Assets/Webp/contact-us.webp";
import Contact_US_Box from "../../Assets/svg/contact_us.svg";
import Future_Outlook_Background from "../../Assets/Webp/future_outlook_background.webp";
import Future_Outlook_Front from "../../Assets/Webp/future_outlook_front.webp";
import Organizational_Aim_Background from "../../Assets/Webp/organizational_aim_background.webp";
import Organizational_Aim_Front from "../../Assets/Webp/organizational_aim_front.webp";
import Line from "../../Assets/Webp/line.webp"
import Future_Outlook_Icon from "../../Assets/Webp/future_outlook_icon.webp";
import Organizational_Icon from "../../Assets/Webp/organizational_icon.webp";
import ContactBlog from '../../Assets/Webp/contact-blog.webp';
import ContactServices from '../../Assets/Webp/contact-service.webp';
import ContactProcess from '../../Assets/Webp/contact-process.webp';
import EthicalCommitment from '../../Assets/svg/ethical_commitment.svg';
import MoralResponsibility from '../../Assets/svg/moral_responsibility.svg';
import TeamUnity from '../../Assets/svg/team_unity.svg';


function Contact() {
  const isHDImages = false;
  const location = useLocation();
  const navigator = useNavigate()
  const OurEthosImages = [EthicalCommitment, MoralResponsibility, TeamUnity]
  const GetToKnowUs = [ContactProcess, ContactServices, ContactBlog]

  /* Contact From Redirect */
  useEffect(() => {
    const scrollToContact =
      new URLSearchParams(location.search).get("Contact") === "true";
    const scrollToHiring =
      new URLSearchParams(location.search).get("hiring") === "true";
    if (scrollToContact || scrollToHiring) {
      const sectionElement = document.getElementById("contact");
      if (sectionElement) {
        const sectionPosition =
          sectionElement.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: sectionPosition, behavior: "smooth" });
      }
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <SEO
        title={data.SEO.title}
        description={data.SEO.Description}
        canonicalLink={data.SEO.CanonicalLink}
        keywords={data.SEO.keywords}
      />
      <main className="wrapper">
        <div className="w-full flex flex-col justify-center text-4xl">
          {/*  Conatct us Box */}
          <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
            <h3 className="xl:max-w-[926px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
            </h3>
            <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
              <img
                src={isHDImages ? (imageUrl + data.headingBox.src) : Contact_US_Box}
                alt="About Us"
                className="w-full h-full"
                loading="lazy"
              />
            </div>
          </div>
          <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
            <HeadingTitle
              title_name={data.WhoWeAre.heading}
              heading_main="!cursor-auto"
              line_class="bg-gradient-to-r order-2 sm:ml-[30px]"
            />
          </div>
          <div className="w-full h-auto rounded-[30px] mt-8 md:mt-[50px]">
            <div className="">
              <div className="relative md:float-left max-w-[450px] mxa-w-[550px] xl:max-w-[650px] w-full md:pr-[30px] rounded-2xl mx-auto">
                <img
                  src={isHDImages ? imageUrl + data.WhoWeAre.WhoWeAreSrc : Contact_Us}
                  alt="contact_us"
                  className="w-full object-contain rounded-2xl overflow-hidden z-10"
                  loading="lazy"
                />
                <div className=""></div>
              </div>
              {/*  Enthusia */}
              <div className="md:text-justify">
                <p className="font-normal text-enthusia-primary indent-6 text-justify mt-2.5">
                  {data.WhoWeAre.paragraph}
                </p>
                <p className="font-normal text-enthusia-primary indent-6 text-justify mt-5 xl:mt-14">
                  {data.WhoWeAre.paragraph_2}
                </p>
                <p className="font-normal text-enthusia-primary indent-6 text-justify mt-5 xl:mt-14">
                  {data.WhoWeAre.paragraph_3}
                </p>
              </div>
            </div>
            {/* Our Ethos: Guiding Principles */}
            <div className="md:text-justify pt-[30px] xl:pt-[50px]">
              <LeftBorderHeading heading_title={data.OurEthos.heading} />
              <p className="font-normal text-enthusia-primary indent-6 mt-5">
                {data.OurEthos.paragraph}
              </p>
              <div className="flex w-full flex-wrap lg:flex-nowrap justify-center space-y-5 md:space-y-0 pt-[30px]">
                {(data.OurEthos.Ethos).map((data, index) => (
                  <div key={data.id} className={`w-full md:w-1/3 text-center md:px-5 lg:px-[30px] group ${data.id === 2 ? "relative side-line" : ""}`}>
                    <div className="flex lg:flex-col justify-start items-center">
                      <div className="w-8 md:w-10 lg:w-[60px] h-8 md:h-10 lg:h-[60px] lg:mx-auto">
                        <img
                          src={isHDImages ? (imageUrl + data.src) : OurEthosImages.map((data) => (data))[index]}
                          alt={data.title}
                          className="w-full h-full object-cover group-hover:scale-105 anim"
                          loading="lazy"
                        />
                      </div>
                      <p className="font-semibold text-enthusia-text pt-2.5 pl-5 lg:pl-0">
                        {data.title}
                      </p>
                    </div>
                    <p className="text-sm text-enthusia-primary text-justify font-normal pt-2">
                      {data.desorption}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="pt-8 sm:pt-[50px]">
              <p className="font-normal text-enthusia-text">{data.OurEthos.paragraph_2}</p>
              {/* FUTURE OUTLOOK */}
              <div id="FutureOutlook" className='relative pt-8 sm:pt-[50px]'>
                <HeadingTitle title_name={data.HomeSection.FutureOutlook.heading} heading_main="!cursor-auto" heading_class="order-1 sm:order-2" line_class="text-right bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5" src={Line} />
                <div className="relative flex flex-wrap justify-between mt-6 md:mt-[50px]">
                  <div className="relative w-full lg:w-1/2 order-2 lg:order-1">
                    <div className="flex flex-col items-center relative gradient-border-rightbox lg:gradient-border-leftbox justify-center w-full h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] lg:rounded-br-[0px] md:rounded-br-[30px] lg:rounded-tl-[50px] lg:rounded-bl-[50px] p-5 md:p-[30px] lg:px-[50px] 2xl:px-[100px]">
                      <div className={`flex items-center justify-center w-10 md:w-[60px] lg:w-20 h-10 md:h-[60px] lg:h-20 rounded-full lg:p-4 gradient-border-2 absolute -top-6 md:-top-8 left-1/2 -translate-x-1/2 lg:left-auto lg:-translate-x-0 lg:top-10 lg:-right-10 z-10`}>
                        <img src={isHDImages ? (imageUrl + data.HomeSection.FutureOutlook.RoundIconSrc) : Future_Outlook_Icon} alt="Future Outlook icon" className="w-5 md:w-8 lg:w-full h-5 md:h-8 lg:h-full object-contain" loading='lazy' />
                      </div>
                      <p className='text-enthusia-text capitalize leading-7 text-justify indent-16'>{data.HomeSection.FutureOutlook.paragraph}</p>
                    </div>
                  </div>
                  <div className="relative w-full lg:w-1/2 order-1 lg:order-2 h-auto overflow-hidden">
                    <div className="flex flex-col items-start justify-center h-full rounded-tl-2xl rounded-tr-2xl md:rounded-bl-[30px] md:rounded-tr-[30px] lg:rounded-tl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:rounded-bl-[0px] overflow-hidden">
                      <div className="relative w-full h-full anim">
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 anim">
                          <Tilt
                            glareEnable={false}
                            glareMaxOpacity={0.5}
                            glareColor="white"
                            perspective={1000}
                            scale={1.02}
                            transitionSpeed={2500}
                          >
                            <img
                              src={isHDImages ? (imageUrl + data.HomeSection.FutureOutlook.FrontSrc) : Future_Outlook_Front}
                              alt="Future Outlook"
                              className="w-full h-full hover:drop-shadow-2xl anim"
                              loading="lazy"
                            />
                          </Tilt>
                        </div>
                        <img src={isHDImages ? (imageUrl + data.HomeSection.FutureOutlook.BackSrc) : Future_Outlook_Background} alt="Future Outlook" className="w-full h-[350px] sm:h-[400px] lg:h-full lg:object-cover" loading='lazy' />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              {/* ORGANIZATIONAL AIM Box */}
              <div id="OrganizationalAim" className='relative pt-8 sm:pt-[50px]'>
                <HeadingTitle title_name={data.HomeSection.OrganizationalAim.heading} heading_main="!cursor-auto" heading_class="order-1" line_class="bg-gradient-to-r order-2 sm:ml-5" src={Line} />
                <div className="relative flex flex-wrap justify-between mt-6 md:mt-[50px]">
                  <div className="w-full lg:w-1/2 h-auto">
                    <div className="flex flex-col items-center justify-center w-full h-full rounded-tl-2xl rounded-tr-2xl md:rounded-tl-[30px] md:rounded-tr-[30px] lg:rounded-tr-none lg:rounded-l-[50px] overflow-hidden">
                      <div className="relative w-full h-full anim">
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 anim">
                          <Tilt
                            glareEnable={false}
                            glareMaxOpacity={0.5}
                            glareColor="white"
                            perspective={1000}
                            scale={1.02}
                            transitionSpeed={2500}
                          >
                            <img
                              src={isHDImages ? (imageUrl + data.HomeSection.OrganizationalAim.FrontSrc) : Organizational_Aim_Front}
                              alt="Organizational Aim"
                              className="w-full h-full anim"
                              loading="lazy"
                            />
                          </Tilt>
                        </div>
                        <img src={isHDImages ? (imageUrl + data.HomeSection.OrganizationalAim.BackSrc) : Organizational_Aim_Background} alt="Organizational Aim" className="w-full h-[350px] sm:h-[400px] lg:h-full lg:object-cover" loading='lazy' />
                      </div>
                    </div>
                  </div>
                  <div className="relative w-full lg:w-1/2">
                    <div className="flex flex-col items-start justify-center relative gradient-border-rightbox h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] md:rounded-br-[30px] lg:rounded-bl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] p-5 md:p-[30px] lg:px-[50px] 2xl:px-[100px]">
                      <div className={`flex items-center justify-center w-10 md:w-[60px] lg:w-20 h-10 md:h-[60px] lg:h-20 rounded-full lg:p-4 gradient-border-2 absolute -top-6 md:-top-8 left-1/2 -translate-x-1/2 lg:right-1/2 lg:-translate-x-0 lg:top-10 lg:-left-10 z-10`}>
                        <img src={isHDImages ? (imageUrl + data.HomeSection.OrganizationalAim.RoundIconSrc) : Organizational_Icon} alt="Organizational Icon" className="w-5 md:w-8 lg:w-full h-5 md:h-8 lg:h-full object-contain" loading='lazy' />
                      </div>
                      <p className='text-enthusia-text capitalize leading-7 text-justify indent-16'>{data.HomeSection.OrganizationalAim.paragraph}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Our Key Strengths: Pioneering Excellence with 100% Client Satisfaction */}
            <div className='relative pt-[30px] sm:pt-[50px] md:pt-[100px]'>
              <LeftBorderHeading heading_title={data.KeyStrengths.heading} />
              <p className="font-normal text-enthusia-text pt-5">{data.KeyStrengths.paragraph}</p>
              <div className="flex flex-wrap pt-[35px] md:-mx-[15px]">
                {(data.KeyStrengths.KeyStrengthsBox).map((data) => (
                  <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.description} />
                ))}
              </div>
            </div>
            {/* Get to know us */}
            <div className="relative pt-[30px] sm:pt-[50px] md:pt-[85px]">
              <LeftBorderHeading heading_title={data.GetToKnowUs.heading} />
              <p className="font-normal text-enthusia-primary indent-6 mt-7">
                {data.GetToKnowUs.paragraph}
              </p>
              <p className="font-normal text-enthusia-primary indent-6 mt-2">
                {data.GetToKnowUs.paragraph_2}
              </p>
              <div className="flex w-full flex-wrap lg:flex-nowrap justify-center space-y-5 md:space-y-0 pt-[30px]">
                {(data.GetToKnowUs.knowsBox).map((data, index) => (
                  <div key={data.id} className="group w-full md:w-1/3 text-center md:px-5 lg:px-[30px]">
                    <div className="contact-small-box rounded-xl md:rounded-3xl group-hover:drop-shadow-xl anim p-5 md:p-10">
                      <img src={isHDImages ? (imageUrl + data.src) : GetToKnowUs.map((data) => (data))[index]} alt={data.buttonName} loading="lazy" className="w-full h-full object-contain" />
                    </div>
                    <Button onClick={() => navigator(`${data.link}`)} button_name={data.buttonName} button_src="hidden" img_class="hidden" />
                  </div>
                ))}
              </div>
            </div>
            {/* Work-Life Balance: Nurturing Well-being and Success */}
            <div className="relative pt-[30px] sm:pt-[50px] md:pt-[85px]">
              <LeftBorderHeading heading_title={data.WorkLifeBalance.heading} />
              <p className="font-normal text-enthusia-text pt-5">{data.WorkLifeBalance.paragraph}</p>
              <div className="pt-[35px]">
                <h5 className="text-2xl font-semibold pb-3">Our Approach to Work-Life Balance</h5>
                <div className="flex flex-wrap md:-mx-[15px]">
                  {(data.WorkLifeBalance.OurApproach).map((data, i) => (
                    <WeDevelopBox key={i} develop_class="w-1/2 lg:w-1/3 p-2.5 md:p-[15px]" images_box="hidden" develop_title_class="!pt-0" develop_second_class="!p-3 !rounded-2xl" develop_title={data} />
                  ))}
                </div>
              </div>
              <div className="pt-[35px]">
                <h5 className="text-2xl font-semibold pb-3">The Benefits of Work-Life Balance</h5>
                <div className="flex flex-wrap md:-mx-[15px]">
                  {(data.WorkLifeBalance.Benefits).map((data, i) => (
                    <WeDevelopBox key={i} develop_class="w-1/2 lg:w-1/3 p-2.5 md:p-[15px]" images_box="hidden" develop_title_class="!pt-0" develop_second_class="!p-3 !rounded-2xl" develop_title={data} />
                  ))}
                </div>
              </div>
              <p className="font-normal text-enthusia-text pt-5">{data.WorkLifeBalance.paragraph_2}</p>
            </div>
            {/* contact from */}
            <div id="contact" className="pt-[50px] md:pt-[100px]">
              <ConsultationFrom
                main_title={data.Contact.MainTitle}
                paragraph_class_2="hidden"
                paragraph_1={data.Contact.paragraph}
              />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Contact;
