const ScopeServicesBox = ({ ScopeServicesBox_class, ScopeServicesBox_title, ScopeServicesBox_paragraph, p_class, ul_class, li_class, li_1, li_2, li_3, li_4, li_5 }) => {
    return (
        <div className={`hover:drop-shadow-xl anim ${ScopeServicesBox_class}`}>
            <div className="customs-services border md:border-[2px] border-transparent p-5 md:p-[30px] md:pl-0 h-full rounded-2xl md:rounded-[24px] overflow-hidden animation pl-0">
                <div className="relative flex items-center">
                    <span className="w-[6px] h-[40px] md:h-[60px] bg-enthusia-primary rounded-r-xl"></span>
                    <h6 className="text-xl md:text-2xl  text-enthusia-primary leading-7 font-medium capitalize pl-[30px]">{ScopeServicesBox_title}</h6>
                </div>
                <p className={`font-normal text-enthusia-text pl-[30px] pt-3 md:pt-5 text-justify ${p_class}`}>{ScopeServicesBox_paragraph}</p>
                <ul className={`text-lg font-normal text-enthusia-primary pl-10 pt-5 list-disc ${ul_class}`}>
                    <li>{li_1}</li>
                    <li>{li_2}</li>
                    <li>{li_3}</li>
                    <li>{li_4}</li>
                    <li className={`${li_class}`}>{li_5}</li>
                </ul>
            </div>
        </div>
    )
}

export default ScopeServicesBox;