import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import { Routers } from './Routers';
import Layout from '../Layout/Layout';
import ScrollToTop from "../Others/ScrollToTop"
import Modal from '../Common/Modal';
import Cookie from '../Popup/Cookie';

function AllRouters() {
    const navigator = useNavigate();
    const [cookiesData, setCookiesData] = useState(false);

    const routeComponents = Routers.map(({ path, Component }, key) => (
        <Route path={path} exact element={<Component />} key={key} />
    ));

    const checkCookieConsent = () => {
        const hasConsent = Cookies.get('cookieConsent') === 'true';
        if (!hasConsent) {
            setCookiesData(true);
        }
    };

    useEffect(() => {
        checkCookieConsent()
    }, [navigator]);

    return (
        <React.Fragment>
            <ScrollToTop />
            <Routes>
                <Route element={<Layout />}>{routeComponents}</Route>
            </Routes>
            <Modal isOpen={cookiesData}>
                <Cookie handleClose={() => setCookiesData(false)} />
            </Modal>
        </React.Fragment>
    );
}

export default AllRouters;
