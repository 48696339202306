const CustomServicesBox = ({ id, onClick, custom_class, custom_box_class, src, alt, custom_services_title, custom_services_paragraph }) => {
    return (
        <div id={id} onClick={onClick} className={`group cursor-pointer anim ${custom_box_class}`}>
            <div className={`customs-services-hover flex flex-col h-full ${custom_class}`}>
                <div className="flex items-center space-x-4">
                    <div className="">
                        <div className="flex items-center justify-center w-10 md:w-[65px] h-10 md:h-[65px] bg-enthusia-primary rounded-full p-2.5 md:p-0 anim">
                            <img src={src} alt={alt} className="w-auto h-auto object-cover" loading="lazy" />
                        </div>
                    </div>
                    <h3 className="leading-[28px] md:leading-[34px] 2xl:leading-[36px] text-enthusia-primary font-semibold anim">{custom_services_title}</h3>
                </div>
                <p className="text-enthusia-text font-normal text-justify anim py-4">{custom_services_paragraph}</p>
                <p className="flex align-bottom justify-end text-enthusia-primary font-semibold anim underline mt-auto">Read More</p>
            </div>
        </div>
    )
}

export default CustomServicesBox