import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import {
    EmailShareButton,
    LinkedinShareButton,
    WhatsappShareButton,
} from 'react-share';

import Close_Icon from '../../Assets/svg/close_icon.svg'
import LinkIcon from '../../Assets/svg/link_icon.svg'

/* Copy Link */
function Share({ shareName, handleClose }) {
    const [copyText, setCopyText] = useState(false)

    const handleCopyLink = () => {
        const linkElement = document.getElementById("linkCoped");
        if (linkElement) {
            const linkToCopy = linkElement.textContent;

            // Create a temporary input element
            const tempInput = document.createElement('input');
            tempInput.value = linkToCopy;
            document.body.appendChild(tempInput);

            // Select the text in the input element
            tempInput.select();
            document.execCommand('copy');

            // Remove the temporary input element
            document.body.removeChild(tempInput);
            setCopyText(true);
        }
        else {
            console.error('Linked Not Coped!!!, Please try after sometime.');
        }
    };
    const shareUrl = `https://enthusiasoftech.com/infohub/fullbolg/${shareName._id}`;
    const title = `Dive into a world of insights and inspiration! Check out my latest ${shareName.mainCategory} post where I share ${shareName.title}. 🚀✨ Explore now. `;

    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50">
            <div className="flex items-center justify-center w-full max-w-[400px] mx-auto animation-slideUp">
                <div className="relative w-full bg-enthusia-primary rounded-2xl md:rounded-[24px] p-8 md:p-[30px] mx-3 md:mx-0">
                    <img onClick={() => handleClose(false)} src={Close_Icon} alt="close_icon" className='absolute top-3 sm:top-4 scale-90 right-3 sm:right-4 cursor-pointer' />
                    <h3 className="text-white sm:text-2xl font-semibold border-b border-white capitalize leading-[28px] md:leading-[34px] pb-2 sm:pb-4">Share Our {shareName.mainCategory}</h3>
                    <span className='block text-lg font-normal text-white py-3 sm:py-5'>Share this Link via</span>
                    <div className="flex items-center space-x-[30px]">
                        {/* share Linkedin icon */}
                        <LinkedinShareButton url={shareUrl} title={title}>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full share-gradient cursor-pointer group anim">
                                <div className="flex justify-center items-center bg-[#0A66C2] w-10 h-10 rounded-full anim p-2">
                                    <svg className="fill-[#fff]  scale-90 group-hover:scale-100 anim" width="37" height="37" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.92921 6.36475C8.12654 6.14253 8.28832 5.91853 8.49098 5.7283C9.1132 5.14164 9.84921 4.84653 10.7097 4.85364C11.1825 4.8572 11.6483 4.89097 12.1034 5.02253C13.1452 5.32297 13.7497 6.0412 14.0394 7.05808C14.2563 7.82075 14.2954 8.60475 14.2972 9.39053C14.3008 11.0474 14.2919 12.7061 14.2954 14.363C14.2954 14.5176 14.2528 14.5585 14.0999 14.5585C13.2465 14.5514 12.3914 14.5514 11.5381 14.5585C11.387 14.5603 11.355 14.5141 11.355 14.3719C11.3603 12.795 11.3603 11.2181 11.355 9.63941C11.3532 9.24475 11.3283 8.84831 11.2181 8.46431C11.0137 7.75675 10.507 7.39586 9.76565 7.43497C8.75231 7.48831 8.2261 7.98964 8.09632 9.01897C8.0661 9.26431 8.0501 9.51142 8.05188 9.75853C8.05365 11.2927 8.05009 12.827 8.05542 14.3612C8.05542 14.5141 8.0181 14.5585 7.86165 14.5585C7.00121 14.5514 6.14076 14.5514 5.28032 14.5585C5.14165 14.5603 5.10254 14.523 5.10254 14.3825C5.10609 11.3461 5.10609 8.30786 5.10254 5.27141C5.10254 5.1203 5.15232 5.08653 5.29454 5.08653C6.11054 5.09186 6.92831 5.09364 7.74431 5.08653C7.89542 5.08475 7.93277 5.13453 7.93099 5.27675C7.92388 5.63764 7.92921 6.00031 7.92921 6.36475Z" />
                                        <path d="M3.25012 9.83389C3.25012 11.3361 3.24834 12.8383 3.25368 14.3406C3.25368 14.5077 3.21279 14.5574 3.04034 14.5557C2.18701 14.5468 1.33367 14.5486 0.478559 14.5539C0.34167 14.5557 0.300781 14.5201 0.300781 14.3797C0.304337 11.3379 0.304337 8.29611 0.300781 5.25256C0.300781 5.12634 0.331011 5.08189 0.464345 5.08189C1.33012 5.08722 2.1959 5.089 3.06167 5.08011C3.22879 5.07834 3.25012 5.14234 3.25012 5.28634C3.24834 6.80456 3.25012 8.31922 3.25012 9.83389Z" />
                                        <path d="M3.48481 2.07082C3.48659 3.01482 2.72036 3.78282 1.77814 3.78282C0.848359 3.78282 0.07681 3.01482 0.0732544 2.08505C0.0696989 1.14638 0.841252 0.373047 1.7817 0.373047C2.71503 0.374825 3.48481 1.14104 3.48481 2.07082Z" />
                                    </svg>
                                </div>
                            </div>
                        </LinkedinShareButton>
                        {/* share Gmail icon */}
                        <EmailShareButton url={shareUrl} subject={title}>
                            <Link className="flex items-center justify-center w-12 h-12 rounded-full share-gradient cursor-pointer group anim">
                                <div className="flex justify-center items-center bg-white w-10 h-10 rounded-full p-2 anim">
                                    <svg className='scale-90 group-hover:scale-100 anim' width="37" height="29" viewBox="0 0 37 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.292 18.6474L1.90199 7.58074C1.00295 6.98619 0.37337 6.06234 0.148816 5.00815C-0.0757374 3.95395 0.122699 2.85373 0.701395 1.9444C1.28009 1.03508 2.19275 0.389396 3.24285 0.146391C4.29295 -0.0966146 5.39648 0.0824981 6.31581 0.64516L19.074 8.76365L30.529 0.744923C31.4227 0.139299 32.5188 -0.0904697 33.5805 0.105244C34.6422 0.300958 35.5844 0.906446 36.2034 1.79093C36.8224 2.67542 37.0687 3.76791 36.889 4.83245C36.7094 5.89698 36.1181 6.84812 35.2431 7.48043L19.292 18.6474Z" fill="#EA4435" />
                                        <path d="M34.2593 28.7773H28.7778L28.7778 4.11068C28.7778 3.02034 29.2109 1.97467 29.9819 1.20368C30.7529 0.432701 31.7986 -0.000431061 32.8889 -0.000431061C33.9792 -0.000431061 35.0249 0.432701 35.7959 1.20368C36.5669 1.97467 37 3.02034 37 4.11068V26.0366C37 26.7635 36.7112 27.4606 36.1973 27.9746C35.6833 28.4886 34.9861 28.7773 34.2593 28.7773Z" fill="#00AC47" />
                                        <path d="M36.9401 3.51551C36.928 3.43329 36.929 3.34928 36.9119 3.2672C36.8856 3.1414 36.8366 3.02519 36.7991 2.90377C36.7627 2.76481 36.7189 2.62789 36.6678 2.49362C36.6404 2.42853 36.5982 2.37139 36.5671 2.30807C36.4818 2.1254 36.3832 1.94929 36.2719 1.78117C36.2171 1.70155 36.1486 1.63372 36.088 1.55834C35.9841 1.42136 35.8717 1.29097 35.7516 1.16793C35.6605 1.07926 35.5566 1.00513 35.4575 0.925233C35.3522 0.834949 35.2424 0.750016 35.1286 0.670755C35.0199 0.59977 34.901 0.545092 34.786 0.484522C34.6649 0.421348 34.5462 0.352555 34.4201 0.302126C34.2983 0.253066 34.1684 0.221822 34.0411 0.184411C33.9138 0.147 33.7872 0.102189 33.6564 0.077933C33.4945 0.0519158 33.3311 0.0354432 33.1672 0.0286C33.0632 0.0213371 32.9601 0.00311096 32.8557 0.00393318C32.6619 0.00958313 32.4686 0.0290461 32.2776 0.0621739C32.2004 0.0736851 32.1227 0.0727258 32.0461 0.0886221C31.7875 0.167631 31.5297 0.248943 31.2725 0.332548C31.202 0.362696 31.1401 0.407507 31.0718 0.441493C30.3759 0.766212 29.7889 1.28553 29.3818 1.93669C28.9748 2.58785 28.765 3.34298 28.7779 4.1108V12.0069L35.2461 7.47875C35.8812 7.05247 36.3792 6.45123 36.6798 5.7478C36.9804 5.04437 37.0711 4.26917 36.9401 3.51551Z" fill="#FFBA00" />
                                        <path d="M4.11111 0C5.20145 0 6.24712 0.433134 7.01811 1.20412C7.78909 1.9751 8.22222 3.02078 8.22222 4.11111V28.7778H2.74074C2.01385 28.7778 1.31673 28.489 0.802744 27.975C0.288756 27.461 0 26.7639 0 26.037V4.11111C0 3.02078 0.433134 1.9751 1.20412 1.20412C1.9751 0.433134 3.02078 0 4.11111 0Z" fill="#4285F4" />
                                        <path d="M0.058241 3.51647C0.0703002 3.43424 0.0693411 3.35024 0.0864707 3.26816C0.112782 3.14235 0.161704 3.02615 0.199252 2.90473C0.235652 2.76581 0.279475 2.62895 0.330534 2.49472C0.357941 2.42963 0.400148 2.37248 0.431393 2.30917C0.516603 2.12642 0.615267 1.95026 0.726571 1.78213C0.781385 1.70251 0.849904 1.63467 0.910474 1.5593C1.01437 1.42232 1.12669 1.29193 1.24676 1.16888C1.33789 1.08022 1.44177 1.00608 1.54098 0.926192C1.64625 0.835873 1.75602 0.750939 1.86987 0.671714C1.97854 0.600729 2.09749 0.546052 2.21246 0.485481C2.33128 0.418275 2.4534 0.357079 2.57835 0.302126C2.70032 0.253066 2.83023 0.221822 2.9574 0.184411C3.08457 0.147 3.21133 0.102189 3.34206 0.077933C3.50403 0.0519227 3.66738 0.0354502 3.83128 0.0286C3.93543 0.0213371 4.03834 0.00311096 4.14277 0.00393318C4.33663 0.00957616 4.52984 0.0290391 4.72093 0.0621739C4.79808 0.0736851 4.87578 0.0727258 4.95252 0.0886221C5.08517 0.122421 5.21598 0.163038 5.34444 0.210311C5.47363 0.244647 5.601 0.285444 5.72609 0.332548C5.79653 0.362696 5.85833 0.407507 5.92685 0.441493C6.10449 0.524948 6.27587 0.621124 6.43964 0.72927C6.98866 1.10624 7.43768 1.61119 7.74791 2.2005C8.05814 2.78981 8.22027 3.44577 8.2203 4.11175V12.0078L1.75216 7.47999C1.11709 7.05358 0.619156 6.45234 0.318544 5.74895C0.0179323 5.04556 -0.0724791 4.27015 0.058241 3.51647Z" fill="#C52528" />
                                    </svg>
                                </div>
                            </Link>
                        </EmailShareButton>
                        {/* share whatsapp icon*/}
                        <WhatsappShareButton url={shareUrl} title={title}>
                            <div className="flex items-center justify-center w-12 h-12 rounded-full share-gradient cursor-pointer group anim">
                                <div className="flex justify-center items-center bg-[#4CAF50] w-10 h-10 rounded-full p-2 anim">
                                    <svg className='fill-white scale-90 group-hover:scale-100 anim' width="37" height="37" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.2681 8.45713C10.1275 8.3868 9.21317 7.96482 9.07251 7.89449C8.93185 7.82416 8.79119 7.82416 8.65053 7.96482C8.50987 8.10548 8.22856 8.52746 8.0879 8.66812C8.01757 8.80877 7.87691 8.80877 7.73625 8.73845C7.24394 8.52746 6.75164 8.24614 6.32966 7.89449C5.97801 7.54284 5.62636 7.12087 5.34505 6.69889C5.27472 6.55823 5.34505 6.41757 5.41537 6.34724C5.4857 6.27691 5.55603 6.13625 5.69669 6.06592C5.76702 5.99559 5.83735 5.85493 5.83735 5.7846C5.90768 5.71427 5.90768 5.57362 5.83735 5.50329C5.76702 5.43296 5.41537 4.589 5.27472 4.23735C5.20439 3.74505 5.06373 3.74505 4.92307 3.74505H4.57142C4.43076 3.74505 4.21977 3.88571 4.14944 3.95604C3.72747 4.37801 3.51648 4.87032 3.51648 5.43296C3.58681 6.06592 3.7978 6.69889 4.21977 7.26153C4.9934 8.3868 5.97801 9.30108 7.17361 9.86372C7.52526 10.0044 7.80658 10.145 8.15823 10.2154C8.50987 10.356 8.86152 10.356 9.2835 10.2857C9.77581 10.2154 10.1978 9.86372 10.4791 9.44174C10.6198 9.16042 10.6198 8.8791 10.5494 8.59779L10.2681 8.45713ZM12.0264 2.05714C9.2835 -0.685713 4.85274 -0.685713 2.10989 2.05714C-0.140659 4.30768 -0.562636 7.75383 0.984614 10.4967L0 14.0835L3.72747 13.0989C4.78241 13.6615 5.90768 13.9428 7.03295 13.9428C10.9011 13.9428 13.9956 10.8483 13.9956 6.98021C14.0659 5.15164 13.2923 3.3934 12.0264 2.05714ZM10.1275 11.9033C9.21317 12.4659 8.15823 12.8176 7.03295 12.8176C5.97801 12.8176 4.9934 12.5362 4.07911 12.0439L3.86812 11.9033L1.68791 12.4659L2.25055 10.356L2.10989 10.145C0.421977 7.33185 1.26593 3.81538 4.00878 2.05714C6.75164 0.298901 10.2681 1.21318 11.956 3.88571C13.6439 6.62856 12.8703 10.2154 10.1275 11.9033Z" />
                                    </svg>
                                </div>
                            </div>
                        </WhatsappShareButton>
                    </div>
                    <div className='pt-5 md:pt-[24px]'>
                        <span className='block text-lg font-normal text-white'>Or Copy link</span>
                        <div className='flex items-center border-[2px] border-white bg-white rounded-full mt-3 md:mt-5'>
                            <img src={LinkIcon} alt="Link Icon" className='mx-2' />
                            <span id='linkCoped' className='text-sm font-normal copy-link-text max-w-[310px] w-full truncate'>{shareUrl}</span>
                            <button onClick={handleCopyLink} className='relative flex ml-auto text-sm font-medium text-white bg-enthusia-primary py-2.5 px-5 rounded-full'>{copyText ? "Copied" : "Copy"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Share