import React from 'react'
import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Jira from '../../Assets/Webp/jira.webp'
import Trello from '../../Assets/Webp/trello.webp'
import Asana from '../../Assets/Webp/asana.webp'
import ClickUp from '../../Assets/Webp/click_up.webp'

import Git from '../../Assets/Webp/git.webp'
import Github from '../../Assets/Webp/github.webp'
import Gitlab from '../../Assets/Webp/gitlab.webp'
import Bitbucket from '../../Assets/Webp/bitbucket.webp'

import Jenkins from '../../Assets/Webp/jenkins.webp'
import Travisci from '../../Assets/Webp/travisci.webp'

import Docker from '../../Assets/Webp/docker.webp'
import Kubernetes from '../../Assets/Webp/kubernetes.webp'

import GoogleAnalytics from '../../Assets/Webp/googleanalytics.webp'
import Mixpanel from '../../Assets/Webp/mixpanel.webp'
import AdobeAnalytics from '../../Assets/Webp/adobeanalytics.webp'

import Newrelic from '../../Assets/Webp/newrelic.webp'
import Sentry from '../../Assets/Webp/sentry.webp'
import Datadog from '../../Assets/Webp/datadog.webp'

function CommonTechnologies() {
    return (
        <div className='w-full p-4 md:p-[30px]'>
            <div className="flex flex-wrap items-start justify-between">
                <div className="w-full lg:w-1/2">
                    <p className='leading-9 font-medium text-[#737373]'>Bug Tracking and Project Management :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Jira} alt="JIRA" technologies_title="JIRA" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Trello} alt="Trello" technologies_title="Trello" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Asana} alt="Asana" technologies_title="Asana" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={ClickUp} alt="Clickup" technologies_title="Clickup" />
                    </div>
                </div>
                <div className="w-full lg:w-1/2 p4-8 md:pt-[45px] lg:pt-0">
                    <p className='md:leading-9 font-medium text-[#737373]'>DevOps and Deployment :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Git} alt="Git" technologies_title="Git" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Github} alt="GitHub" technologies_title="GitHub" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Gitlab} alt="GitLab" technologies_title="GitLab" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Bitbucket} alt="Bitbucket" technologies_title="Bitbucket" />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap items-start justify-between">
                <div className="w-full lg:w-1/2 pt-4 md:pt-[45px]">
                    <p className='md:leading-9 font-medium text-[#737373]'>Continuous Integration/Continuous Deployment (CI/CD) :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Jenkins} alt="Jenkins" technologies_title="Jenkins" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Travisci} alt="Travis CI" technologies_title="Travis CI" />
                    </div>
                </div>
                <div className="w-full lg:w-1/2 pt-4 md:pt-[45px]">
                    <p className='md:leading-9 font-medium text-[#737373]'>Containerization and Orchestration :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Docker} alt="Docker" technologies_title="Docker" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Kubernetes} alt="Kubernetes" technologies_title="Kubernetes" />
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap items-start justify-between">
                <div className="w-full lg:w-1/2 pt-4 md:pt-[45px]">
                    <p className='md:leading-9 font-medium text-[#737373]'>Analytics and Monitoring :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={GoogleAnalytics} alt="Google Analytics" technologies_title="Google Analytics" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Mixpanel} alt="Mixpanel" technologies_title="Mixpanel" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={AdobeAnalytics} alt="Adobe Analytics" technologies_title="Adobe Analytics" />
                    </div>
                </div>
                <div className="w-full lg:w-1/2 pt-4 md:pt-[45px]">
                    <p className='md:leading-9 font-medium text-[#737373]'>Error and Performance Monitoring :</p>
                    <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Newrelic} alt="New Relic" technologies_title="New Relic" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Sentry} alt="Sentry" technologies_title="Sentry" />
                        <TechnologiesTabBox technologies_box_class="w-1/4 p-2 2xl:px-5" src={Datadog} alt="Datadog" technologies_title="Datadog" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonTechnologies