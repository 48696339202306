import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Android from '../../Assets/svg/android.svg'
import Ios from '../../Assets/svg/ios.svg'

function AppPlatformsTechnologies() {
  return (
    <div className='w-full'>
      <p className='leading-9 font-medium text-[#737373]'>Operating System :</p>
      <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Android} alt="Android" technologies_title="Android" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Ios} alt="Ios" technologies_title="iOS" />
      </div>
    </div>
  )
}

export default AppPlatformsTechnologies