import { useEffect, useState } from 'react';

const useScrollSpy = (sectionIds) => {
  const [activeLink, setActiveLink] = useState(sectionIds[0]);

  useEffect(() => {
    const handleScroll = () => {
      for (const section of sectionIds) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom >= window.innerHeight / 2 && rect.top <= window.innerHeight / 2) {
            setActiveLink(section);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [sectionIds]);

  return activeLink;
};

export default useScrollSpy;
