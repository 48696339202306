import { useLocation } from "react-router-dom";
import useScrollEffect from "../Components/hook/useScrollEffect"
import EnthusiaLogo from '../Assets/svg/EnthusiaCenterHeaderSvg.svg'

const EnthusiaCenterHeader = () => {
    const location = useLocation();
    const isScrollingUp = useScrollEffect();
    return (
        <div className={`w-full grid place-items-center z-40 anim ${location.pathname === "/" ? "absolute" : "sticky top-0 bg-white"} ${!isScrollingUp ? "-translate-y-full anim" : "translate-y-0 anim"}`} >
            <img src={EnthusiaLogo} alt="Enthusis Header" className="w-auto h-auto object-contain my-5" />
            <span className={`block w-full h-[3px] bg-gradient-to-r from-[rgba(255,255,255,0.1)] via-[#134046] to-[rgba(255,255,255,0.1)] z-10`}></span>
        </div>
    )
}

export default EnthusiaCenterHeader;