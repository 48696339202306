import { useState } from 'react';
import Button from './Button'
import Modal from '../Components/Common/Modal';
import ContactFrom from '../Components/Popup/ContactFrom';

function Development({ development_class, src, alt, title_class, development_title, paragraph_1_class, development_paragraph, paragraph_2_class, development_paragraph_2, button_name }) {
    const [consultFrom, setConsultFrom] = useState(false);
    return (
        <div className={`flex flex-wrap justify-between ${development_class}`}>
            <div className="w-full lg:w-1/2 h-auto">
                <div className="flex flex-col items-center justify-center w-full h-full bg-gradient-to-r from-[rgba(19,64,70,0.1)] rounded-tl-2xl rounded-tr-2xl md:rounded-tl-[30px] md:rounded-tr-[30px]  lg:rounded-tr-none lg:rounded-l-[50px] p-5 md:p-[30px] 2xl:p-[50px]">
                    <img src={src} alt={alt} className='w-[300px] h-[300px] object-contain mx-auto' loading='lazy' />
                </div>
            </div>
            <div className="relative w-full lg:w-1/2">
                <div className="flex flex-col items-start justify-center relative gradient-border-rightbox h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] md:rounded-br-[30px] lg:rounded-bl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] p-5 md:p-[30px] 2xl:p-[50px]">
                    <h3 className={`text-[32px] font-medium text-enthusia-primary leading-[28px] md:leading-[34px] pb-[30px] ${title_class}`}>{development_title} </h3>
                    <p className={`text-enthusia-primary font-normal text-justify ${paragraph_1_class}`}>{development_paragraph}</p>
                    <p className={`text-enthusia-primary font-normal text-justify py-[30px] ${paragraph_2_class}`}>{development_paragraph_2}</p>
                    <Button onClick={() => setConsultFrom(true)} button_name={button_name} img_class="hidden" />
                </div>
            </div>
            {/* consultFrom Popup */}
            <Modal isOpen={consultFrom}>
                <ContactFrom handleClose={setConsultFrom} />
            </Modal>
        </div>
    )
}

export default Development;