import React from 'react'
import Cookies from 'js-cookie';
import CookiesData from '../../static/PolicyAndConditions.json'
import Cookie_Icon from "../../Assets/svg/cookies_icon.svg"
import Modal from '../Common/Modal';
import { useState } from 'react';
import CustomCookie from './CustomCookie';
import CookiePolicy from './CookiePolicy';

function Cookie({ handleClose }) {
    const [customCookiesData, setCustomCookiesData] = useState(false);
    const [cookiePolicyPopUp, setCookiePolicyPopUp] = useState(false)

    const handleConsent = () => {
        Cookies.set('cookieConsent', 'true', { expires: 1 });
        handleClose(false)
    };

    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50">
            <div className='flex items-center justify-center max-w-[721px] w-full mx-auto animated-slideUp'>
                <div className="relative w-full bg-enthusia-primary rounded-2xl md:rounded-[30px] p-3 xsm:p-5 sm:p-8 mx-3 md:mx-0">
                    <div className="flex items-center justify-start">
                        <img src={Cookie_Icon} alt="cookie_icon" className='w-8 md:w-12 h-8 md:h-12' />
                        <h4 className='text-white text-xl md:text-2xl font-semibold pl-[22px]'>We Value Your Privacy</h4>
                    </div>
                    <p className='font-normal text-white py-5 md:pt-[22px] md:pb-[35px]'>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.<span onClick={() => setCookiePolicyPopUp(true)} className='inline-flex items-center font-medium cursor-pointer underline '>Read More</span></p>
                    <div className="w-full flex flex-wrap sm:flex-nowrap  items-center justify-start space-x-3 sm:space-x-[18px]">
                        <button onClick={handleConsent} className='text-enthusia-primary bg-white text-sm md:text-base md:text-xl border border-white font-medium rounded-full px-5 sm:px-[30px] py-1.5 animation'>Accept All</button>
                        <button onClick={() => setCustomCookiesData(true)} className='text-white bg-enthusia-primary text-sm md:text-base md:text-xl hover:bg-white hover:text-enthusia-primary border border-white font-medium rounded-full px-5 sm:px-[30px] py-1.5 animation'>Customize Cookies</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={customCookiesData}>
                <CustomCookie handleClose={handleClose} handleCustomClose={setCustomCookiesData} />
            </Modal>
            <Modal isOpen={cookiePolicyPopUp} >
                <CookiePolicy data={CookiesData} handleClose={setCookiePolicyPopUp} />
            </Modal>
        </div>
    )
}

export default Cookie