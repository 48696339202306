import React from 'react'
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import data from '../../static/PolicyAndConditions.json'
import HeadingTitle from '../../Utility/HeadingTitle';

function TermsAndConditions() {
    return (
        <main className="wrapper">
            {/* Terms and Conditions */}
            <div className="w-full mt-[60px]">
                <HeadingTitle title_name={data.TermsAndConditions.heading} heading_class="order-1" heading_main="!cursor-auto" line_class="bg-gradient-to-r order-2 sm:ml-5" />
            </div>
            <div className="pt-[50px]">
                <h5 className='font-semibold'>Effective Date : {data.TermsAndConditions.EffectiveDate}</h5>
                <p className='pt-5'>{data.TermsAndConditions.Description}</p>
            </div>
            <div className="pt-[50px] lg:pt-[80px]">
                {data.TermsAndConditions.Points.map((data, mainIndex) => (
                    <div key={data.id} className="pb-5 md:pb-10">
                        <h3 className='font-bold'>{data.id}. {data.heading}</h3>
                        {data.Description && <p className='pt-3 md:pl-9'>{data.Description}</p>}
                        {data.subPoint && (data.subPoint).map((data, i) => (
                            <div key={data.id} className='pt-8 md:pl-9'>
                                <h4 className='text-2xl font-semibold'><span className='pr-3'>{mainIndex + 1}.{data.id}</span>{data.title}</h4>
                                <p className='pt-3 md:pt-0'>{data.Description}</p>
                                {data.Points && data.Points.map((data, i) => (
                                    <div key={i} className='text-base text-enthusia-primary mt-3 md:mt-5'>
                                        <span className='pb-0' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <React.Fragment>
                <p>{data.TermsAndConditions.contactInfo.Description}</p>
                <span className='block text-xl font-semibold text-enthusia-primary py-3'>{data.TermsAndConditions.contactInfo.Title}</span>
                <div className="">
                    <Link to={"mailto:Info@enthusiasoftech.com"} className="inline-block text-enthusia-primary text-lg space-x-3 ">
                        <span className='font-semibold inline-block w-[70px] xsm:w-[80px]'>Email</span>:
                        <span>{data.TermsAndConditions.contactInfo.Email}</span>
                    </Link>
                </div>
                <div className="">
                    <Link to={"tel:+918200550680"} className="inline-block text-enthusia-primary text-lg space-x-3 pt-3">
                        <span className='font-semibold inline-block w-[70px] xsm:w-[80px]'>Mobile</span>:
                        <span>{data.TermsAndConditions.contactInfo.PhoneNumber}</span>
                    </Link>
                </div>
            </React.Fragment>
        </main>
    )
}

export default TermsAndConditions