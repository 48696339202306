import React, { useState } from "react";
import SEO from "../Common/SEO";
import customData from '../../static/SubServices.json'
import DOMPurify from 'dompurify';
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from "../../Utility/HeadingTitle";
import Button from "../../Utility/Button";
import LeftBorderHeading from "../../Utility/LeftBorderHeading";
import ScopeServicesBox from "../../Utility/ScopeServicesBox";
import OursProcessSection from "../../Utility/OursProcessSection";
import ConsultationFrom from "../Others/ConsultationFrom";
import ConsultationGetStarted from "../Others/ConsultationGetStarted";
import Modal from "../Common/Modal";
import ContactFrom from "../Popup/ContactFrom";

import ConsultationBox from "../../Assets/svg/consultation_box.svg";
import ArrowIcon from "../../Assets/svg/arrow_line.svg";
import ConsultationScheduled from "../../Assets/Webp/consultation_scheduled.webp";

function Consultation() {
    const data = customData.consultation;
    const isHDImages = false;
    const [consultFrom, setConsultFrom] = useState(false);
    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className="wrapper">
                {/* consultation Box*/}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[900px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? (data.headingBox.src) : ConsultationBox}
                            alt="consultation"
                            className="w-full h-full"
                            loading="lazy"
                        />
                    </div>
                </div>
                {/* consultation */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.GetAConsultation.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <p className="text-enthusia-primary font-normal text-justify indent-16 pt-5 md:pt-[50px]">
                        {data.GetAConsultation.paragraph}
                    </p>
                </div>
                {/* Certainly, let's get you scheduled for a consultation! */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <h3 className="text-center leading-[28px] md:leading-[50px]  text-enthusia-primary font-medium">
                        {data.GetAConsultation.subHeading}
                    </h3>
                    <div className="relative flex justify-center max-w-[300px] w-full mx-auto pt-5 md:pt-[50px]">
                        <Button
                            onClick={() => setConsultFrom(true)}
                            button_class="px-[30px] py-2"
                            button_name="We're Here For You"
                            img_class="hidden"
                        />
                        <img
                            src={ArrowIcon}
                            alt="Consultation Scheduled"
                            className="hidden md:inline-block absolute -left-28 top-4"
                            loading="lazy"
                        />
                    </div>
                    <img
                        src={isHDImages ? (imageUrl + data.GetAConsultation.src) : ConsultationScheduled}
                        alt="Consultation Scheduled"
                        className="w-auto h-auto object-contain"
                        loading="lazy"
                    />
                </div>
                {/* Why Choose Us */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Why choose us for Technology Partner" />
                    <div className="flex flex-wrap pt-5 md:pt-[35px] md:-mx-[15px]">
                        {data.WhyChoose.map((data) => (
                            <ScopeServicesBox
                                key={data.id}
                                ScopeServicesBox_class="w-full md:w-1/2 pb-5 md:p-[15px]"
                                ul_class="hidden"
                                ScopeServicesBox_title={data.title}
                                ScopeServicesBox_paragraph={data.Description}
                            />
                        ))}
                    </div>
                </div>
                {/* How to Get Started */}
                <div className="pt-8 md:pt-[50px] lg:pt-[85px]">
                    <ConsultationGetStarted />
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_2={data.Contact.paragraph_2}
                    />
                </div>
                {/* consultFrom Popup */}
                <Modal isOpen={consultFrom}>
                    <ContactFrom handleClose={setConsultFrom} />
                </Modal>
            </main>
        </React.Fragment>
    );
}

export default Consultation;
