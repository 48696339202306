import React from 'react'

import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Wordpress from '../../Assets/svg/wordpress.svg'
import Woocommerce from '../../Assets/svg/woocommerce.svg'
import Bigcommerce from '../../Assets/svg/bigcommerce.svg'
import Firebase from '../../Assets/svg/firebase.svg'
import Shopify from '../../Assets/svg/shopify.svg'

function WebPlatformsTechnologies() {
  return (
    <React.Fragment>
      <p className='leading-9 font-medium text-[#737373]'>Technologies & Tools :</p>
      <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Wordpress} alt="Wordpress" technologies_title="Wordpress" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Woocommerce} alt="Woocommerce" technologies_title_class="break-all" technologies_title="Woocommerce" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Bigcommerce} alt="Bigcommerce" technologies_title_class="break-all" technologies_title="Bigcommerce" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Firebase} alt="Firebase" technologies_title="Firebase" />
        <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Shopify} alt="Shopify" technologies_title="Shopify" />
      </div>
    </React.Fragment>
  )
}

export default WebPlatformsTechnologies