import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { subscribeUser } from "./Slice/SubscribeSlice";

const combineReducer = combineReducers({
  subscriber: subscribeUser,
})

const Store = configureStore({
  reducer: combineReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
});

export default Store;