import { useEffect, useState } from 'react';

const useScrollEffect = () => {
    const [lastPos, setLastPos] = useState(0);
    const [isScrollingUp, setIsScrollingUp] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const winPos = window.pageYOffset;

            if (winPos < lastPos) {
                setIsScrollingUp(true);
            } else {
                setIsScrollingUp(false);
            }

            setLastPos(winPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastPos]);

    return isScrollingUp;
};

export default useScrollEffect;
