import React from 'react'
import ContactWhiteFrom from './ContactWhiteFrom'
import data from '../../static/ProcessContent.json';

import WhiteArrow from '../../Assets/svg/arrow-left-white.svg'
function ProcessContentBox({ processStep, setProcessStep, scrollToSection }) {

    const processStepNames = {
        1: "Get In Touch",
        2: "Ideation",
        3: "Quick kick-off",
        4: "Work Elicitation",
        5: "Contract transparency",
        6: "Planning & strategize Documentation",
        7: "Requirement Walkthrough",
        8: "Design And Prototyping",
        9: "Development",
        10: "Quality assurance",
        11: "Security check",
        12: "Deployment",
        13: "Maintenance and care",
    };

    const handleNext = () => {
        // Update the current step to move to the next step
        setProcessStep((prevStep) => prevStep + 1);
        scrollToSection();
    };

    const handlePreview = () => {
        // Update the current step to go back to the previous step
        setProcessStep((prevStep) => prevStep - 1);
        scrollToSection();
    };

    return (
        <div className='relative'>
            <h3 className="text-[35px] leading-[28px] md:leading-[34px] font-semibold text-enthusia-primary capitalize">
                {processStep}. {processStepNames[processStep]}
            </h3>
            <div className="pt-5">
                {/* Process Step 1 */}
                {processStep === 1 && <div className="">
                    <p className="leading-7 font-normal text-enthusia-text text-justify">Don't miss out on the countless opportunities that a well-crafted application can offer your business. Get started today by filling out the consultation request form, and let's bring your vision to life.</p>
                    <p className={`leading-7 font-normal text-enthusia-text text-justify pt-3 pb-5`}>At Enthusia Softech, we're not just developers; we're your partners in success.</p>
                    <ContactWhiteFrom contact_from_className="!m-0" /></div>
                }
                {/* Process Step 2 */}
                {processStep === 2 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-2'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-2'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-2'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-2'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 3 */}
                {processStep === 3 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-3'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-3'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-3'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-3'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 4 */}
                {processStep === 4 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-4'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-4'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-4'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-4'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 5 */}
                {processStep === 5 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-5'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-5'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-5'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-5'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 6 */}
                {processStep === 6 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-6'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-6'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-6'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-6'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 7 */}
                {processStep === 7 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-7'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-7'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-7'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-7'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 8 */}
                {processStep === 8 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-8'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-8'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-8'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-8'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 9 */}
                {processStep === 9 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-9'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-9'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-9'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-9'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 10 */}
                {processStep === 10 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-10'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-10'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-10'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-10'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 11 */}
                {processStep === 11 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-11'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-11'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-11'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-11'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 12 */}
                {processStep === 12 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-12'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-12'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-12'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-12'].Ending_paragraph}</p>}
                </div>}
                {/* Process Step 13 */}
                {processStep === 13 && <div className="relative">
                    <p className="font-normal text-enthusia-primary">{data['step-13'].Starting_paragraph}</p>
                    <div className="space-y-5 pt-5">
                        {data['step-13'].topics.map((processData, index) => (
                            <div key={index} className="">
                                <span className="process-dot-small-heading">{processData.title}</span>
                                <p className="process-content-heading">{processData.paragraph}</p>
                            </div>
                        ))}
                    </div>
                    {data['step-13'].Ending_paragraph && <p className="font-normal text-enthusia-primary pt-3">{data['step-13'].Ending_paragraph}</p>}
                </div>}
                <div className="flex items-center justify-between mt-4">
                    {processStep > 1 && processStep < 14 && (
                        <div onClick={handlePreview} className="block bg-enthusia-primary rounded-md  cursor-pointer px-5 py-2">
                            <img src={WhiteArrow} alt="Next Button Arrow" className='w-auto h-auto object-cover rotate-180' />
                        </div>
                        /* <Button onClick={handlePreview} type="button" button_class="" button_name="Preview" img_class={WhiteArrow} /> */
                    )}
                    {processStep < 13 && processStep > 0 && (
                        <div onClick={handleNext} className="block bg-enthusia-primary rounded-md  cursor-pointer px-5 py-2 ml-auto">
                            <img src={WhiteArrow} alt="Next Button Arrow" className='w-auto h-auto object-cover' />
                        </div>
                        /* <Button onClick={handleNext} type="button" button_class="flex ml-auto cursor-pointer px-[30px] py-2" button_name="Next" img_class={WhiteArrow} /> */
                    )}
                </div>
            </div>
        </div>
    )
}
export default ProcessContentBox