const Button = ({ onClick, type, button_name, button_class, button_src, img_class }) => {
    return (
        <button type={type} onClick={onClick} className={`relative inline-block items-center text-sm md:text-lg sm:text-xl bg-gradient-radial from-[#225E66] to-[#134046] text-white rounded-full cursor-pointer overflow-hidden font-medium capitalize px-[30px] py-2 ${button_class}`}>
            <div className="flex items-center">
                <span>{button_name}</span>
                <img src={button_src} alt="Right Arrow" className={img_class} loading="lazy" />
                <span className="button-animation"></span>
            </div>
        </button>
    )
}

export default Button;