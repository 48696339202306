import axios from "axios";
import { baseUrl } from "../../Api/baseUrl";
import { toast } from "react-toastify";

export const apiInstance = axios.create({
    baseURL: baseUrl,
});
apiInstance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

/* Global Error Message */
/* apiInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error?.response?.data?.message) {
            toast.error(error?.response?.data?.message);
        } else {
            if (!error?.response?.data?.message) {
                toast.error('Something went Wrong!');
            }
            if (typeof error?.response?.data?.message === "string") {
                toast.error(error.response.data.message);
            } else {
                for (let i = 0; i < error?.response?.data?.message?.length; i++) {
                    toast.error(error.response.data.message[i]);
                }
            }
        }
        return Promise.reject(error);
    }
); */

apiInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const errorMessage =
            error?.response?.data?.message ||
            error?.response?.data?.message?.join(', ') ||
            'Something went wrong!';

        toast.error(errorMessage);
        return Promise.reject(error);
    }
);


/* Custom Single Filed Error Message */

/* apiInstance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.log(error, "Axios Error ==");
        if (!error?.response?.data?.message) {
            error.message = 'Something went Wrong!';
        }
        if (error?.response?.data?.code === 'E_USER_NOT_FOUND') {
            error.message = 'User not found'; // Modify the message as needed
        }
        if (typeof error?.response?.data?.message === "string") {
            error.message = error.response.data.message;
        } else {
            if (Array.isArray(error?.response?.data?.message)) {
                error.message = error.response.data.message.join(", ");
            }
        }
        throw error; // Throw the modified error
    }
); */
