import React, { useCallback, useEffect, useState } from "react";

import PhoneInput from 'react-phone-number-input';
import * as Yup from 'yup';
import { useFormik } from 'formik'
import PhoneNumber from 'libphonenumber-js';
import axios from "axios";
import { baseUrl } from "../../Api/baseUrl";
import { CONTACT } from "../../Api/constantApi";

import Input from "../../Utility/Input";
import Textarea from "../../Utility/Textarea";

import ContactFrom_Icon from '../../Assets/svg/contactfrom__icon.svg'
import Close_Icon from '../../Assets/svg/close_icon.svg'


function ContactFrom({ handleClose }) {
    const [error, setError] = useState("");
    const [successFull, setSuccessFull] = useState(null);

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
    }

    const isValidPhoneNumber = (phoneNumber) => {
        const parsedPhoneNumber = PhoneNumber(phoneNumber, 'ZZ');
        return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
    };

    const ValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required*').matches(/^[A-Za-z]+$/, 'First Name only letters').transform((value, originalValue) => originalValue.replace(/\s/g, '')),
        lastName: Yup.string().required('Last Name is required*').matches(/^[A-Za-z]+$/, 'First Name only letters').transform((value, originalValue) => originalValue.replace(/\s/g, '')),
        email: Yup.string().required('Email is required*'),
        phoneNumber: Yup.string()
            .required('Phone Number is required*')
            .test('is-valid-phone', 'Invalid phone number', (value) => isValidPhoneNumber(value)),
        message: Yup.string().required('Message is required*'),
    });

    const clickNextHandler = async (values) => {
        const payload = { ...values };
        try {
            const response = await axios.post(`${baseUrl}${CONTACT}`, payload);
            console.log("response", response);
            if (response.data.status === 200) {
                setSuccessFull(response.data.message)
                formik.resetForm();
                setError("")
                setInterval(() => {
                    setSuccessFull(null);
                    localStorage.removeItem('consultationFormData');
                }, 1000);
            } else {
                setError("Something went wrong while submitting the form.");
            }
        } catch (error) {
            setError(error.response?.data?.message || "Something went wrong while submitting the form.");
        } finally {
            handleClose(false);
        }
    }

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: ValidationSchema,
        onSubmit: clickNextHandler,
        validateOnBlur: false,
        validateOnChange: false,
    });

    useEffect(() => {
        // Retrieve saved form data from localStorage
        const savedData = localStorage.getItem('consultationFormData');
        if (savedData) {
            formik.setValues(JSON.parse(savedData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateAndSaveFormData = (values) => {
        formik.setValues(values);
        localStorage.setItem('consultationFormData', JSON.stringify(values));
    };

    const setInputValue = useCallback(
        (key, value) => {
            const newValues = { ...formik.values, [key]: value };
            updateAndSaveFormData(newValues);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik]
    );

    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50">
            <div className="flex items-start xsm:items-center justify-center max-w-[800px] w-full mx-auto animated-slideUp mt-7 xsm:mt-0">
                <div className="relative w-full bg-enthusia-primary rounded-2xl md:rounded-[30px] p-3 md:p-6 m-3">
                    <img onClick={() => handleClose(false)} src={Close_Icon} alt="close_icon" className='absolute sm:top-5 top-2 right-2 sm:right-5 scale-90 xsm:scale-100 cursor-pointer' />
                    <div className="flex items-center space-x-[10px] md:space-x-[22px]">
                        <img src={ContactFrom_Icon} alt="contactfrom_icon" className="w-7 h-7 md:w-auto md:h-auto" />
                        <h3 className="text-white font-semibold leading-[28px] md:leading-[34px]">Get in Touch With Us</h3>
                    </div>
                    <form onSubmit={formik.handleSubmit} className='flex flex-wrap pt-3 md:pt-5'>
                        <div className="w-full mt-3 sm:mt-0">
                            <div className="flex flex-wrap -mx-3 md:-mx-5 -my-5">
                                <div className="relative w-full sm:w-1/2 p-3 md:p-5">
                                    <Input input_name="firstNamedssd" value={formik.values.firstName} onChange={(e) => {
                                        const alphabetOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, '');
                                        setInputValue("firstName", alphabetOnlyValue)
                                    }} input_box="w-full" input_class="bg-transparent" label_class="text-white" label_name="First Name" placeholder="Enter your first name" input_type="text" />
                                    {formik.errors && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.firstName}</span>}
                                </div>
                                <div className="relative w-full sm:w-1/2 p-3 md:p-5">
                                    <Input input_name="lastName" value={formik.values.lastName} onChange={(e) => { const alphabetOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, ''); setInputValue("lastName", alphabetOnlyValue) }} input_box="w-full" input_class="bg-transparent" label_class="text-white" label_name="Last Name" placeholder="Enter your last name" input_type="text" />
                                    {formik.errors && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.lastName}</span>}

                                </div>
                                <div className="relative w-full sm:w-1/2 p-2 xsm:p-3 md:p-5">
                                    <Input input_name="email" value={formik.values.email} onChange={(e) => setInputValue("email", e.target.value)} input_box="w-full" input_class="bg-transparent" label_class="text-white" label_name="Email" placeholder="Enter your email address" input_type="email" />
                                    {(error.email || formik.errors) && <span className='absolute text-[10px] xsm:text-xs text-red-500 xsm:pt-1'>{formik.errors.email || error.email}</span>}
                                </div>

                                <div className="relative w-full sm:w-1/2 p-2 xsm:p-3 md:p-5">
                                    <label className={`block text-base leading-5 font-medium text-white`}>Phone Number</label>
                                    <PhoneInput
                                        international
                                        defaultCountry="IN"
                                        value={formik.values.phoneNumber}
                                        onChange={(phoneNumber) => setInputValue("phoneNumber", phoneNumber)}
                                    />
                                    {(error.phoneNumber || formik.errors) && <span className='absolute text-[10px] xsm:text-xs text-red-500 xsm:pt-1'>{formik.errors.phoneNumber || error.phoneNumber}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="w-full mt-7">
                            <Textarea textarea_name="message" value={formik.values.message} onChange={(e) => setInputValue("message", e.target.value)} textarea_box="w-full" label_name="Message" label_class="text-white" placeholder="Write your message.." />
                            {formik.errors && <span className='absolute text-[10px] xsm:text-xs text-red-500 xsm:pt-1'>{formik.errors.message}</span>}
                        </div>
                        <div className="w-full flex justify-between items-center pt-7 md:pt-[44px]">
                            <button type="submit" className="cursor-pointer text-sm md:text-xl bg-white text-enthusia-primary rounded-full font-medium px-[30px] py-1.5 xsm:py-2">Submit</button>
                            {successFull && <span className='text-base font-semibold text-green-800 pt-1'>{successFull}</span>}
                            {error && <span className='text-base font-semibold text-red-500 pt-1'>{error}</span>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default ContactFrom;
