import React from 'react'
import ContactWhiteFrom from './ContactWhiteFrom'
import Consultation from '../../Assets/svg/consultation.svg'

function ConsultationFrom({ main_title, paragraph_1, paragraph_class_2, paragraph_2 }) {
    return (
        <div className="contact-border w-full h-full rounded-md sm:rounded-2xl md:rounded-[30px]">
            <div className="flex flex-wrap items-center px-3 sm:px-5 md:px-[50px] pt-3 sm:pt-5 md:pt-[50px]">
                <div className="w-full lg:w-1/4 xl:px-[50px]">
                    <img src={Consultation} alt="Consultation" className='lg:w-auto h-[200px] lg:h-auto object-contain pointer-events-none mx-auto' loading='lazy' />
                </div>
                <div className="full lg:w-3/4 md:pl-[40px] pt-5 md:pt-0">
                    <h3 className="leading-[28px] md:leading-[34px] 2xl:leading-10 font-medium text-enthusia-primary">{main_title}</h3>
                    <p className="leading-7 font-normal text-enthusia-text text-justify pt-3">{paragraph_1}</p>
                    <p className={`leading-7 font-normal text-enthusia-text text-justify pt-3 ${paragraph_class_2}`}>{paragraph_2}</p>
                </div>
                <ContactWhiteFrom />
            </div>
        </div>
    )
}

export default ConsultationFrom