import React from 'react'
import { Link } from 'react-router-dom';
import data from '../../static/PolicyAndConditions.json'
import HeadingTitle from '../../Utility/HeadingTitle';

const CopyRight = () => {
    return (
        <main className="wrapper">
            {/* Privacy Policy */}
            <div className="w-full mt-[60px]">
                <HeadingTitle title_name={data.CopyRight.heading} heading_class="order-1" heading_main="!cursor-auto" line_class="bg-gradient-to-r order-2 sm:ml-5" />
            </div>
            <div className="pt-[50px]">
                <h5 className='font-semibold'>Effective Date : {data.CopyRight.EffectiveDate}</h5>
                <p className='pt-5'>{data.CopyRight.Description}</p>
            </div>
            <div className="pt-[50px] lg:pt-[80px]">
                {data.CopyRight.Points.map((data, mainIndex) => (
                    <div key={data.id} className="pb-5 md:pb-10">
                        <h3 className='font-bold'>{data.id}. {data.heading}</h3>
                        {data.Description && <p className='pt-3 md:pl-9'>{data.Description}</p>}
                        {data.Description_2 && <p className='pt-3 md:pl-9'>{data.Description_2}</p>}
                        {data.subPoint && (data.subPoint).map((data, i) => (
                            <div key={data.id} className='pt-8 md:pl-9'>
                                <h4 className='text-2xl font-semibold'><span className='pr-3'>{mainIndex + 1}.{data.id}</span>{data.title}</h4>
                                <p className='pt-3 md:pt-0'>{data.Description}</p>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <React.Fragment>
                <p>{data.CopyRight.contactInfo.Description}<Link to={"mailto:Info@enthusiasoftech.com"} className='font-semibold'>Info@enthusiasoftech.com</Link></p>
                <p className='pt-2'>{data.CopyRight.contactInfo.Description_2}</p>
            </React.Fragment>
        </main>
    )
}

export default CopyRight