import React from 'react'
import { Link } from 'react-router-dom'
import Close_Icon from '../../Assets/svg/close_icon.svg';

const CookiePolicy = ({ data, handleClose }) => {
    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50 overflow-y-auto pt-10 pb-20 xl:py-5 2xl:py-10">
            <div className="flex items-center justify-center max-w-[800px] w-full mx-auto h-full animation-slideUp overflow-y-auto">
                <div className="relative w-full bg-enthusia-primary text-white rounded-[20px] h-[95.5%] px-5 md:px-8 pb-5 md:pb-8 overflow-y-auto m-3 sm:m-0">
                    <div className="sticky top-0 flex items-center justify-between h-[50px] bg-enthusia-primary py-3 md:py-0">
                        <h3 className="leading-[28px] xl:text-4xl md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] font-semibold text-[#fff] capitalize">
                            {data.CookiePolicy.heading}
                        </h3>
                        <img onClick={() => handleClose(false)} src={Close_Icon} alt="close_icon" className="md:-m-3 cursor-pointer" />
                    </div>
                    <div className="pt-[30px] md:pt-[50px]">
                        <h5 className='font-semibold'>Effective Date : {data.CookiePolicy.EffectiveDate}</h5>
                        <p className='pt-5'>{data.CookiePolicy.Description}</p>
                    </div>
                    <div className="pt-[30px] md:pt-[50px]">
                        {data.CookiePolicy.Points.map((data, mainIndex) => (
                            <div key={data.id} className="pb-8 md:pb-10">
                                <h3 className='font-bold'>{data.id}. {data.heading}</h3>
                                {data.Description && <p className='pt-3 md:pl-9'>{data.Description}</p>}
                                {data.subPoint && (data.subPoint).map((data, i) => (
                                    <div key={data.id} className='pt-5 md:pt-8 md:pl-9'>
                                        <h4 className='text-base md:text-2xl font-semibold'><span className='pr-3'>{mainIndex + 1}.{data.id}</span>{data.title}</h4>
                                        <p className='pt-3 md:pt-0'>{data.Description}</p>
                                    </div>
                                ))}
                                {data.subSubPoint && (data.subSubPoint).map((data, i) => (
                                    <div key={data.id} className='pt-2 md:pl-9'>
                                        <p className={`text-lg ${i === 0 ? "font-semibold" : ""}`}>{data.subTitle}</p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <div className="pt-[20px]">
                        <p>{data.PrivacyPolicy.contactInfo.Description}</p>
                        <span className='block text-xl font-semibold text-[#fff] py-3'>{data.PrivacyPolicy.contactInfo.Title}</span>
                        <div className="">
                            <Link to={"mailto:Info@enthusiasoftech.com"} className="inline-block text-[#fff] text-lg space-x-3 ">
                                <span className='font-semibold inline-block w-[65px] sm:w-[80px]'>Email</span>:
                                <span className='text-base md:text-lg'>{data.PrivacyPolicy.contactInfo.Email}</span>
                            </Link>
                        </div>
                        <div className="">
                            <Link to={"tel:+918200550680"} className="inline-block text-[#fff] text-lg space-x-3 pt-3">
                                <span className='font-semibold inline-block w-[65px] sm:w-[80px]'>Mobile</span>:
                                <span className='text-base md:text-lg'>{data.PrivacyPolicy.contactInfo.PhoneNumber}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy