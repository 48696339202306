import React from "react";
import customData from '../../static/SubServices.json'
import { imageUrl } from '../../Api/baseUrl';

import IndustrySectorsBox from "../../Utility/IndustrySectorsBox";

import Healthcare from "../../Assets/svg/healthcare.svg";
import RealEstateIcon from "../../Assets/svg/real_estate_icon.svg";
import manufacturingIcon from "../../Assets/svg/manufacturing_icon.svg";
import InsuranceIcon from "../../Assets/svg/insurance_icon.svg";
import LifeScienceWellnessIcon from "../../Assets/svg/life_science_wellness_icon.svg";
import EducationIcon from "../../Assets/svg/education_icon.svg";

import LogisticsDistributionIcon from "../../Assets/svg/logistics_distribution_icon.svg";
import FinancialIcon from "../../Assets/svg/financial_icon.svg";
import TravelHospitalityIcon from "../../Assets/svg/travel_hospitality_icon.svg";
import MediaInfoServicesIcon from '../../Assets/svg/media_Info_services_icon.svg'
import MediaEntertainmentIcon from "../../Assets/svg/media_entertainment_icon.svg";
import WasteMenagementIcon from "../../Assets/svg/waste_menagement_icon.svg";
import RetailAndEcommerce from "../../Assets/svg/retail_and_ecommerce.svg";
import EnergyAndUtilities from "../../Assets/svg/energy_and_utilities.svg";

const IndustriesImages = [
  Healthcare,
  RealEstateIcon,
  manufacturingIcon,
  InsuranceIcon,
  LifeScienceWellnessIcon,
  EducationIcon,
  RetailAndEcommerce,
  MediaEntertainmentIcon,
  LogisticsDistributionIcon,
  FinancialIcon,
  TravelHospitalityIcon,
  MediaInfoServicesIcon,
  EnergyAndUtilities,
  WasteMenagementIcon

]

function IndustriesWeServe({ industries_class }) {
  const data = customData.Industries;
  const isHDImages = false;

  return (
    <div className={`flex flex-wrap md:px-0 ${industries_class}`}>
      {(data.IndustriesList.listOfIndustries).map((data, index) => (
        <IndustrySectorsBox
          key={data.id}
          industry_sectors_class="w-full md:w-1/2 pb-4 md:py-4 md:p-[15px]"
          src={isHDImages ? (imageUrl + data.src) : IndustriesImages.map((data) => (data))[index]}
          alt={data.title}
          industry_sectors_title={data.title}
          paragraph_title="Common Software Requirements:"
          industry_sectors_paragraph={data.CommonSoftwareRequirements}
          paragraph_title_2="How We Deliver:"
          industry_sectors_paragraph_2={data.HowWeDeliver}
        />
      ))}
    </div>
  );
}

export default IndustriesWeServe;
