import React from 'react'

function CustomSaasServicesBox({ id, onClick, custom_class, custom_box_class, custom_services_title, custom_services_paragraph }) {
    return (
        <div id={id} onClick={onClick} className={`group anim ${custom_box_class}`}>
            <div className={`customs-services-hover rounded-2xl md:rounded-[24px] ${custom_class}`}>
                <h3 className="leading-[28px] md:leading-[34px] 2xl:leading-[36px] text-enthusia-primary font-semibold anim">{custom_services_title}</h3>
                <p className="text-enthusia-text text-justify font-normal anim pt-5">{custom_services_paragraph}</p>
            </div>
        </div>
    )
}

export default CustomSaasServicesBox