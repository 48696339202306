import React from 'react'
import ContactWhiteFrom from '../Others/ContactWhiteFrom'
import Close_Icon from '../../Assets/svg/close_icon_normal.svg'

function ProcessPopUpBox({ handleClose, processStep, processStepNames, smallPopupClose, setProcessStep }) {
    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.2)] flex backdrop-blur-[10px] z-50">
            <div className="fixed items-center justify-center max-w-[760px] w-full px-4 mx-auto inset-x-0 animation-slideUp h-full max-h-[90%] my-5">
                <div className="relative w-full contact-border rounded-2xl pb-4 overflow-y-auto h-full">
                    <div className="sticky top-0 bg-[rgba(231,236,238,1)]  pt-4 pb-1 px-4 z-20">
                        <div className="w-full flex justify-between">
                            <span className='text-xl font-medium text-enthusia-primary'>{processStep}. {processStepNames[processStep]}</span>
                            <img onClick={() => { handleClose(false); smallPopupClose(false); setProcessStep(1) }} src={Close_Icon} alt="close_icon" className='w-auto h-auto object-contain cursor-pointer' />
                        </div>
                        <span className='block w-full h-[3px] bg-gradient-to-r from-[rgba(255,255,255,0.1)] via-[#134046] to-[rgba(255,255,255,0.1)] z-10 mt-3'></span>
                    </div>
                    <div className="pt-5 px-4">
                        {/* Process Step 1 */}
                        {processStep === 1 && <ContactWhiteFrom contact_from_className="!m-0" />}
                        {/* Process Step 2 */}
                        {processStep === 2 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">At the ideation phase of software development, the focus is on brainstorming ideas for the project. This is where the initial idea is formed and refined to lay the foundation for the development process.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Clear vision</span>
                                    <p className="process-content-heading">It allows you to have a clear and unambiguous project vision, which is essential for successful development.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Cost-effective</span>
                                    <p className="process-content-heading">Early ideas help identify potential problems, thereby reducing costly rework later in the project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Innovation</span>
                                    <p className="process-content-heading">The ideation phase encourages creative thinking and innovative solutions.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Risk mitigation</span>
                                    <p className="process-content-heading">Identifying potential risks and challenges during the ideation phase helps proactively mitigate them.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Alignment</span>
                                    <p className="process-content-heading">It facilitates alignment with business goals and user needs, thereby improving the relevance of the final product.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Faster development</span>
                                    <p className="process-content-heading">Well-thought-out ideas can lead to faster and smoother development phases.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Customer Satisfaction</span>
                                    <p className="process-content-heading">Customers are more likely to be satisfied when the final product matches their original vision.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 3 */}
                        {processStep === 3 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">This documentation sets the project's direction, aligns stakeholders, and minimizes misunderstandings, ensuring a smooth and efficient development process. It's crucial for project success.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Clarity and alignment</span>
                                    <p className="process-content-heading">Inception document ensures that all stakeholders have a clear and common understanding of project goals, requirements and expectations, minimizing misunderstandings and conflicts.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Scope definition</span>
                                    <p className="process-content-heading">It helps define the project's scope, describes what is included and what is not, helps prevent scope creep, and keeps the project on track.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Risk Identification</span>
                                    <p className="process-content-heading">Documentation can highlight potential risks and challenges, allowing the team to prepare risk mitigation strategies.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Stakeholder engagement</span>
                                    <p className="process-content-heading">Involving stakeholders in creating launch documents will promote their participation and ensure that their needs and interests are taken into account come right from the start.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Milestone Planning</span>
                                    <p className="process-content-heading">It provides a project roadmap with key milestones, deadlines, and deadlines, making it easier to track progress and manage your time more effectively.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Communication Framework</span>
                                    <p className="process-content-heading">It establishes a communication framework that ensures that team members, customers and stakeholders are aware of their roles and responsibilities.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Cost Control</span>
                                    <p className="process-content-heading">Having a well-documented plan can avoid costly changes and rework later in the project by reducing misunderstandings and scope changes.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Customer Satisfaction</span>
                                    <p className="process-content-heading">Creating kick-off documents with customer involvement leads to a sense of ownership, increased customer satisfaction, and buy-in throughout the project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Legal and Compliance</span>
                                    <p className="process-content-heading">It can help meet legal and compliance requirements, ensuring that the project meets applicable regulations and standards.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Conflict Resolution</span>
                                    <p className="process-content-heading">The document can serve as a reference point for resolving disputes or disagreements during the development process.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Change Management</span>
                                    <p className="process-content-heading">It provides a framework for managing changes to a project, helping to assess the impact of changes on schedule and budget.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Consistency and standardization</span>
                                    <p className="process-content-heading"> It encourages a consistent approach to development, leading to higher quality and more reliable results.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Knowledge transfer</span>
                                    <p className="process-content-heading">A well-documented initiation can facilitate the transfer of knowledge between team members, ensuring that knowledge is not bound tied to specific individuals.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 4 */}
                        {processStep === 4 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Work elicitation identifies and defines project requirements, ensuring that the development team knows what is expected and can deliver a successful end product. It's essential for project clarity and alignment.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Scope Clarity</span>
                                    <p className="process-content-heading">It helps define the project scope and its specific components, allowing for a comprehensive assessment of the resources and efforts required.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Resource Allocation</span>
                                    <p className="process-content-heading">By identifying the tasks and activities involved, work elicitation assists in efficient resource allocation, helping to determine labour, technology, and material costs.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Accurate Cost Projections</span>
                                    <p className="process-content-heading">Through a detailed understanding of work requirements, it provides the foundation for more precise cost projections, reducing the likelihood of underestimating or overestimating expenses.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Efficient Development</span>
                                    <p className="process-content-heading">It streamlines the development process by providing a well-defined roadmap, helping projects stay on schedule and within budget.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 5 */}
                        {processStep === 5 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Contract transparency is vital to establish trust, prevent disputes, and ensure both parties have a clear understanding of project expectations and responsibilities. It fosters a cooperative and successful working relationship.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Clear Expectations</span>
                                    <p className="process-content-heading">Transparent contracts provide clients with a clear understanding of what to expect from the project, reducing misunderstandings and potential conflicts.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Mutual Trust</span>
                                    <p className="process-content-heading">Transparency in contracts builds trust between the client and the service provider, fostering a more collaborative and positive working relationship.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Scope Definition</span>
                                    <p className="process-content-heading">Clearly outlined contracts help define the project's scope, reducing the likelihood of scope creep and ensuring that both parties are on the same page regarding project goals.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Cost Control</span>
                                    <p className="process-content-heading">Transparent contracts allow clients to have a detailed breakdown of costs and expenses, which enables better budget management and cost control.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Risk Mitigation</span>
                                    <p className="process-content-heading"> By specifying roles, responsibilities, and expectations, transparent contracts help identify and mitigate potential risks, reducing project uncertainties.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Change Management</span>
                                    <p className="process-content-heading">Transparent contracts include procedures for handling changes and deviations from the original plan, making it easier to manage modifications without disrupting the project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Legal Compliance</span>
                                    <p className="process-content-heading">Transparent contracts ensure that the project complies with legal and regulatory requirements, reducing the risk of legal disputes or complications.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Accountability</span>
                                    <p className="process-content-heading">Clients can hold the service provider accountable for meeting their obligations and delivering the agreed-upon results, which is essential for project success.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Client Involvement</span>
                                    <p className="process-content-heading">Transparent contracts may specify the client's role and responsibilities in the project, ensuring their active involvement and contribution.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Documentation of Agreements</span>
                                    <p className="process-content-heading">Contracts serve as a written record of all agreements and commitments, providing a reference point for both parties.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Milestone and Deadline Setting</span>
                                    <p className="process-content-heading">Contracts can include milestones and deadlines, helping both parties track progress and ensuring that the project stays on schedule.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Dispute Resolution</span>
                                    <p className="process-content-heading">Clearly defined dispute resolution mechanisms in the contract can facilitate the resolution of conflicts without resorting to legal action.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Client Satisfaction</span>
                                    <p className="process-content-heading">Transparent contracts are more likely to result in client satisfaction as they demonstrate the service provider's commitment to openness and professionalism.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 6 */}
                        {processStep === 6 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Planning & Strategize Documentation is essential to outline project objectives and strategies. It ensures project alignment and offers detailed timelines, resource allocation, and risk mitigation, enhancing project management and successful development.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Efficient Workflow</span>
                                    <p className="process-content-heading">Well-planned strategies streamline the development process, making it more efficient and reducing delays.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Smooth Transition</span>
                                    <p className="process-content-heading">Detailed documentation eases the handover process by providing a clear project history and guidelines, ensuring a seamless transition to the next phase or team.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Risk Management</span>
                                    <p className="process-content-heading">Strategize documentation includes strategies for risk mitigation, helping to identify potential challenges and develop plans to address them effectively.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Communication Framework</span>
                                    <p className="process-content-heading">Planning establishes a communication framework, specifying reporting structures, meeting schedules, and channels, facilitating effective communication among team members and stakeholders.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Change Management</span>
                                    <p className="process-content-heading">It includes procedures for handling changes to the project, making it easier to evaluate the impact of changes on the schedule and budget.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Client Engagement</span>
                                    <p className="process-content-heading">Involving clients in the planning process ensures their active participation, aligning project strategies with their goals and preferences.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Change Management</span>
                                    <p className="process-content-heading">It includes procedures for handling changes to the project, making it easier to evaluate the impact of changes on the schedule and budget.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 7 */}
                        {processStep === 7 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Requirement walkthroughs are essential for teams to gain a comprehensive understanding of project requirements, fostering clarity, alignment, and reducing misinterpretations. It enhances collaboration and helps ensure that everyone is on the same page before proceeding with the project.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Ensure common understanding</span>
                                    <p className="process-content-heading">They help ensure that all team members have a common and clear understanding of the project requirements, thereby minimizing misunderstandings and errors.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Promote Collaboration</span>
                                    <p className="process-content-heading">They promote collaboration and enable team members to provide valuable information and feedback, thereby improving the overall quality of requirements and projects.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Identify problems early</span>
                                    <p className="process-content-heading">By involving all team members, potential problems or challenges can be identified and resolved at an early stage, thereby avoiding costly rework later in the project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Improved Accountability</span>
                                    <p className="process-content-heading">Team members become more accountable for their roles and responsibilities when they actively participate in reviewing requirements step by step, which can lead to project results, more successful.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Foster Ownership</span>
                                    <p className="process-content-heading">Participating in the step-by-step review of requirements encourages a sense of ownership and commitment to the project, which can boost motivation and productivity.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 8 */}
                        {processStep === 8 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Design and prototyping are essential before development to visualize the final product, validate concepts, and uncover potential issues early, ensuring a more efficient and successful development process. It helps reduce costly rework and ensures that the final product aligns with client expectations.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Visual clarity</span>
                                    <p className="process-content-heading">Design and prototyping provide a clear visual representation of the project, helping stakeholders understand how the final product will look and function.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Early identification of problems</span>
                                    <p className="process-content-heading">It enables early detection and resolution of design flaws or usability issues, thereby reducing the likelihood of costly changes occurring during development.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">User Feedback</span>
                                    <p className="process-content-heading">Prototypes can be used to gather user feedback, ensuring that the final product meets user expectations and needs.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Efficient Development</span>
                                    <p className="process-content-heading">Prototypes serve as a blueprint, guiding developers and reducing ambiguity, leading to a more efficient development process.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Cost reduction</span>
                                    <p className="process-content-heading">Early problem identification and design validation saves costs by avoiding rework or significant changes during development.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Risk mitigation</span>
                                    <p className="process-content-heading">It helps reduce risks related to usability, functionality and design, thereby improving project success.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Customer involvement</span>
                                    <p className="process-content-heading">Involving customers in the design and prototyping stages ensures their active participation and alignment with the direction of the project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Matching expectations</span>
                                    <p className="process-content-heading">Design and prototyping help ensure that the final product matches customer and stakeholder expectations, minimizing potential misunderstandings.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Quality Assurance</span>
                                    <p className="process-content-heading">Early design validation and prototyping improves the quality of the final product by proactively addressing design and usability issues.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Save time</span>
                                    <p className="process-content-heading">Solving design problems and gathering user feedback early can speed development and project completion.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Flexibility</span>
                                    <p className="process-content-heading">Prototyping allows testing and changes to project design without committing to full development, thus facilitating agile development methods.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Customer Confidence</span>
                                    <p className="process-content-heading">Involving customers in the design process increases their confidence in the success of the project and their investment.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 9 */}
                        {processStep === 9 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">Follow Coding Standards: Adhere to coding standards and guidelines set by your team or organization. Consistent code style makes it easier for multiple developers to work on the project and maintain the code.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Modularize Code</span>
                                    <p className="process-content-heading">Divide your code into smaller, manageable modules or functions. This promotes reusability, readability, and simplifies debugging.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Use Descriptive Names</span>
                                    <p className="process-content-heading">Choose meaningful and descriptive variable and function names that convey their purpose and make the code self-explanatory.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Comment Code</span>
                                    <p className="process-content-heading">Add comments to explain complex or non-obvious sections of code. Documentation helps fellow developers understand your code and its purpose.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Version Control</span>
                                    <p className="process-content-heading">Use a version control system (e.g., Git) to track changes, collaborate with team members, and maintain a history of your codebase.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Test-Driven Development (TDD)</span>
                                    <p className="process-content-heading">Consider practicing TDD, where you write tests before writing code. This ensures your code meets requirements and helps catch bugs early.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Error Handling</span>
                                    <p className="process-content-heading">Implement robust error handling to gracefully manage exceptions and unexpected situations. This enhances the stability of the software.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Code Reviews</span>
                                    <p className="process-content-heading">Participate in or conduct code reviews to get feedback from peers. Code reviews help identify issues, improve code quality, and share knowledge.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Optimize Performance</span>
                                    <p className="process-content-heading">Identify and optimize performance bottlenecks in your code to ensure efficient execution and responsiveness.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Security Considerations</span>
                                    <p className="process-content-heading">Be mindful of security best practices and avoid vulnerabilities like SQL injection, cross-site scripting (XSS), and others.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Keep It DRY (Don't Repeat Yourself)</span>
                                    <p className="process-content-heading">Avoid code duplication by encapsulating common functionality into reusable functions or classes.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Testing</span>
                                    <p className="process-content-heading">Thoroughly test your code, including unit testing and integration testing, to ensure it functions as expected and doesn't introduce regressions.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Documentation</span>
                                    <p className="process-content-heading">Maintain up-to-date documentation for your code, API, and usage instructions. This documentation is valuable for developers who interact with your code.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Continuous Integration (CI)</span>
                                    <p className="process-content-heading">Integrate your code into the CI/CD pipeline to automate testing and deployment processes.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Refactoring</span>
                                    <p className="process-content-heading">Periodically refactor your code to improve its structure, maintainability, and readability. Refactoring should be done cautiously and with a clear purpose.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Clean Code</span>
                                    <p className="process-content-heading">Strive for clean, readable, and maintainable code. Follow principles outlined in "Clean Code" by Robert C. Martin.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">SEO Best Practices</span>
                                    <p className="process-content-heading">Implement SEO best practices such as using semantic HTML, optimizing images, creating user-friendly URLs, and ensuring responsive design for mobile-friendliness.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Structured Data</span>
                                    <p className="process-content-heading">Incorporate structured data (e.g., schema markup) to help search engines understand and display content better in search results.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Optimized Content</span>
                                    <p className="process-content-heading">Ensure that content is well-structured, relevant, and optimized for keywords and user intent.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Page Speed</span>
                                    <p className="process-content-heading">Optimize page load times by minimizing HTTP requests, leveraging browser caching, and using content delivery networks (CDNs).</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Security by Design</span>
                                    <p className="process-content-heading">Integrate security considerations from the outset, following security by design principles to identify and mitigate vulnerabilities during coding.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Data Encryption</span>
                                    <p className="process-content-heading">Use encryption for data in transit and at rest to protect sensitive information from unauthorized access.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Authentication and Authorization</span>
                                    <p className="process-content-heading">Implement strong authentication and authorization mechanisms to ensure that only authorized users can access specific features and data.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Input Validation</span>
                                    <p className="process-content-heading">Perform input validation to prevent common security vulnerabilities such as SQL injection, cross-site scripting (XSS), and cross-site request forgery (CSRF).</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Error Handling</span>
                                    <p className="process-content-heading">Handle errors gracefully, avoiding the exposure of sensitive information while providing users with informative error messages.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Patch Management</span>
                                    <p className="process-content-heading">Keep libraries, frameworks, and dependencies up to date to address known security vulnerabilities.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Access Control</span>
                                    <p className="process-content-heading">Enforce proper access controls to limit user permissions and reduce the risk of unauthorized access to sensitive areas of the application.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">HTTPS</span>
                                    <p className="process-content-heading">Use HTTPS to encrypt data in transit, ensuring that sensitive information is protected during communication.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Backup and Recovery</span>
                                    <p className="process-content-heading">Regularly back up data and create disaster recovery plans to safeguard against data loss and service disruptions.</p>
                                </div>
                            </div>
                        </div>}
                        {/* Process Step 10 */}
                        {processStep === 10 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">At Enthusia Softech, we prioritize the highest standards of quality throughout the Software Development Life Cycle (SDLC). Our robust Quality Assurance (QA) processes are seamlessly integrated into every phase, from ideation to deployment.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Requirements Validation</span>
                                    <p className="process-content-heading">QA begins with a meticulous review of project requirements, ensuring clarity and alignment with client expectations.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Test-Driven Development</span>
                                    <p className="process-content-heading">We embrace Test-Driven Development (TDD), writing tests before code to catch issues early, leading to more resilient and error-free software.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Comprehensive Testing</span>
                                    <p className="process-content-heading">Rigorous testing, including unit, integration, and system testing, guarantees that each component functions as intended and contributes to a cohesive whole.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Automation Excellence</span>
                                    <p className="process-content-heading">Leveraging automation tools, we streamline repetitive testing processes, allowing for quicker iterations and more comprehensive test coverage.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">User Experience Testing</span>
                                    <p className="process-content-heading">Our QA extends beyond functionality to encompass user experience, ensuring that the software meets usability and accessibility standards.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Performance Optimization</span>
                                    <p className="process-content-heading">We conduct performance testing to identify and address bottlenecks, ensuring your software delivers optimal speed and responsiveness.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Security Assurance</span>
                                    <p className="process-content-heading">Robust security testing is ingrained in our processes, safeguarding your application against potential vulnerabilities and cyber threats.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Regression Testing</span>
                                    <p className="process-content-heading">Changes and updates are subjected to thorough regression testing to guarantee that existing functionalities remain unaffected.</p>
                                </div>
                                <p className="font-normal text-enthusia-primary">Impeccable software that not only meets but exceeds expectations. Our commitment to quality assurance translates into reliable, secure, and high-performance solutions that empower your business for success. Choose Enthusia Softech for a software development partner that prioritizes quality at every turn.</p>
                            </div>
                        </div>}
                        {/* Process Step 11 */}
                        {processStep === 11 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">At Enthusia Softech, we recognize the paramount importance of security in the digital landscape. Our dedicated Security Checks are seamlessly woven into every phase of the Software Development Life Cycle (SDLC), ensuring that your digital assets remain impervious to threats. </p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Threat Modeling</span>
                                    <p className="process-content-heading">Our security protocols commence with meticulous threat modeling, identifying potential risks and vulnerabilities specific to your project.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Secure Coding Practices</span>
                                    <p className="process-content-heading">Developers adhere to secure coding practices, mitigating common risks such as SQL injection, cross-site scripting (XSS), and other vulnerabilities.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Code Reviews</span>
                                    <p className="process-content-heading">Security is a collaborative effort. Our regular code reviews include a focus on security, ensuring that potential issues are identified and addressed before they become threats.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Penetration Testing</span>
                                    <p className="process-content-heading">Rigorous penetration testing is conducted to simulate real-world attacks, providing insights into potential weaknesses and vulnerabilities.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Continuous Monitoring</span>
                                    <p className="process-content-heading">Our commitment to security doesn't end with deployment. We implement continuous monitoring to swiftly detect and respond to any anomalies or security breaches.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Data Encryption</span>
                                    <p className="process-content-heading">Sensitive data is safeguarded through robust encryption measures, both in transit and at rest, ensuring confidentiality and compliance with data protection standards.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Authentication and Authorization</span>
                                    <p className="process-content-heading">Implementing robust authentication and authorization mechanisms ensures that only authorized users have access to specific functionalities and data.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Regular Security Audits</span>
                                    <p className="process-content-heading">Periodic security audits and assessments are conducted to proactively identify and remediate potential security gaps.</p>
                                </div>
                                <p className="font-normal text-enthusia-primary">Rest easy knowing that your digital infrastructure is fortified against evolving cyber threats. At Enthusia Softech, we prioritize the security of your software, providing you with the confidence to navigate the digital landscape without compromise. Choose Enthusia Softech for a software development partner committed to securing your digital assets at every phase of development.</p>
                            </div>
                        </div>}
                        {/* Process Step 12 */}
                        {processStep === 12 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">At Enthusia Softech, the journey from code to deployment is a well-choreographed symphony. Our deployment practices are meticulously crafted to ensure a smooth transition from development to the live environment, bringing your vision to life with precision.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Continuous Integration</span>
                                    <p className="process-content-heading">Our deployment process begins with continuous integration, where code changes are automatically integrated, tested, and verified to maintain a stable and reliable codebase.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Environment Configuration</span>
                                    <p className="process-content-heading">Rigorous environment configuration ensures that the deployment environment mirrors the production environment, minimizing the risk of issues during the deployment phase.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Automated Deployment Pipelines</span>
                                    <p className="process-content-heading">We leverage automated deployment pipelines to streamline the deployment process, reducing manual interventions and ensuring consistency across environments.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Rollback Mechanism</span>
                                    <p className="process-content-heading">Prepared for every scenario, our deployments include a robust rollback mechanism, allowing for swift reversals in case unforeseen issues arise post-deployment.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Performance Monitoring</span>
                                    <p className="process-content-heading">Post-deployment, our team monitors system performance to promptly address any performance-related issues and optimize the software for peak efficiency.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">User Acceptance Testing (UAT)</span>
                                    <p className="process-content-heading">Prior to full deployment, we conduct thorough User Acceptance Testing (UAT) to ensure that the software meets user expectations and functions as intended.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Zero-Downtime Deployments</span>
                                    <p className="process-content-heading">We prioritize user experience by implementing zero-downtime deployment strategies, ensuring uninterrupted service for your users during the deployment process.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Deployment Documentation</span>
                                    <p className="process-content-heading">Detailed deployment documentation is provided to facilitate a seamless handover and empower your team with the knowledge needed for future updates and maintenance.</p>
                                </div>
                                <p className="font-normal text-enthusia-primary">Experience the thrill of seeing your vision come to life as we execute a flawless deployment. At Enthusia Softech, we understand that deployment is not just a phase; it's the moment your software steps into the spotlight, ready to make an impact. Choose Enthusia Softech for a software development partner committed to delivering seamless deployments that elevate your digital presence.</p>
                            </div>
                        </div>}
                        {/* Process Step 13 */}
                        {processStep === 13 && <div className="relative">
                            <p className="font-normal text-enthusia-primary">At Enthusia Softech, our commitment to your project extends well beyond the deployment phase. Our Maintenance and Care practices are designed to nurture your software, ensuring its continued excellence, security, and relevance in the ever-evolving digital landscape.</p>
                            <div className="space-y-5 pt-5">
                                <div className="">
                                    <span className="process-dot-small-heading">Proactive Monitoring</span>
                                    <p className="process-content-heading">Our team employs proactive monitoring tools to track system performance, detect anomalies, and address potential issues before they impact the user experience.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Regular Updates</span>
                                    <p className="process-content-heading">We stay ahead of the curve with regular updates, including security patches, feature enhancements, and optimizations, keeping your software at the forefront of innovation.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">User Support</span>
                                    <p className="process-content-heading">Our support team is at your disposal, providing prompt assistance and troubleshooting to address user queries, concerns, and technical issues effectively.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Performance Tuning</span>
                                    <p className="process-content-heading">Periodic performance tuning ensures that your software continues to deliver optimal speed and responsiveness, adapting to growing user demands.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Security Audits</span>
                                    <p className="process-content-heading">Scheduled security audits are conducted to identify and address emerging threats, maintaining the robustness of your software's security architecture.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Documentation Updates</span>
                                    <p className="process-content-heading">Maintenance includes keeping documentation up to date, empowering your team with accurate and relevant information for ongoing management and future developments.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Scalability Planning</span>
                                    <p className="process-content-heading">As your user base grows, we plan for scalability, ensuring that your software infrastructure can seamlessly handle increased loads without compromising performance.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">End-User Training</span>
                                    <p className="process-content-heading">Should there be updates or new features, we provide end-user training to ensure your users can leverage the full potential of the software.</p>
                                </div>
                                <div className="">
                                    <span className="process-dot-small-heading">Outcome</span>
                                    <p className="process-content-heading">With Enthusia Softech, your software receives the attention it deserves, evolving with the digital landscape and consistently delivering value to your users. Our Maintenance and Care practices exemplify our dedication to your software's longevity and success. Choose Enthusia Softech for a software development partner committed to the ongoing excellence and care of your digital solutions.</p>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProcessPopUpBox