import React, { useState } from 'react'
import Data from '../../static/Home.json'

import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay } from 'swiper/modules';
import Tilt from 'react-parallax-tilt';
import SEO from '../Common/SEO'
import { imageUrl } from '../../Api/baseUrl';

import useMoveAndRotate from '../hook/useMoveAndRotate';
import useScrollAnimation from '../hook/useScrollAnimation';
import Button from '../../Utility/Button'
import HeadingTitle from '../../Utility/HeadingTitle'
import Hiring from '../../Utility/HiringHome'
import ConsultationFrom from '../Others/ConsultationFrom';
import Modal from '../Common/Modal'
import ContactFrom from '../Popup/ContactFrom'
import PdfPopUp from '../../Components/Popup/PdfPopUp'
import Share from '../Popup/Share'

import Home_bg from "../../Assets/Webp/home-bg.webp";
import BusinessProposalPdf from '../../Assets/Webp/Business_Proposal_pdf.webp'
import Future_Outlook_Background from "../../Assets/Webp/future_outlook_background.webp";
import Future_Outlook_Front from "../../Assets/Webp/future_outlook_front.webp";
import Organizational_Aim_Background from "../../Assets/Webp/organizational_aim_background.webp";
import Organizational_Aim_Front from "../../Assets/Webp/organizational_aim_front.webp";
import Logo_icon from "../../Assets/Webp/logo_icon.webp"
import Business_Proposal from "../../Assets/Webp/business_proposal_background.webp";
import Approach_Icon from "../../Assets/svg/approach_icon.svg";
import Future_Outlook_Icon from "../../Assets/svg/future_outlook_icon.svg";
import Organizational_Icon from "../../Assets/svg/organizational_icon.svg";
import Hiring_Model from "../../Assets/svg/hiring-model.svg"
import SaasDevelopment from "../../Assets/svg/saas_development_home_icon.svg"
import DigitalTransformation from "../../Assets/svg/digital_transformation_home_icon.svg"
import BoostingStartup from "../../Assets/svg/boosting_startup_home_icon.svg"

function Home() {
  const navigator = useNavigate();
  const [consultFrom, setConsultFrom] = useState(false);
  const [pdfView, setPdfView] = useState(false)
  const [sharePdf, setSharePdf] = useState(false)
  const [isMouseInsideHero, setIsMouseInsideHero] = useState(false);
  const [transformingIdeas, setTransformingIdeas] = useState(2);
  const sectionIds = ["ArrowAnimation", "FutureOutlookAnimation", "OrganizationalAim"];

  const logoStyle = useMoveAndRotate(isMouseInsideHero);
  const activeSection = useScrollAnimation(sectionIds);
  const isHDImages = false;

  const IdeasImagesList = [SaasDevelopment, BoostingStartup, DigitalTransformation]

  return (
    <React.Fragment>
      <SEO
        title={Data.SEO.title}
        description={Data.SEO.Description}
        canonicalLink={Data.SEO.CanonicalLink}
        keywords={Data.SEO.keywords}
      />
      {/* Hero Section */}
      <div onMouseEnter={() => setIsMouseInsideHero(true)} onMouseLeave={() => setIsMouseInsideHero(false)} className="relative px-3.5 md:px-5 xl:px-8 2xl:px-[68px] md:mb-[50px] lg:mb-[100px] overflow-hidden">
        <img src={Home_bg} alt="home_circle" className='absolute top-0 left-0 w-full h-full object-cover md:object-fill -z-20' loading='lazy' />
        {/* Enthsia Icon Logo */}
        <div className="absolute bottom-14 sm:bottom-10 left-2/3 opacity-80 z-10">
          <img
            src={Logo_icon}
            alt="enthusia_logo_icon"
            style={logoStyle}
            className='w-[100px] h-[100px] md:w-[250px] md:h-[250px] lg:w-[400px] lg:h-[400px] object-contain opacity-20'
          />
        </div>
        <div className='relative flex items-center w-full h-[500px] md:h-screen bg-[rgba(19, 64, 70, 0.3)] text-4xl font-bold z-10'>
          <div className="lg:absolute lg:left-[200px] max-w-[865px] w-full">
            <Swiper
              grabCursor={true}
              spaceBetween={30}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              {(Data.slider).map((data, i) => (
                <SwiperSlide key={i}>
                  <h1 className='max-w-[600px] w-full text-enthusia-primary'>{data.heading}</h1>
                  <p className='max-w-[750px] 2xl:max-w-full w-full text-enthusia-text font-normal py-3 md:py-[18px]'>{data.paragraph}</p>
                  <Button onClick={() => setConsultFrom(true)} button_name={data.buttonName} button_class="mt-5 lg:mt-[26px]" img_class="hidden" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      {/* APPROACH , FUTURE OUTLOOK , ORGANIZATIONAL AIM Box*/}
      <main className="wrapper space-y-[36px] md:space-y-[50px] lg:space-y-[100px]">
        {/* APPROACH */}
        <div id="Approach" className='relative'>
          <HeadingTitle title_name="APPROACH" heading_class="order-1" heading_main="!cursor-auto" line_class="bg-gradient-to-r order-2 sm:ml-5" />
          <div className="relative flex flex-wrap justify-between mt-6 md:mt-[50px]">
            <div className="w-full lg:w-1/2 h-auto">
              <div className="flex flex-col items-center justify-center w-full h-full rounded-tl-2xl rounded-tr-2xl md:rounded-tl-[30px] md:rounded-tr-[30px] lg:rounded-tr-none lg:rounded-l-[50px] overflow-hidden">
                <div className="relative w-full h-full anim">
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 anim">
                    <Tilt
                      glareEnable={true}
                      glareMaxOpacity={0.5}
                      glareColor="white"
                      perspective={2000}
                      scale={1}
                      transitionSpeed={2500}
                    >
                      <img
                        src={BusinessProposalPdf}
                        alt="Business Proposal"
                        className="border-[2px] border-enthusia-primary w-[180px] h-[250px] lg:w-[200px] xl:w-[220px] lg:h-[250px] xl:h-[300px] 2xl:w-full 2xl:h-full max-w-[264px] max-h-[375px] !z-30 cursor-pointer pointer-events-none anim"
                        loading="lazy"
                      />
                    </Tilt>
                    <div id='ArrowAnimation' className="">
                      {/* Desktop Arrow Animation */}
                      <svg className='hidden !z-20 lg:inline-block absolute -right-[98px] xl:-right-[128px] 2xl:-right-[165px] bottom-5 xl:bottom-10 w-[100px] xl:w-[130px] 2xl:w-[169px] h-[142px]' viewBox="0 0 169 142" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={`arrow-animation-smallshap ${activeSection === "ArrowAnimation" ? "active" : ""}`} d="M163.846 74.6969L167.05 53.7707C167.077 53.5917 166.871 53.4713 166.729 53.5829L149.865 66.7731" stroke="#134046" strokeWidth="2.5" strokeLinecap="round" />
                        <path className={`arrow-animation ${activeSection === "ArrowAnimation" ? "active" : ""}`} d="M4.49848 75.5003C48.4308 140.703 114.942 71.2109 94.3091 61.9578C90.2267 60.1269 84.8893 62.3045 83.6666 66.6084C77.9299 86.8029 135.105 121.759 165.51 55.8349" stroke="#134046" strokeWidth="2.5" strokeLinecap="round" />
                      </svg>
                      {/* Mobile Arrow Animation */}
                      <svg className='hidden !z-20 sm:inline-block lg:hidden absolute -right-[167px] bottom-6' width="168" height="58" viewBox="0 0 168 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={`arrow-animation-smallshap ${activeSection === "ArrowAnimation" ? "active" : ""}`} d="M160.846 34.3031L164.05 55.2293C164.077 55.4083 163.871 55.5287 163.729 55.4171L146.865 42.2269" stroke="#134046" strokeWidth="2.5" strokeLinecap="round" />
                        <path className={`arrow-animation ${activeSection === "ArrowAnimation" ? "active" : ""}`} d="M1.49848 33.4997C45.4308 -31.7025 111.942 37.7891 91.3091 47.0422C87.2267 48.8731 81.8893 46.6955 80.6666 42.3916C74.9299 22.1971 132.105 -12.7587 162.51 53.1651" stroke="#134046" strokeWidth="2.5" strokeLinecap="round" />
                      </svg>
                    </div>
                  </div>
                  <img src={Business_Proposal} alt="Business Proposal" className="w-full h-[350px] sm:h-[400px] lg:h-full lg:object-cover pointer-events-none" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2">
              <div className="flex flex-col items-start justify-center relative gradient-border-rightbox h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] md:rounded-br-[30px] lg:rounded-bl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] p-5 md:p-[30px] lg:px-[50px] 2xl:px-[100px]">
                <div className={`flex items-center justify-center w-10 md:w-[60px] lg:w-20 h-10 md:h-[60px] lg:h-20 rounded-full lg:p-4 gradient-border-2 absolute -top-6 md:-top-8 left-1/2 -translate-x-1/2 lg:right-1/2 lg:-translate-x-0 lg:top-10 lg:-left-10 z-10`}>
                  <img src={Approach_Icon} alt="Approach Icon" className="w-5 md:w-8 lg:w-full h-5 md:h-8 lg:h-full object-contain pointer-events-none" loading='lazy' />
                </div>
                <p className='text-enthusia-text capitalize leading-7 text-justify indent-16'>{Data.approach}</p>
              </div>
            </div>
          </div>
        </div>
        {/* FUTURE OUTLOOK */}
        <div id="FutureOutlook" className='relative'>
          <HeadingTitle title_name="FUTURE OUTLOOK" heading_main="!cursor-auto" heading_class="sm:order-2 order-1" line_class="text-right bg-gradient-to-r sm:bg-gradient-to-l sm:order-1 order-2 sm:mr-5" />
          <div className="relative flex flex-wrap justify-between mt-6 md:mt-[50px]">
            <div className="relative w-full lg:w-1/2 order-2 lg:order-1">
              <div className="flex flex-col items-center relative gradient-border-rightbox lg:gradient-border-leftbox justify-center w-full h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] lg:rounded-br-[0px] md:rounded-br-[30px] lg:rounded-tl-[50px] lg:rounded-bl-[50px] p-5 md:p-[30px] lg:px-[50px] 2xl:px-[100px]">
                <div className={`flex items-center justify-center w-10 md:w-[60px] lg:w-20 h-10 md:h-[60px] lg:h-20 rounded-full lg:p-4 gradient-border-2 absolute -top-6 md:-top-8 left-1/2 -translate-x-1/2 lg:left-auto lg:-translate-x-0 lg:top-10 lg:-right-10 z-10`}>
                  <img src={Future_Outlook_Icon} alt="Future Outlook icon" className="w-5 md:w-8 lg:w-full h-5 md:h-8 lg:h-full object-contain pointer-events-none" loading='lazy' />
                </div>
                <p className='text-enthusia-text capitalize leading-7 text-justify indent-16'>{Data.FutureOutlook}</p>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2 order-1 lg:order-2 h-auto overflow-hidden">
              <div className="flex flex-col items-start justify-center h-full rounded-tl-2xl rounded-tr-2xl md:rounded-bl-[30px] md:rounded-tr-[30px] lg:rounded-tl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] lg:rounded-bl-[0px] overflow-hidden">
                { /* <div className="relative w-full h-full anim">
                  <img src={Future_Outlook} alt="Future Outlook" className="w-full h-[350px] sm:h-[400px] lg:h-full object-cover" loading='lazy' />
                </div> */}
                <div className="relative w-full h-full anim">
                  <div id='FutureOutlookAnimation' className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 anim">
                    <Tilt
                      glareEnable={false}
                      glareMaxOpacity={0.5}
                      glareColor="white"
                      perspective={1000}
                      scale={1.02}
                      transitionSpeed={2500}
                    >
                      <img
                        src={Future_Outlook_Front}
                        alt="Future Outlook"
                        className={`rotating360 w-full h-full hover:drop-shadow-2xl anim ${activeSection === "FutureOutlookAnimation" ? "active" : ""}`}
                        loading="lazy"
                      />
                    </Tilt>
                  </div>
                  <img src={Future_Outlook_Background} alt="Future Outlook" className="w-full h-[350px] sm:h-[400px] lg:h-full lg:object-cover pointer-events-none" loading='lazy' />
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* ORGANIZATIONAL AIM Box */}
        <div id="OrganizationalAim" className='relative'>
          <HeadingTitle title_name="ORGANIZATIONAL AIM" heading_main="!cursor-auto" heading_class="order-1" line_class="bg-gradient-to-r order-2 sm:ml-5" />
          <div className="relative flex flex-wrap justify-between mt-6 md:mt-[50px]">
            <div className="w-full lg:w-1/2 h-auto">
              <div className="flex flex-col items-center justify-center w-full h-full rounded-tl-2xl rounded-tr-2xl md:rounded-tl-[30px] md:rounded-tr-[30px] lg:rounded-tr-none lg:rounded-l-[50px] overflow-hidden">
                <div className="relative w-full h-full anim">
                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 anim">
                    <Tilt
                      glareEnable={false}
                      glareMaxOpacity={0.5}
                      glareColor="white"
                      perspective={1000}
                      scale={1.02}
                      transitionSpeed={2500}
                    >
                      <img
                        src={Organizational_Aim_Front}
                        alt="Organizational Aim"
                        className="w-full h-full anim pointer-events-none"
                        loading="lazy"
                      />
                    </Tilt>
                  </div>
                  <img src={Organizational_Aim_Background} alt="Organizational Aim" className="w-full h-[350px] sm:h-[400px] lg:h-full lg:object-cover pointer-events-none" loading='lazy' />
                </div>
              </div>
            </div>
            <div className="relative w-full lg:w-1/2">
              <div className="flex flex-col items-start justify-center relative gradient-border-rightbox h-full rounded-bl-2xl rounded-br-2xl md:rounded-bl-[30px] md:rounded-br-[30px] lg:rounded-bl-[0px] lg:rounded-tr-[50px] lg:rounded-br-[50px] p-5 md:p-[30px] lg:px-[50px] 2xl:px-[100px]">
                <div className={`flex items-center justify-center w-10 md:w-[60px] lg:w-20 h-10 md:h-[60px] lg:h-20 rounded-full lg:p-4 gradient-border-2 absolute -top-6 md:-top-8 left-1/2 -translate-x-1/2 lg:right-1/2 lg:-translate-x-0 lg:top-10 lg:-left-10 z-10`}>
                  <img src={Organizational_Icon} alt="Organizational Icon" className="w-5 md:w-8 lg:w-full h-5 md:h-8 lg:h-full object-contain pointer-events-none" loading='lazy' />
                </div>
                <p className='text-enthusia-text capitalize leading-7 text-justify indent-16'>{Data.OrganizationalAim}</p>
              </div>
            </div>
          </div>
        </div>
        {/* Transforming Ideas into IT Realities */}
        <div className="">
          <h3 className='text-enthusia-primary text-center font-semibold'>{Data.TransformingIdeas.heading}</h3>
          <div className="w-full flex flex-wrap items-end justify-between pt-5 md:pt-[50px]">
            {/* Mobile Size */}
            <div className="block sm:hidden w-full text-center border-b border-enthusia-primary">
              <div className="flex items-center justify-between">
                <div onClick={() => setTransformingIdeas(2)} className={`w-10 h-10 md:w-[60px] md:h-[60px] lg:w-[80px] lg:h-[80px] sm:mx-auto opacity-50 grayscale ${transformingIdeas === 2 && "order-2 !opacity-100 grayscale-0"}`}>
                  <img src={SaasDevelopment} alt="Saas Development" className={`w-full h-full object-contain pointer-events-none`} loading='lazy' />
                </div>
                <div onClick={() => setTransformingIdeas(3)} className={`w-10 h-10 md:w-[60px] md:h-[60px] lg:w-[80px] lg:h-[80px] order-3 opacity-50 grayscale sm:mx-auto ${transformingIdeas === 3 && "order-2 !opacity-100 grayscale-0"}`}>
                  <img src={DigitalTransformation} alt="Digital Transformation" className={`w-full h-full object-contain pointer-events-none`} loading='lazy' />
                </div>
                <div onClick={() => setTransformingIdeas(1)} className={`w-10 h-10 md:w-[60px] md:h-[60px] lg:w-[80px] lg:h-[80px] sm:mx-auto opacity-50 grayscale ${transformingIdeas === 1 && "order-2 !opacity-100 grayscale-0"} ${transformingIdeas === 3 ? "order-3" : "order-1"}`}>
                  <img src={BoostingStartup} alt="Boosting Startup" className={`w-full h-full object-contain pointer-events-none`} loading='lazy' />
                </div>
              </div>
              <h4 className={`sm:w-full relative inline-block sm:block text-center text-base md:text-xl lg:text-28 font-bold text-enthusia-primary capitalize py-2 after:content-[""] after:absolute after:-bottom-[1px] after:left-0 after:right-0 after:rounded-t-[2px] after:h-[3px] after:bg-enthusia-primary after:bg-transparent}`}>{transformingIdeas === 2 ? "SaaS Development" : transformingIdeas === 3 ? "Digital Transformation" : "Boosting Startup"}</h4>
            </div>
            {/* Above 640 px Size */}
            {Data.TransformingIdeas.listOfIdeas.map((data, index) => (
              <div key={data.id} onClick={() => setTransformingIdeas(data.id)} className={`w-full sm:w-1/3`}>
                <div className={`hidden sm:block border-b border-enthusia-primary group hover:opacity-100 hover:grayscale-0 cursor-pointer anim ${transformingIdeas === data.id ? "opacity-100 grayscale-0" : "opacity-50 grayscale"}`}>
                  <div className="w-10 h-10 md:w-[60px] md:h-[60px] lg:w-[80px] lg:h-[80px] mx-auto">
                    <img src={isHDImages ? (imageUrl + data.src) : IdeasImagesList.map((data) => (data))[index]} alt="Saas Development" className='w-full h-full object-contain group-hover:scale-105 pointer-events-none anim' loading='lazy' />
                  </div>
                  <div className="flex items-center justify-center">
                    <h4 className={`sm:w-full relative inline-block sm:block text-center text-base md:text-xl xl:text-28 font-bold text-enthusia-primary capitalize pt-6 pb-7 after:content-[""] after:absolute after:-bottom-[1px] after:left-0 after:right-0 after:rounded-t-[2px] after:h-[3px] ${transformingIdeas === data.id ? "after:bg-enthusia-primary" : "after:bg-transparent"}`}>{data.title}</h4>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p className='hidden sm:block text-enthusia-primary text-justify pt-6 sm:h-[142px] md:pt-[30px]'>
            {`${transformingIdeas === 1 ? Data.TransformingIdeas.listOfIdeas[0].paragraph : transformingIdeas === 2 ? Data.TransformingIdeas.listOfIdeas[1].paragraph : Data.TransformingIdeas.listOfIdeas[2].paragraph}`}
          </p>
          <p className='block sm:hidden text-enthusia-primary text-justify pt-6 sm:h-[142px] md:pt-[30px]'>
            {`${transformingIdeas === 1 ? Data.TransformingIdeas.listOfIdeas[1].paragraph : transformingIdeas === 2 ? Data.TransformingIdeas.listOfIdeas[0].paragraph : Data.TransformingIdeas.listOfIdeas[2].paragraph}`}
          </p>
          <div className="flex items-center justify-center pt-6">
            <Button onClick={() => transformingIdeas === 1 ? navigator("/services/custom-services/saas-development") : transformingIdeas === 3 ? navigator("/services/custom-services/digital-transformation") : navigator("/services/boosting-startups")} button_class="hidden sm:block" button_name="View  More" img_class="hidden" />
            <Button onClick={() => transformingIdeas === 1 ? navigator("/services/boosting-startups") : transformingIdeas === 3 ? navigator("/services/custom-services/digital-transformation") : navigator("/services/custom-services/saas-development")} button_class="block sm:hidden" button_name="View  More" img_class="hidden" />
          </div>
        </div>
        {/* Hiring Box */}
        <Hiring onClick={() => setConsultFrom(true)} src={Hiring_Model} hiringImg="hidden lg:inline-block" />
        {/* GET A CONSULTATION */}
        <ConsultationFrom
          main_title={Data.Contact.title}
          paragraph_1={Data.Contact.paragraph}
          paragraph_2={Data.Contact.paragraph_2}
        />
      </main>
      {/* consultFrom Popup */}
      <Modal isOpen={consultFrom} >
        <ContactFrom handleClose={setConsultFrom} />
      </Modal>
      {/* PDF VIEW Popup */}
      <Modal isOpen={pdfView} >
        <PdfPopUp handleClose={setPdfView} />
      </Modal>
      {/* Share pDF Popup */}
      <Modal isOpen={sharePdf} >
        <Share shareName="Praposal PDF" handleClose={setSharePdf} />
      </Modal>
    </React.Fragment>
  )
}

export default Home