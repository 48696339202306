import React, { useState } from 'react'
import customData from '../../static/CustomSubServices.json'
import DOMPurify from 'dompurify';
import SEO from '../Common/SEO';
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from '../../Utility/HeadingTitle'
import ScopeServicesBox from '../../Utility/ScopeServicesBox'
import LeftBorderHeading from '../../Utility/LeftBorderHeading'
import Development from '../../Utility/Development'
import OursProcessSection from '../../Utility/OursProcessSection'
import MobileAppOfferServices from '../Others/MobileAppOfferServices'
import AppFrontendTechnologies from '../Others/AppFrontendTechnologies'
import AppBackendTechnologies from '../Others/AppBackendTechnologies'
import AppPlatformsTechnologies from '../Others/AppPlatformsTechnologies'
import DatabaseTechnologies from '../Others/DatabaseTechnologies'
import IndustriesWeServe from '../Others/IndustriesWeServe'
import ConsultationFrom from '../Others/ConsultationFrom'

import MobileAppBox from "../../Assets/svg/mobileapp_app_box.svg";
import MobileAppDevelopmentBox from "../../Assets/Webp/mobileapp_development_box.webp";

function MobileAppDevelopment() {
    const data = customData.mobileAppDevelopment;
    const isHDImages = false;
    const [technologiesSideTab, setTechnologiesSideTab] = useState(1);
    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className="wrapper">
                {/* Mobile Apps */}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[819px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? (imageUrl + data.headingBox.src) : MobileAppBox}
                            alt="Mobile Development"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                </div>
                {/* Mobile App Development */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.MobileAppDevelopment.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? (imageUrl + data.MobileAppDevelopment.src) : MobileAppDevelopmentBox}
                        alt="MobileApp development"
                        title_class="hidden"
                        development_paragraph={data.MobileAppDevelopment.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.MobileAppDevelopment.buttonName}
                    />
                </div>
                {/* Our Approach */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title={data.OurApproach.heading} />
                    <p className="text-enthusia-primary font-normal text-justify pl-[18px] pt-[30px]">{data.OurApproach.paragraph}</p>
                </div>
                {/* Our Services We Offer: */}
                <div className="pt-8 sm:pt-[50px] md:pt-[80px]">
                    <MobileAppOfferServices />
                </div>
                {/* Why Choose Us */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Why Choose Us" />
                    <div className="flex flex-wrap pt-[35px] md:-mx-[15px]">
                        {data.WhyChoose.map((data) => (
                            <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
                        ))}
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* Technologies We Use for Mobile App Development */}
                <div className="pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Technologies We Use for Mobile App Development" />
                    <div className="customs-services rounded-2xl md:rounded-[24px] border-[1px] border-transparent mt-[50px]">
                        <div className="w-full flex flex-wrap md:flex-nowrap justify-start h-full">
                            {/* WebDevelopment TechnologiesTab Left side*/}
                            <div className="w-full md:w-auto flex md:flex-col justify-between border-b md:border-b-0 border-b-[#13404633] md:border-r md:border-r-[#13404633] sm:space-x-5 md:space-x-0 px-3 md:px-5 lg:px-10 2xl:px-20 md:py-[70px] md:space-y-5 xl:space-y-[55px] h-auto">
                                <h3 className={`technology-sideTab ${technologiesSideTab === 1 ? "active" : ""}`} data-tab="Frontend" onClick={() => setTechnologiesSideTab(1)}>Frontend</h3>
                                <h3 className={`technology-sideTab ${technologiesSideTab === 2 ? "active" : ""}`} data-tab="Backend" onClick={() => setTechnologiesSideTab(2)}>Backend</h3>
                                <h3 className={`technology-sideTab ${technologiesSideTab === 3 ? "active" : ""}`} data-tab="Platforms" onClick={() => setTechnologiesSideTab(3)}>Platforms</h3>
                                <h3 className={`technology-sideTab ${technologiesSideTab === 4 ? "active" : ""}`} data-tab="Database" onClick={() => setTechnologiesSideTab(4)}>Database</h3>
                            </div>
                            {/* WebDevelopment TechnologiesTab Right side*/}
                            <div className="w-full h-auto p-4 md:p-5 lg:p-[30px]">
                                {technologiesSideTab === 1 && <AppFrontendTechnologies />}
                                {technologiesSideTab === 2 && <AppBackendTechnologies />}
                                {technologiesSideTab === 3 && <AppPlatformsTechnologies />}
                                {technologiesSideTab === 4 && <DatabaseTechnologies />}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Industries We Serve */}
                <div className="pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Industries We Serve" />
                    <IndustriesWeServe industries_class="pt-[50px]" />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_2={data.Contact.paragraph_2}
                    />
                </div>
            </main>
        </React.Fragment>
    )
}

export default MobileAppDevelopment