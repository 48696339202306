import { useState, useEffect } from "react";

function useScrollAnimation(sectionIds) {
  const [activeSection, setActiveSection] = useState(null);
  const [visitedSections, setVisitedSections] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      const newActiveSection = sectionIds.find((sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          return rect.top <= window.innerHeight * 1 && rect.bottom >= window.innerHeight * 0;
        }
        return false;
      });

      if (newActiveSection) {
        // Check if the section has been visited before
        if (!visitedSections[newActiveSection]) {
          // Add "active" class and mark as visited in state
          const activeSectionElement = document.getElementById(newActiveSection);
          if (activeSectionElement) {
            activeSectionElement.classList.add("active");

            // Update visited status in state
            setVisitedSections((prevVisitedSections) => ({
              ...prevVisitedSections,
              [newActiveSection]: true,
            }));

            // Remove "active" class from the previously visited section (if any)
            if (activeSection && activeSection !== newActiveSection) {
              const prevActiveSectionElement = document.getElementById(activeSection);
              if (prevActiveSectionElement) {
                prevActiveSectionElement.classList.remove("active");
              }
            }
          }
        }
      }

      setActiveSection(newActiveSection); // Set the active section (or null)
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Call initially to set the active section
    handleScroll();

    // Clean up by removing the event listener when unmounting
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [sectionIds, visitedSections, activeSection]);

  return activeSection;
}

export default useScrollAnimation;
