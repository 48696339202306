const ProcessBox = ({ onClick, process_class, process_box, step_class, step_no, process_step_name, process_name_class }) => {
    return (
        <div onClick={onClick} className={`group block text-center ${process_class}`}>
            <div className={`relative block h-[70px] md:h-[80px] 3xl:h-[100px] cursor-pointer ${process_box}`}>
                <span className={`absolute flex items-center justify-center w-8 md:w-[40px] h-8 md:h-[40px] -left-4 md:-left-[25px] top-1/2 -translate-y-1/2 text-white text-lg 3xl:text-[25px] font-semibold animation ${step_class}`}>{step_no}</span>
                <span className={`flex items-center justify-center w-full h-full font-medium text-base md:text-lg 3xl:text-22 px-5 capitalize ${process_name_class}`}>{process_step_name}</span>
            </div>
        </div>
    )
}

export default ProcessBox;