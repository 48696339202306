import React, { useState } from 'react'
import customData from '../../static/SubServices.json'
import DOMPurify from 'dompurify';
import SEO from '../Common/SEO';
import { imageUrl } from '../../Api/baseUrl';

import HeadingTitle from '../../Utility/HeadingTitle';
import Button from '../../Utility/Button';
import Development from '../../Utility/Development';
import ScopeServicesBox from '../../Utility/ScopeServicesBox';
import OursProcessSection from '../../Utility/OursProcessSection';
import LeftBorderHeading from '../../Utility/LeftBorderHeading';
import ConsultationFrom from '../Others/ConsultationFrom';
import HireResourcesWork from '../Others/HireResourcesWork';
import Modal from '../Common/Modal';
import ContactFrom from '../Popup/ContactFrom';

import HireResourcesBox from "../../Assets/svg/hire_resources_box.svg";
import HireResourceImages from '../../Assets/Webp/hire_resources.webp'
import DreamProject from '../../Assets/Webp/dream_project.webp'
import DreamProjectHorizontal from '../../Assets/Webp/dream_project_horizontal.webp'
import TalentedExpert from '../../Assets/svg/talented_experts.svg'
import HourlyHiring from '../../Assets/svg/hourly_hiring.svg'
import FullTimeHiring from '../../Assets/svg/full_time_hiring.svg'

function HireResources() {
    const data = customData.HireResources;
    const isHDImages = false;
    const [consultFrom, setConsultFrom] = useState(false);
    return (
        <React.Fragment>
            <SEO
                title={data.SEO.title}
                description={data.SEO.Description}
                canonicalLink={data.SEO.CanonicalLink}
                keywords={data.SEO.keywords}
            />
            <main className='wrapper'>
                {/* Hire Resources Box*/}
                <div className="flex flex-wrap lg:flex-nowrap items-center justify-between hiring-gradient rounded-[20px] md:rounded-[30px] lg:rounded-[50px] py-5 md:py-8 px-5 sm:px-10 xl:px-[90px] mt-8 md:mt-[60px]">
                    <h3 className="xl:max-w-[800px] w-full leading-[28px] md:leading-[34px] lg:leading-[50px] 2xl:leading-[70px] text-center lg:text-left font-semibold text-enthusia-primary capitalize order-2 lg:order-1 pt-5 lg:pt-0">
                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.headingBox.title) }} />
                    </h3>
                    <div className="w-auto h-[150px] md:h-[200px] order-1  lg:order-2 mx-auto lg:mx-0 lg:ml-auto">
                        <img
                            src={isHDImages ? (data.headingBox.src) : HireResourcesBox}
                            alt="Hire Resources"
                            className="w-full h-full"
                            loading='lazy'
                        />
                    </div>
                </div>
                {/* Hire Resources */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <HeadingTitle
                        title_name={data.HireResources.heading}
                        heading_main="cursor-auto"
                        heading_class="order-1"
                        line_class="bg-gradient-to-r order-2 sm:ml-5"
                    />
                    <Development
                        development_class="mt-7 lg:mt-[50px]"
                        src={isHDImages ? (imageUrl + data.HireResources.src) : HireResourceImages}
                        alt="Hire ResourceImage"
                        title_class="hidden"
                        development_paragraph={data.HireResources.paragraph}
                        paragraph_2_class="hidden"
                        paragraph_1_class="pb-5"
                        button_name={data.HireResources.buttonName}
                    />
                </div>
                {/* Why Choose Us */}
                <div className="pt-8 sm:pt-[50px] md:pt-[100px]">
                    <LeftBorderHeading heading_title="Why Choose Us for Resource Hiring" />
                    <div className="flex flex-wrap pt-5 md:pt-[35px] md:-mx-[15px]">
                        {data.WhyChoose.map((data) => (
                            <ScopeServicesBox key={data.id} ScopeServicesBox_class="w-full md:w-1/2 lg:w-1/3 2xl:w-1/3 pb-5 md:p-[15px]" ul_class="hidden" ScopeServicesBox_title={data.title} ScopeServicesBox_paragraph={data.Description} />
                        ))}
                    </div>
                </div>
                {/* Our Resource Categories */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <LeftBorderHeading heading_title="Our Resource Categories" />
                    <div className="flex flex-wrap pt-8 md:pt-[35px]">
                        {data.OurResource.map((data, i) => (
                            <div key={i} className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 hover:drop-shadow-xl anim pb-2 sm:p-3">
                                <div className="sm:flex items-center architecture-patterns-box-small h-full rounded-2xl p-3 sm:p-5">
                                    <span className='block text-center sm:text-left text-sm sm:text-base md:leading-7 font-medium text-enthusia-primary'>{data}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* How it Works */}
                <div className="pt-8 md:pt-[50px] lg:pt-[100px]">
                    <HireResourcesWork />
                </div>
                {/* Ready to Start Your Dream Project? */}
                <div className="pt-5 sm:pt-[50px]">
                    <h3 className='text-center text-enthusia-primary font-medium leading-[28px] md:leading-[34px]'>Ready to Start Your Dream Project?</h3>
                    <div className="pt-[50px]">
                        <div className="relative w-full mxa-w-[942px] px-5 mx-auto">
                            <img src={DreamProject} alt="dream_project" className='w-auto h-auto object-cover mx-auto hidden lg:block' loading='lazy' />
                            <img src={DreamProjectHorizontal} alt="dream_project" className='lg:hidden block w-auto mx-auto h-auto object-cover' loading='lazy' />
                            <div className="absolute flex flex-col lg:flex-row justify-center w-full lg:space-x-[170px] space-y-[120px] lg:space-y-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                                <div className="text-center">
                                    <img src={TalentedExpert} alt="dream_project" className='w-[60px] lg:w-auto h-auto object-cover mx-auto' loading='lazy' />
                                    <h5 className='text-base lg:text-22 font-medium text-enthusia-primary pt-2.5 lg:pt-8'>Talented Experts</h5>
                                </div>
                                <div className="text-center">
                                    <img src={HourlyHiring} alt="hourly_hiring" className='w-[60px] lg:w-auto h-auto object-cover mx-auto' loading='lazy' />
                                    <h5 className='text-base lg:text-22 font-medium text-enthusia-primary pt-2.5 lg:pt-8'>Hourly Hiring</h5>
                                </div>
                                <div className="text-center">
                                    <img src={FullTimeHiring} alt="full_time_hiring" className='w-[60px] lg:w-auto h-auto object-cover mx-auto' loading='lazy' />
                                    <h5 className='text-base lg:text-22 font-medium text-enthusia-primary pt-2.5 lg:pt-8'>Full-Time hiring</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center pt-[50px]">
                        <Button onClick={() => setConsultFrom(true)} button_class="capitalize px-[30px] py-2" button_name="schedule a developer interview" img_class="hidden" />
                    </div>
                </div>
                {/* Bringing Dreams to Digital Reality section */}
                <div className="pt-5 sm:pt-[50px] md:pt-[85px]">
                    <OursProcessSection />
                </div>
                {/* GET A CONSULTATION */}
                <div className="pt-8 sm:pt-[50px] md:pt-[85px]">
                    <ConsultationFrom
                        main_title={data.Contact.MainTitle}
                        paragraph_1={data.Contact.paragraph}
                        paragraph_class_2="hidden"
                    />
                </div>
                {/* consultFrom Popup */}
                <Modal isOpen={consultFrom}>
                    <ContactFrom handleClose={setConsultFrom} />
                </Modal>
            </main>
        </React.Fragment>
    )
}

export default HireResources