import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'

import * as Yup from 'yup';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input';
import PhoneNumber from 'libphonenumber-js';

import { baseUrl } from '../../Api/baseUrl';
import { CONTACT } from '../../Api/constantApi';

import Input from '../../Utility/Input'
import Textarea from '../../Utility/Textarea'
import Button from '../../Utility/Button'

const ContactWhiteFrom = ({ contact_from_className }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successFull, setSuccessFull] = useState(null);

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        message: "",
    }
    const isValidPhoneNumber = (phoneNumber) => {
        const parsedPhoneNumber = PhoneNumber(phoneNumber, 'ZZ');
        return parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
    };

    const ValidationSchema = Yup.object().shape({
        firstName: Yup.string().required('First name is required*').matches(/^[A-Za-z]+$/, 'First name only letters').transform((value, originalValue) => originalValue.replace(/\s/g, '')),
        lastName: Yup.string().required('Last name is required*').matches(/^[A-Za-z]+$/, 'First name only letters').transform((value, originalValue) => originalValue.replace(/\s/g, '')),
        email: Yup.string().required('Email is required*'),
        phoneNumber: Yup.string()
            .required('Phone number is required*')
            .test('is-valid-phone', 'Invalid phone number', (value) => isValidPhoneNumber(value)),
        message: Yup.string().required('Message is required*'),
    });

    const clickNextHandler = async (values) => {
        const payload = { ...values };
        try {
            setLoading(true);
            const response = await axios.post(`${baseUrl}${CONTACT}`, payload);
            if (response.data.status === 200) {
                formik.resetForm();
                setError("")
                localStorage.removeItem('consultationFormData');
                setSuccessFull(response.data.message)
                setInterval(() => {
                    setSuccessFull(null);
                }, 2000);
            } else {
                setError(response.data.message || "Something went wrong, Please Try Agin!!.");
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error?.response?.data?.message || "Something went wrong, Please Try Agin!!.");
        }
    }

    const formik = useFormik({
        initialValues: initialState,
        validationSchema: ValidationSchema,
        onSubmit: clickNextHandler,
        validateOnBlur: false,
        validateOnChange: false,
    });

    useEffect(() => {
        // Retrieve saved form data from localStorage
        const savedData = localStorage.getItem('consultationFormData');
        if (savedData) {
            formik.setValues(JSON.parse(savedData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function to update and save form data to localStorage
    const updateAndSaveFormData = (values) => {
        formik.setValues(values);
        localStorage.setItem('consultationFormData', JSON.stringify(values));
    };

    const setInputValue = useCallback(
        (key, value) => {
            // Update the form values in real-time
            const newValues = { ...formik.values, [key]: value };
            updateAndSaveFormData(newValues);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik]
    );
    return (
        <form onSubmit={formik.handleSubmit} className={`w-full block bg-white rounded-md sm:rounded-2xl md:rounded-3xl my-5 md:my-[50px] xl:m-[50px] p-3 py-5 sm:p-5 md:p-[30px] xl:p-[50px] ${contact_from_className}`}>
            <div className='flex flex-wrap lg:flex-nowrap items-center lg:space-x-[40px] xl:space-x-[50px]'>
                <div className="w-full lg:w-2/3 xl:w-1/2">
                    <div className="flex flex-wrap -mx-5 -my-5">
                        <div className="relative w-full sm:w-1/2 px-5 p-5">
                            <Input input_name="firstName" value={formik.values.firstName} onChange={(e) => { const alphabetOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, ''); setInputValue("firstName", alphabetOnlyValue) }} input_box="w-full" input_class="w-full rounded-0" label_class="text-enthusia-primary" label_name="First Name" placeholder="Enter your first name" input_type="text" />
                            {formik.errors && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.firstName}</span>}
                        </div>
                        <div className="relative w-full sm:w-1/2 px-5 p-5">
                            <Input input_name="lastName" value={formik.values.lastName} onChange={(e) => { const alphabetOnlyValue = e.target.value.replace(/[^a-zA-Z]/g, ''); setInputValue("lastName", alphabetOnlyValue) }} input_box="w-full" label_class="text-enthusia-primary" label_name="Last Name" placeholder="Enter your last name" input_type="text" />
                            {formik.errors && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.lastName}</span>}
                        </div>
                        <div className="relative w-full sm:w-1/2 px-5 p-5">
                            <Input input_name="email" value={formik.values.email} onChange={(e) => setInputValue("email", e.target.value)} input_box="w-full" label_class="text-enthusia-primary" label_name="Email" placeholder="Enter your email address" input_type="email" />
                            {(error.email || formik.errors) && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.email || error.email}</span>}
                        </div>
                        <div className="relative w-full sm:w-1/2 px-5 p-5">
                            <label className={`block text-base leading-5 font-medium text-enthusia-primary`}>Phone Number</label>
                            <PhoneInput
                                international
                                defaultCountry="IN"
                                value={formik.values.phoneNumber}
                                onChange={(phoneNumber) => setInputValue("phoneNumber", phoneNumber)}
                            />
                            {(error.phoneNumber || formik.errors) && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.phoneNumber || error.phoneNumber}</span>}
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-1/3 xl:w-1/2 mt-7 lg:mt-0">
                    <Textarea textarea_name="message" value={formik.values.message} onChange={(e) => setInputValue("message", e.target.value)} textarea_box="w-full" label_name="Message" placeholder="Write your message.." />
                    {formik.errors && <span className='absolute text-xs text-red-500 pt-1'>{formik.errors.message}</span>}
                </div>
            </div>
            <div className="flex justify-between items-center pt-8 xl:pt-[44px]">
                {loading ?
                    <div className='flex items-center justify-center max-w-[125px] w-full h-[44px] bg-enthusia-primary text-white rounded-full px-5 py-2'>
                        <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="#fff" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="#fff" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </div>

                    :
                    <Button type="submit" button_class="cursor-pointer" button_name="Submit" img_class="hidden" />
                }
                {successFull && <span className='text-base font-semibold text-green-800 pt-1'>{successFull}</span>}
                {error && <span className='text-base font-semibold text-red-400 pt-1'>{error}</span>}
            </div>
        </form>
    )
}

export default ContactWhiteFrom