import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import customData from '../../static/CustomSubServices.json'
import DOMPurify from 'dompurify';

import useScrollSpy from '../hook/useScrollSpy';
import CustomSaasServicesBox from '../../Utility/CustomSaasServicesBox'

function OurSaasProcess() {
  const navigator = useNavigate();
  const sectionIds = [
    'ConceptsAndIdeation',
    'MarketAnalysis',
    'SaasDevelopmentGoals',
    'SaasArchitecture',
    'UiUx',
    'DevelopmentPhase',
    'TestingAndQuality',
    'SecurityAndCompliance',
    'BetaTesting',
    'DeploymentAndEnlarging',
    'MarketingAndGrowth',
    'CareAndRenovations',
    'FutureRoadmap',
  ];
  const activeLink = useScrollSpy(sectionIds);
  const data = customData.SaaSDevelopment;
  return (
    <div className="flex items-start pt-5 md:pt-[30px]">
      <div className="hidden lg:block lg:sticky lg:top-32 max-w-[400px] xl:max-w-[596px] w-full services-offer-border-left space-y-4 lg:pr-5">
        {(data.SaasProcess.process).map((data) => (
          <Link
            key={data.id}
            to={data.idName}
            className={`custom-service-tab flex items-center pointer-events-none relative ${activeLink === data.idName ? "active" : ""
              }`}
          >
            <h3 className="custom-services-tab-number leading-[28px] md:leading-[34px]">
              {data.Number}
            </h3>
            <p className="custom-services-tab-title leading-5 xl:leading-9">
              {data.title}
            </p>
          </Link>
        ))}
      </div>
      <div className="w-full lg:w-[calc(100%-300px)] xl:w-[calc(100%-596px)]">
        {data.SaasProcess.process.map((data) => (
          data.id === 6 ? (
            <div key={data.id} id={data.idName} className={`group anim section w-full pb-5 md:pb-[30px]`}>
              <div className={`customs-services-hover rounded-2xl md:rounded-[24px] border md:border-[2px] border-transparent p-5 md:p-[30px] lg:p-10 animation hover:grayscale-0 hover:opacity-100 ${activeLink === data.idName
                ? "grayscale-0 customs-services-active"
                : "grayscale opacity-70"
                }`}>
                <h3 className="leading-[28px] md:leading-[34px] 2xl:leading-[36px] text-enthusia-primary font-semibold anim">{data.title}</h3>
                <p className="text-enthusia-text font-normal anim pt-5">{data.Description}</p>
                <ul className='text-enthusia-primary text-sm md:text-base xl:text-lg  leading-[21px] md:leading-[23px] xl:leading-7 list-disc list-inside space-y-1'>
                  {data.Phase.map((data, index) => (
                    <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }} />
                  ))}
                </ul>
                <p className="text-enthusia-text font-normal anim pt-5">{data.Description_2}</p>
                <span onClick={() => navigator(`${data.link}`)} className='cursor-pointer inline-block font-bold text-enthusia-text underline anim pt-3'>{data.linkName}</span>
              </div>
            </div>
          ) : (
            <CustomSaasServicesBox
              key={data.id}
              id={data.idName}
              custom_class={`border md:border-[2px] border-transparent p-5 md:p-[30px] lg:p-10 animation hover:grayscale-0 hover:opacity-100 ${activeLink === data.idName
                ? "grayscale-0 customs-services-active"
                : "grayscale opacity-70"
                }`}
              custom_box_class="section w-full pb-5 md:pb-[30px]"
              custom_services_title={data.title}
              custom_services_paragraph={data.Description}
            />
          )
        ))}
      </div>
    </div>
  );
}

export default OurSaasProcess