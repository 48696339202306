import React from 'react'

import { Link } from 'react-router-dom';

import Close_Icon from '../../Assets/svg/close_icon.svg';
import Call_Icon from "../../Assets/svg/call_icon.svg";
import Email_Icon from "../../Assets/svg/email_icon.svg";
import Location_Icon from "../../Assets/svg/location_icon.svg";

function Directions({ handleClose }) {
    return (
        <div className="fixed inset-0 w-screen h-screen bg-[rgba(255,255,255,0.4)] flex backdrop-blur-[10px] z-50">
            <div className="flex items-start xsm:items-center justify-center max-w-[870px] w-full mx-auto animation-slideUp mt-5 xsm:mt-0">
                <div className="relative w-full bg-enthusia-primary rounded-2xl lg:rounded-[30px] p-5 md:p-7 lg:p-[50px] m-3">
                    <img onClick={() => handleClose(false)} src={Close_Icon} alt="close_icon" className='absolute lg:top-5 top-3 right-3 lg:right-5 cursor-pointer' />
                    <div className="w-full flex flex-wrap md:flex-nowrap md:space-x-[22px]">
                        <div className="w-full md:w-2/5 text-white">
                            <h3 className='text-[32px] font-semibold leading-[28px] md:leading-[34px]'>Contact Us</h3>
                            <span className='block text-lg font-medium py-4 md:py-[25px]'>Better yet, see us in person!</span>
                            <div className="flex flex-col items-center space-y-5 md:space-y-[20px]">
                                {/* Call Icon */}
                                <Link to={"tel:91 8200550680"} className="w-full flex items-center justify-start">
                                    <div className=''>
                                        <div className='grid place-items-center w-10 md:w-[50px] h-10 md:h-[50px] bg-enthusia-primary border-white border rounded-full'>
                                            <img src={Call_Icon} alt="call icon" className='w-auto h-auto object-cover' />
                                        </div>
                                    </div>
                                    <span className='inline-block text-base md:text-lg font-normal ml-4'>+91 82005 50680</span>
                                </Link>
                                {/*  Email icon */}
                                <Link to={"mailto:hr@enthusiasoftech.com"} className="w-full flex items-center justify-start">
                                    <div className="">
                                        <div className='grid place-items-center w-10 md:w-[50px] h-10 md:h-[50px] bg-enthusia-primary border-white border rounded-full'>
                                            <img src={Email_Icon} alt="call icon" className='w-auto h-auto object-cover' />
                                        </div>
                                    </div>
                                    <span className='inline-block text-base md:text-lg font-normal whitespace-normal ml-4'>info@enthusiasoftech.com</span>
                                </Link>
                                {/* Location icon */}
                                <Link to={"https://maps.app.goo.gl/5koNMv7fWfVJrXBR7"} className="w-full flex items-start justify-start">
                                    <div className="">
                                        <div className='grid place-items-center w-10 md:w-[50px] h-10 md:h-[50px] bg-enthusia-primary border-white border rounded-full'>
                                            <img src={Location_Icon} alt="call icon" className='w-auto h-auto object-cover' />
                                        </div>
                                    </div>
                                    <span className='inline-block text-base md:text-lg font-normal ml-4'>309, Enthusia Softech Pvt. Ltd., Unique Square, Causeway Link Road, Singanpore, Surat-395004.</span>
                                </Link>
                            </div>
                        </div>
                        <div className="w-full md:w-3/5 rounded-xl h-[200px] sm:h-[250px] md:h-auto overflow-hidden mt-5 md:mt-0">
                            <iframe title="Enthusia Softech Pvt Ltd Address" className='w-full h-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.04392326765!2d72.8033386760021!3d21.23010678083121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f3d7a4e0519%3A0x50017e0a83bf18fa!2sEnthusia%20Softech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1703669298380!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Directions