import React from 'react'
import { Link } from 'react-router-dom';
import customData from '../../static/CustomSubServices.json'
import useScrollSpy from '../hook/useScrollSpy';
import CustomSaasServicesBox from '../../Utility/CustomSaasServicesBox'
import HeadingTitle from '../../Utility/HeadingTitle';

function MobileAppOfferServices() {
    const data = customData.mobileAppDevelopment;
    const sectionIds = [
        'CustomMobileApp',
        'UI/UXDesign',
        'NativeAndCrossPlatform',
        'MobileAppIntegration',
        'MobileAppTesting',
        'MaintenanceAndUpdates',
        'MaintenanceAndSupport',
    ];

    const activeLink = useScrollSpy(sectionIds);
    return (
        <React.Fragment>
            <div className="lg:sticky lg:top-0 bg-white md:py-5 z-10">
                <HeadingTitle
                    title_name={data.ServicesWeOffer.hading}
                    heading_class="order-2"
                    line_class="bg-gradient-to-r sm:bg-gradient-to-l order-2 sm:order-1 sm:mr-5"
                />
            </div>
            <div className="flex items-start pt-5 md:pt-[30px]">
                <div className="hidden lg:block lg:sticky lg:top-32 max-w-[400px] xl:max-w-[596px] w-full services-offer-border-left space-y-4 lg:pr-5">
                    {(data.ServicesWeOffer.Services).map((data) => (
                        <Link
                            key={data.id}
                            to={data.idName}
                            className={`custom-service-tab flex items-center pointer-events-none relative ${activeLink === data.idName ? "active" : ""
                                }`}
                        >
                            <h3 className="custom-services-tab-number leading-[28px] md:leading-[34px]">
                                {data.Number}
                            </h3>
                            <p className="custom-services-tab-title leading-5 xl:leading-9">
                                {data.title}
                            </p>
                        </Link>
                    ))}
                </div>
                <div className="w-full lg:w-[calc(100%-300px)] xl:w-[calc(100%-596px)]">
                    {(data.ServicesWeOffer.Services).map((data) => (
                        <CustomSaasServicesBox
                            key={data.id}
                            id={data.idName}
                            custom_class={`border-[3px] border-transparent p-5 md:p-[30px] lg:p-10 animation hover:grayscale-0 hover:opacity-100 ${activeLink === data.idName
                                ? "grayscale-0 customs-services-active"
                                : "grayscale opacity-70"
                                }`}
                            custom_box_class="section w-full pb-5 md:pb-[30px]"
                            custom_services_title={data.title}
                            custom_services_paragraph={data.Description}
                        />
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default MobileAppOfferServices