import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import EnthusiaCenterHeader from '../../Utility/EnthusiaCenterHeader';

function Layout() {
    return (
        <div className='max-w-screen-2k mx-auto relative'>
            <Header />
            <React.Fragment>
                <EnthusiaCenterHeader />
                <Outlet />
            </React.Fragment>
            <Footer />
        </div>
    );
}

export default Layout;
