import React from 'react'
import TechnologiesTabBox from '../../Utility/TechnologiesTabBox'

import Selenium from '../../Assets/Webp/selenium.webp'
import Appium from '../../Assets/Webp/appium.webp'
import Jest from '../../Assets/Webp/Jest.webp'
import Cypress from '../../Assets/Webp/cypress.webp'

function QualityAssurance() {
    return (
        <React.Fragment>
            <p className='leading-9 font-medium text-[#737373]'>Testing Frameworks:</p>
            <div className="w-full flex flex-wrap items-start justify-start 2xl:-mx-5 pt-1">
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Selenium} alt="Selenium" technologies_title="Selenium" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Appium} alt="Appium" technologies_title="Appium" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Jest} alt="Jest" technologies_title="Jest" />
                <TechnologiesTabBox technologies_box_class="w-1/4 sm:w-1/5 p-2 2xl:px-5" src={Cypress} alt="Cypress" technologies_title="Cypress" />
            </div>
        </React.Fragment>
    )
}

export default QualityAssurance