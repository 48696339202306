const WeDevelopBox = ({ develop_class, src, alt, develop_title, images_box, develop_second_class, develop_title_class }) => {
    return (
        <div className={`hover:drop-shadow-xl anim ${develop_class}`}>
            <div className={`sm:flex items-center architecture-patterns-box-small h-full rounded-2xl md:rounded-[24px] p-3 sm:p-5 lg:p-[30px] ${develop_second_class}`}>
                <div className="">
                    <div className={`w-10 md:w-[80px] h-10 md:h-[80px] mx-auto sm:mx-0 ${images_box}`}>
                        <img src={src} alt={alt} className="w-full h-full object-contain" loading="lazy" />
                    </div>
                </div>
                <span className={`block text-center sm:text-left text-sm sm:text-base md:text-xl md:leading-7 font-medium text-enthusia-primary sm:pl-5 pt-3 sm:pt-0 ${develop_title_class}`}>{develop_title}</span>
            </div>
        </div>
    )
}

export default WeDevelopBox;