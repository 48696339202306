import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import data from "../../static/SubServices.json";
import { imageUrl } from "../../Api/baseUrl";
import useScrollSpy from '../hook/useScrollSpy';
import CustomServicesBox from '../../Utility/CustomServicesBox'
import HeadingTitle from '../../Utility/HeadingTitle';

import SaasDevelopment from "../../Assets/svg/saas_development.svg";
import WebDevelopment from "../../Assets/svg/web_development.svg";
import AppDevelopment from "../../Assets/svg/app_development.svg";
import SoftwareDevelopment from "../../Assets/svg/software_development.svg";
import DataAnalyticsAl from "../../Assets/svg/data_analytics_al.svg";
import AppModernization from "../../Assets/svg/app_modernization.svg";
import DigitalTransformation from "../../Assets/svg/digital_transformation.svg";
import QaTesting from "../../Assets/svg/qa_testing.svg";

function CustomOfferServices() {
    const navigate = useNavigate();
    const CustomData = data.CustomServices
    const isHDImages = false;
    const sectionIds = [
        "SaasDevelopment",
        "WebDevelopment",
        "AppDevelopment",
        "SoftwareDevelopment",
        "DataAnalytics",
        "AppModernization",
        "DigitalTransformation",
        "QaTesting",
    ];
    const activeLink = useScrollSpy(sectionIds);
    const CustomServicesImages = [SaasDevelopment, WebDevelopment, AppDevelopment, SoftwareDevelopment, DataAnalyticsAl, AppModernization, DigitalTransformation, QaTesting]
    return (
        <div className="py-8 sm:pt-[50px] md:pt-[100px]">
            <div className="lg:sticky lg:top-0 bg-white py-5 z-10">
                <HeadingTitle
                    title_name={CustomData.CustomServices.heading}
                    heading_class="order-1"
                    line_class="bg-gradient-to-r order-2 sm:ml-5"
                />
            </div>
            <p className="text-enthusia-text font-normal text-justify indent-16 pt-[30px]">{CustomData.CustomServices.paragraph}</p>
            <div className="flex items-start mt-[30px]">
                <div className="hidden md:block sticky top-32 max-w-[300px] xl:max-w-[596px] w-full services-offer-border-left space-y-4">
                    {(CustomData.CustomServices.Services).map((data) => (
                        <Link key={data.id} to={data.idName} className={`custom-service-tab flex items-center relative pointer-events-none ${activeLink === data.idName ? "active" : ""}`}>
                            <h3 className="custom-services-tab-number leading-[28px] md:leading-[34px]">{data.Number}</h3>
                            <p className="custom-services-tab-title leading-9">{data.title}</p>
                        </Link>
                    ))}
                </div>
                <div className="w-full md:w-[calc(100%-300px)] xl:w-[calc(100%-596px)]">
                    {(CustomData.CustomServices.Services).map((data, index) => (
                        <CustomServicesBox
                            key={data.id}
                            onClick={() => navigate(`${data.link}`)}
                            id={data.idName}
                            custom_class={`border-[3px] border-transparent rounded-2xl md:rounded-[24px] p-5 md:p-[30px] lg:p-10 anim hover:grayscale-0 hover:opacity-100 ${activeLink === data.idName ? "grayscale-0 customs-services-active" : "grayscale opacity-70"}`}
                            custom_box_class="section w-full pb-4 md:pb-[30px]"
                            src={isHDImages ? (imageUrl + data.src) : CustomServicesImages.map((data) => (data))[index]}
                            alt={data.title}
                            custom_services_title={data.title}
                            custom_services_paragraph={data.Description}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CustomOfferServices